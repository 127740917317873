import { BaseButton } from "src/components";

type Props = {
  handleClickPayment: () => void;
};

const PaymentInfo = ({ handleClickPayment }: Props) => {
  return (
    <section className="base-section-layout">
      <div className="base-section-layout__container">
        <div className="base-section-layout__wrap">
          <div className="base-section-layout__title-wrap">
            <div className="w-100 text-center">
              <h2>결제 수단을 등록해 주세요.</h2>
              <p className="font14 mt10 text-primary3">최초 1회만 등록하시면 됩니다.</p>
            </div>
          </div>

          <div className="base-section-layout-index d-flex">
            <div className="w-100">
              <BaseButton title="신용카드 등록하기" className="base-btn size-small " onClick={handleClickPayment} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentInfo;
