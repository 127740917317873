import { Sort } from "../public-types";

export type NoticeType = "SERVICE" | "SPACE";

export type NoticeTarget = "ALL" | "TAAP" | "COURT" | "CTRL_ROOM";

/**
 * 공지사항 목록 검색 parameter
 */
export interface NoticeListParams {
  page?: number;
  size?: number;
  sort?: Sort;
  containsTypes?: NoticeType[]; // 공지 유형
  isDisplayed?: boolean; // 노출 여부
  lessThanEqualsDisplayStartDate?: string; // 해당 날짜 보다 노출 기간 시작 일시가 이전인 날짜 검색
  greaterThanDisplayEndDate?: string; // 해당 날짜 보다 노출 기간 종료 일시가 이후인 날짜 검색
  search001?: string;
}

/**
 * 공지사항 공간상품 맵핑 정보
 */
export interface NoticeProduct {
  id?: number; // 공간상품ID
  name?: string; // 공간상품명
}

/**
 * 공지사항
 */
export interface Notice {
  id: number; // 공지사항ID
  title?: string; // 제목
  content?: string; // 내용
  targets?: NoticeTarget[]; // 게시 위치 컬렉션
  createdDate?: string; // 등록일
  createdBy?: string; // 등록자
  lastModifiedDate?: string; // 수정일
  lastModifiedBy?: string; // 수정자
  products?: NoticeProduct[]; // 관련 공간상품 컬렉션
  contracts?: any;
  buildings?: any;
  type?: NoticeType; // 공지 유형
  isDisplayed?: boolean; // 노출 여부
  displayStartDate?: string; // 노출 기간 시작 일시
  displayEndDate?: string; // 노출 기간 종료 일시
  readDate?: string; // 사용자가 공지사항을 읽은 시간(null 일 수 있음)
  noticeCategory?: string;
}

export const noticeCategoryToText = (noticeCategory: string) => {
  switch (noticeCategory) {
    case "NOTICE_CATEGORY_NONE":
      return "";
    case "FACILITY_MAINTENANCE":
      return "점검/보수";
    case "MOVE_IN_GUIDE":
      return "입주 안내";
    case "FACILITY_GUIDE":
      return "시설 안내";
    case "EVENTS_BENEFITS":
      return "행사/혜택";
    case "SURVEY":
      return "설문 조사";
    case "APP_GUIDE":
      return "앱 안내";
    case "ETC":
      return "기타";
    default:
      return "";
  }
};
