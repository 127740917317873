import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import { DeskGroupModel } from "./building-types";

/**
 * TPB01. (무인증) 좌석 그룹 목록 조회
 */
export async function getDeskgroupsAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number | string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<DeskGroupModel> }>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  return await axios.get(`/api/court/pr/public/buildings/${params.buildingId}/deskgroups`);
}

/**
 * TPB02. (무인증) 좌석 그룹 상세 조회
 */
export async function getDeskgroupAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number | string;
    deskGroupId: number | string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<DeskGroupModel> }>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  if (!params?.deskGroupId) {
    throw Error("deskGroupId is not found.");
  }
  return await axios.get(`/api/court/pr/public/buildings/${params.buildingId}/deskgroups/${params.deskGroupId}`);
}

/**
 * TPB03. (무인증) 좌석 그룹별 좌석 목록 조회
 */
export async function getDesksAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number | string;
    deskGroupId: number | string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: DeskGroupModel }>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  if (!params?.deskGroupId) {
    throw Error("deskGroupId is not found.");
  }
  return await axios.get(`/api/court/pr/public/buildings/${params.buildingId}/deskgroups/${params.deskGroupId}/desks`);
}
