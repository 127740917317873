import { atom } from "recoil";
import { CourtAuthErrorCode } from "src/components/court-auth";

export type RefreshTokenErrorModalState = {
  isOpen: boolean;
  errorCode?: CourtAuthErrorCode;
  errorMessage?: string;
};

/**
 * refresh token 요청시 에러발생할떄 띄우는 모달 state
 */
export const refreshTokenErrorModalState = atom<RefreshTokenErrorModalState>({
  key: "refreshTokenErrorModalState",
  default: {
    isOpen: false,
  },
});
