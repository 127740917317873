import { useRef } from "react";
import noimg from "src/assets/images/icons_large_noimg.svg";
type ListProps = {
  viewData: any[];
};
// 이용중인 상품 및 건물 표기(이용 가능한 공간)
const ContractUseProgressProductView = ({ viewData }: ListProps) => {
  const initializedRef2 = useRef(false);

  if (!initializedRef2.current) {
    initializedRef2.current = true;
  }

  // 건물 이미지 없을때 noimg 적용
  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = noimg;
  };
  console.log("viewData", viewData);
  return (
    <article className="after-login-contract">
      <div className="title-area">
        <span>이용 가능한 공간</span>
        <p className="font14 text-primary3">총 {viewData.length}건</p>
      </div>

      {initializedRef2.current && (
        <div className={`contents-area ${viewData.length === 0 ? "no-list" : ""}`}>
          {viewData.length > 0 ? (
            viewData.map((item: any, index: number) => {
              return (
                <div key={index} className="contents-card2">
                  <div className="contents-card2__title-area">
                    <img src={item.src} onError={handleImgError} alt="productImg" />

                    <div>
                     <div className="two_line_ellipsis">{item.contractName ? item.contractName : item.productName}</div>
                      {/* <p className="">{item.productName}</p> */}
                      <p className="font12 text-primary3">{item.address}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="contents-card-none">
              <span>이용 가능한 공간이 없어요.</span>
            </div>
          )}
        </div>
      )}
    </article>
  );
};
export default ContractUseProgressProductView;
