import { atom } from "recoil";

export interface Scroll {
  id?: string;
  scroll: number;
}

/**
 * 토스트
 */
export const scrollState = atom<Scroll | null>({
  key: "scrollState",
  default: null,
});
