import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { CourtAuthErrorCode } from "src/components/court-auth";
import { RefreshTokenErrorModalState, refreshTokenErrorModalState } from "./atom";

export const useRefreshTokenErrorModal = () => {
  const setRefreshTokenErrorModal = useSetRecoilState<RefreshTokenErrorModalState>(refreshTokenErrorModalState);

  // 에러모달 열기
  const openRefreshTokenErrorModal = useCallback(
    (errorCode: CourtAuthErrorCode, errorMessage: string) => {
      console.log("openRefreshTokenErrorModal", errorCode, errorMessage);
      setRefreshTokenErrorModal({ isOpen: true, errorCode, errorMessage });
    },
    [setRefreshTokenErrorModal],
  );

  // 에러모달 닫기
  const closeRefreshTokenErrorModal = useCallback(() => {
    setRefreshTokenErrorModal({ isOpen: false, errorCode: undefined, errorMessage: undefined });
  }, [setRefreshTokenErrorModal]);

  return { openRefreshTokenErrorModal, closeRefreshTokenErrorModal };
};
