import Select from "react-select";

type SelectOption = {
  value: string | number;
  label: string;
  type?: string;
};

type Props = {
  selectLabel?: string;
  options: Array<SelectOption>;
  value?: string | number | null;
  placeholder?: string;
  name?: string;
  onChange?: (value: string | number | SelectOption) => void;
  wrapClassName?: string;
  className?: string;
  errorText?: string;
  disabled?: boolean;
  getObj?: boolean;
};

/**
 * 셀렉트 박스 공통 컴포넌트
 */
const BaseSelect = ({ selectLabel, options, value, placeholder, name, onChange, wrapClassName, className, errorText, disabled, getObj }: Props) => {
  let selectedValue = null; // null 로 초기화 해야 선택안함이 정상적으로 초기화됨
  if (value) {
    selectedValue = options.find((option) => String(option.value) === String(value));
  }
  return (
    <div className={`base-select ${wrapClassName}`}>
      {selectLabel && <div className="base-select__prefix">{selectLabel}</div>}
      <Select
        placeholder={placeholder || "선택해주세요"}
        isSearchable={false}
        value={selectedValue}
        name={name}
        className={`react-select-container ${className}`}
        classNamePrefix="react-select"
        onChange={(e) => {
          console.log("e", e);
          e && onChange && onChange(getObj ? e : e.value);
        }}
        options={options}
        isDisabled={disabled}
      />
      {errorText && <p className="validation-text">{errorText}</p>}
    </div>
  );
};

export default BaseSelect;
