import { FocusEventHandler } from "react";

type Props = {
  name?: string;
  value?: string;
  isInvalid?: boolean; // 유효하지 않을 경우 true (invalidMessage 노출)
  invalidMessage?: string;
  isCompleted?: boolean; // 완료되었을 경우 true (check-green-btn 노출)
  placeholder?: string;
  inputRef?: any;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  wrapClassName?: string;
  inputClassName?: string;
  completeButtonClassName?: string; // 없으면 기본 check-green-btn
  validationTextClassName?: string; // 없으면 undefined
  onChange?: (value?: string, e?: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUpEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  pattern?: string;
  type?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

/**
 * input text 공통 컴포넌트
 */
const BaseTextInput = ({
  name,
  value,
  isInvalid,
  invalidMessage,
  isCompleted,
  placeholder,
  inputRef,
  readOnly,
  disabled,
  maxLength,
  wrapClassName,
  inputClassName,
  completeButtonClassName,
  validationTextClassName,
  type,
  onChange,
  onKeyUp,
  onKeyUpEnter,
  pattern,
  onBlur,
}: Props) => {
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onChange) {
      onChange(e.target?.value, e);
    }
  };

  const _onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onKeyUp) {
      onKeyUp(e);
    }
    if (e.key === "Enter") {
      if (onKeyUpEnter) {
        onKeyUpEnter(e);
      }
    }
  };

  const _onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (onChange) {
      onChange("");
    }
  };

  return (
    <div className={`${wrapClassName ? wrapClassName : "login-input-wrap"} ${isInvalid ? "--invalid" : ""}`}>
      <input
        ref={inputRef}
        className={`${inputClassName ? inputClassName : "login-input"}`}
        name={name}
        pattern={pattern}
        type={type ? type : "text"}
        value={value || ""}
        onChange={_onChange}
        onBlur={(e) => {
          onChange && onChange(e.target.value.trim(), e);
          onBlur && onBlur(e);
        }}
        onKeyUp={_onKeyUp}
        placeholder={placeholder}
        readOnly={readOnly ? true : false}
        disabled={disabled ? true : false}
        maxLength={maxLength}
      />
      {isCompleted ? (
        <button type="button" className={completeButtonClassName ? completeButtonClassName : "check-green-btn"} disabled></button>
      ) : (
        (value || "").length > 0 && <button type="button" className="clear-btn" onClick={_onClickClear}></button>
      )}
      {invalidMessage && <p className={validationTextClassName}>{invalidMessage}</p>}
    </div>
  );
};

export default BaseTextInput;
