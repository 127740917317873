import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import { ProductGuide, ProductGuideByList, ProductGuideDetail, ProductGuideResponseData, ServiceUserGuide } from "./guide-types";

// /**
//  * TPP02. (무인증)공간상품 사용안내 방문자용 상세 조회 (old)
//  */
// export async function getPublicVisitorGuideAsync(
//   axios: AxiosInstance,
//   params?: {
//     productId: number | string;
//     buildingId: number | string;
//   },
// ): Promise<AxiosResponse<ApiResponse<VisitorGuideResponseData>>> {
//   if (!params?.productId) {
//     throw Error("productId is not found.");
//   }
//   if (!params?.buildingId) {
//     throw Error("buildingId is not found.");
//   }
//   return await axios.get(`/api/court/pr/public/guide/${params.productId}/${params.buildingId}`);
// }

/**
 * TPP03. (무인증) 공간상품 이용안내 상세조회
 */
export async function getPublicGuideAsync(
  axios: AxiosInstance,
  params?: {
    productId: string;
  },
): Promise<AxiosResponse<ApiResponse<ProductGuideResponseData>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  return await axios.get(`/api/court/pr/public/${params.productId}/productGuide`);
}

/**
 * TPP04. (인증) 공간상품 사용안내 목록조회
 */
export async function getProductGuidesAsync(
  axios: AxiosInstance,
  params?: {
    productId: string | number; // 공간상품 ID
    buildingId?: string | number;
  },
): Promise<AxiosResponse<ApiResponse<{ content: ProductGuideByList[] }>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  let queryParams = {};
  if (params?.buildingId) {
    queryParams = { ...queryParams, ...{ buildingId: params.buildingId } };
  }
  return await axios.get(`/api/court/pr/products/${params.productId}/product-guide`, {
    params: queryParams,
  });
}

/**
 * TPP04. (무인증) 공간상품 사용안내 목록조회
 */
export async function getPublicProductGuidesAsync(
  axios: AxiosInstance,
  params?: {
    productId: string | number; // 공간상품 ID
    buildingId?: string | number;
  },
): Promise<AxiosResponse<ApiResponse<{ content: ProductGuideByList[] }>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  let queryParams = {};
  if (params?.buildingId) {
    queryParams = { ...queryParams, ...{ buildingId: params.buildingId } };
  }
  return await axios.get(`/api/court/pr/public/${params.productId}/product-guide`, {
    params: queryParams,
  });
}

/**
 * TPP05. (인증) 공간상품 사용안내 상세조회
 */
export async function getProductGuideAsync(
  axios: AxiosInstance,
  params?: {
    guideId: string; // 이용안내 ID
    isOpened?: boolean; // false: 모든 네트워크 네트워크 노출, true: 방문자용 네트워크만 노출
  },
): Promise<AxiosResponse<ApiResponse<{ content: ProductGuide }>>> {
  if (!params?.guideId) {
    throw Error("guideId is not found.");
  }
  let queryParams = {};
  queryParams = { ...queryParams, ...{ isOpened: Boolean(params?.isOpened) } };
  return await axios.get(`/api/court/pr/products/product-guide/${params.guideId}`, {
    params: queryParams,
  });
}

/**
 * TPP05. (무인증) 공간상품 사용안내 상세조회
 */
export async function getPublicProductGuideAsync(
  axios: AxiosInstance,
  params?: {
    guideId: string; // 이용안내 ID
    isOpened?: boolean; // false: 모든 네트워크 네트워크 노출, true: 방문자용 네트워크만 노출
  },
): Promise<AxiosResponse<ApiResponse<{ content: ProductGuideDetail }>>> {
  if (!params?.guideId) {
    throw Error("guideId is not found.");
  }
  let queryParams = {};
  queryParams = { ...queryParams, ...{ isOpened: Boolean(params?.isOpened) } };
  return await axios.get(`/api/court/pr/public/product-guide/${params.guideId}`, {
    params: queryParams,
  });
}

/**
 * TEU01. (인증) 공간상품 사용안내 목록조회 todo::인증api로 교체
 */
export async function getGuideListAsync(
  axios: AxiosInstance,
  params?: {
    serviceType: string | number;
    serviceId: string | number;
    buildingId?: string | number;
    userGuideId?: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: ServiceUserGuide[] }>>> {
  if (!params?.serviceType || !params?.serviceId) {
    throw Error("params is not found.");
  }

  return await axios.get(`/api/court/et/serviceUserGuides`, {
    params,
  });
}
/**
 * TEU02. (무인증) 공간상품 사용안내 목록조회
 */
export async function getGuidePublicListAsync(
  axios: AxiosInstance,
  params?: {
    serviceType: string | number;
    serviceId: string | number;
    buildingId?: string | number;
    userGuideId?: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: ServiceUserGuide[] }>>> {
  if (!params?.serviceType || !params?.serviceId) {
    throw Error("params is not found.");
  }

  return await axios.get(`/api/court/et/public/serviceUserGuides`, {
    params,
  });
}
