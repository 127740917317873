import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface state {
  isOpen: boolean;
  children?: React.ReactNode;
  type?: string;
  className?: string;
  isNotUseWhiteBoard?: boolean;
  opacity?: number; // 0 ~ 1 default 0.6
}
/*
  BaseAbstractModal
  커스텀 가능한 모달
*/
const BaseAbstractModal = (props: state) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef<HTMLElement | null>(null);
  useEffect(() => {
    ref.current = document.getElementById("modal");
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!props.isOpen) return null;

  return mounted
    ? createPortal(
        <>
          <div className="dim" style={props.opacity ? { backgroundColor: `rgba(0,0,0,${props.opacity})` } : {}}>
            <div className={`base-abstract-modal ${props.className ? props.className : ""}`}>{props.children && <>{props.children}</>}</div>
          </div>
        </>,
        ref.current!,
      )
    : null;
};

export default BaseAbstractModal;
