import { ko } from "date-fns/esm/locale";
import React from "react";
import DatePicker from "react-datepicker";

type Props = {
  label?: string;
  value?: Date | null | undefined;
  dateFormat?: string;
  name?: string;
  onChange?: (value: Date) => void;
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
  wrapClassName?: string;
  className?: string;
  errorText?: string;
  disabled?: boolean;
  placeholder?: string;
};

/**
 * date picker 공통 컴포넌트
 */
const BaseDatePicker = ({
  label,
  value,
  dateFormat,
  name,
  onChange,
  minDate,
  maxDate,
  wrapClassName,
  className,
  errorText,
  disabled,
  placeholder,
}: Props) => {
  const handleFocus = (e: any) => {
    const { target } = e;

    if (target) {
      target.readOnly = true; // -------> this for all others
      target.blur(); //  ------> this for ios iphone
    }
  };
  return (
    <div className={`base-datepicker ${wrapClassName ? wrapClassName : ""}`}>
      {label && <div className="base-datepicker__prefix">{label}</div>}
      <DatePicker
        placeholderText={placeholder ? placeholder : "선택해주세요."}
        name={name}
        selected={value}
        className={className || ""}
        closeOnScroll={true}
        onChange={(value: Date) => onChange && onChange(value)}
        onChangeRaw={(e) => e.preventDefault()}
        locale={ko}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat || "yyyy.MM.dd"}
        disabled={disabled}
        showPopperArrow={false}
        onFocus={handleFocus}
        calendarClassName="base-datepicker full-datepicker"
      />
      {errorText && <p className="validation-text">{errorText}</p>}
    </div>
  );
};

export default BaseDatePicker;
