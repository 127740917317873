const NetworkFailure = () => {
  return (
    <div className="error-page">
      <section>
        <div className="error-icon ic-wifi"></div>
        <div className="error-message-wrap">
          <p>
            네트워크 연결 상태가 좋지 않아요.
            <br />
            잠시 후 다시 시도해보시겠어요?
          </p>
        </div>
      </section>
    </div>
  );
};
export default NetworkFailure;
