import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "src/components/layout/Header";

const OperationPolicy = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header
        headerType="CLOSE"
        title="코트(COURT) 이용 규칙"
        onClickCloseButton={() => {
          navigate(-1);
        }}
      />
      <article className="contents-detail">
        <h1 className="base-title">코트(COURT) 이용 규칙</h1>
        <section className="contents-detail__contents">
          {`본 코트 이용 규칙(이하 ‘본 이용 규칙’)은 코트 공간 및 시설에 대한 이용 규칙을 규정합니다. 코트 운영사 핀포인트 주식회사(이하 '당사')의 재량에 따라 통지 후에 또는 통지 없이 변경, 개정, 수정할 수 있습니다. [회원가입 계약]과 본 이용규칙 간에 상충이 있을 경우 회원가입 계약이 우선 적용됩니다.
코트는 회원들의 비즈니스가 번창할 수 있는 안전하고 편안한 업무 환경을 제공하기 위해 노력하고 있으며, 이를 위한 시설 이용 규칙은 다음과 같습니다. 


제1조 	정의

별도의 명시가 없는 한 본 이용 규칙의 용어는 회원가입 계약 제1조에 정의된 바와 같은 의미를 뜻합니다.


제2조	일반적인 의무 사항

1. 회원 및 사용자는 다른 회원을 포함한 이용객 및 코트 직원을 존중해야 합니다. 코트 매니저, 다른 코트 회원 또는 이용객의 업무를 방해하거나, 괴롭히는 행위는 허용되지 않으며, 위협 또는 폭력을 가해서는 안됩니다.

2. 회원 및 사용자는 비즈니스와 관련하여 어떤 방식으로든 코트의 명의를 무단으로 사용해서는 안 됩니다. 더불어, 당사의 사전 서면 동의를 받지 않는 한 코트의 주소를 회원의 사업자 소재지 등록 등 기타 법령상 요구하는 목적과 관련하여 사용해서는 안됩니다. 


제3조	배정 사무 공간 이용 규칙

1. 정규 운영 시간은 평일 오전 9시부터 오후 6시(주말 공휴일 제외)입니다. 해당 시간 및 그 이후에 공용 또는 배정 사무 공간의 출입문을 회원의 임의로 개방하여 발생하는 모든 손실 또는 손해 발생에 대한 책임은 회원 및 사용자에게 있습니다.

2. 회원 및 사용자는 본 이용 규칙을 준수하고 배정 사무 공간을 이용하여야 합니다. 회원 및 사용자는 배정 사무 공간 이용 시 단정한 복장을 착용해야 하고, 다른 회원들의 업무에 방해되지 않도록 소음 발생에 유의해야 합니다. 회원 및 사용자가 부적절한 행동을 하거나, 다른 코트 회원들을 방해할 경우 코트 매니저는 부적절한 행동을 하는 자에게 퇴실을 요청할 수 있습니다.

3. 화재 또는 폭발을 유발할 수 있는 액체 연료, 가솔린, 등유, 가스 등을 배정 사무 공간에 반입하는 행위는 허용되지 않습니다.

4. 전기는 일반 조명, 개인용 컴퓨터 및 소형 기기에 전원을 공급하는 용도로만 사용해야 합니다. 기타 전력 소모가 많은 장비(냉난방 기기 설치 등)에는 추가 요금이 부과됩니다.

5. 배정 사무 공간에 애완동물(맹인 안내견은 제외)을 반입해서는 안됩니다.

6. 회원 및 회원의 방문객은 음료 이용을 위해 공용 팬트리 시설을 이용하는 것이 허용됩니다. 

7. 배정 사무 공간을 주류, 마약 또는 담배의 제조, 판매, 이용을 위해 사용해서는 안 됩니다. 

8. 배정 사무 공간에서 외판, 호객행위 및 행상을 하는 것은 금지되며 코트 매니저의 사전 서면 승인 없이는 다른 코트 회원을 포함한 타인에게 영리 목적의 어떠한 권유나 영업활동은 허용되지 않습니다.

9. 배정 사무 공간의 모든 장소에서 흡연은 허용되지 않으며, 적발 시 10만 원의 벌금이 부과됩니다. 

10. 회원 및 사용자가 다른 코트 회원들에게 부적절하게 행동할 경우 코트매니저는 부적절한 행동을 하는 자에게 배정 사무 공간에서의 퇴실을 요구할 수 있습니다.

11. 회원 및 사용자는 코트 매니저의 사전 동의 없이 제3자에게 출입 QR코드를 양도하는 것이 불가능하며, 제3자와 출입 권한을 공유할 수 없습니다. 제3자의 출입이 확인되거나 합리적인 정황에 따라 당사가 배정 사무 공간의 추가적인 보안 유지가 필요하다고 생각될 경우, 회원 및 사용자는 잠금장치 변경 비용을 직접 부담해야 합니다. 이에 따른 잠금장치 변경 작업은 반드시 당사의 협력사를 통해 진행되어야 합니다. 


제4조 공동 이용 구역에 대한 이용 규칙

1. 모든 복도, 현관, 엘리베이터 및 계단은 출입 목적 이외의 용도로 사용될 수 없으며, 회원의 이용을 방해하도록 사용해서는 안됩니다. 모든 공용 이용 구역은 항상 청결하게 유지해야 합니다.

2. 공용 공간은 임시로 사용할 용도로만 사용할 수 있습니다. 또한, 공용 공간에서 다른 회원의 사용에 방해가 될 수 있는 2인 이상 규모의 회의나 상행위, 홍보 등 업무의 목적으로 사용해서는 안됩니다. 방문객이 회원을 동반하지 않은 상태로 1시간 이상 머무른다면, 코트 매니저는 그 방문객에게 당사의 시간제 이용권을 구입하도록 요구할 권리를 갖습니다. 

3. 공용 공간을 촬영 또는 행사 등을 위해 독점적으로 사용을 희망할 경우, 해당 행사 예정일로부터 최소 3일 전까지 코트 매니저의 동의를 구해야 합니다.

4. 공동 이용 구역의 책상은 한 사람당 한 개의 좌석만 사용 가능합니다.

5. 자리를 비운 상태로 공용 공간에 한 시간 이상 방치된 물품(이하 '방치 물품')에 대하여 당사는 분실 등 일체의 책임을 지지 않습니다. 또한 당사는 방치 물품을 제거 또는 처분할 권리가 있으며, 방치 물품을 처분하는 과정에서 발생하는 일체의 비용 및 손해 발생 시, 물품 소유자는 당사에게 이를 배상하여야 합니다.

6. 회원 및 사용자는 방치 물품을 임의로 제거해서는 안됩니다. 회원 및 사용자가 방치 물품이 있는 책상의 사용을 희망할 경우, 코트 매니저에게 문의해야 합니다.

7. 공용 공간은 청결하게 유지해야 하고, 사용을 마친 후에는 모든 쓰레기 및 개인 비품을 정리해야 합니다. 회원 및 사용자가 공용 공간을 훼손하거나 청소하지 아니한 경우, 당사는 이에 따라 발생하는 일체의 비용(청소비, 수리비, 이로 인한 손해배상금액 등을 포함하며 이에 한정하지 않음)을 회원 및 사용자에게 청구할 수 있으며, 사용자는 이를 배상해야 합니다.


제5조	배정 사무 공간의 물품 이용 규칙

1. 당사는 회원의 배정 사무 공간 입주 시 지급되는 가구 및 물품(이하 ‘지급 가구 및 물품')의 목록을 서면으로 전달하며, 회원은 이를 확인해야 합니다.

2. 배정 사무 공간의 모든 지급 가구 및 물품의 소유권은 당사가 보유합니다.

3. 회원은 배정 사무 공간의 모든 지급 가구 및 물품을 신의성실의 원칙에 따라 관리하며 사용하여야 합니다.

4. 회원 및 사용자는 코트 매니저의 사전 동의 없이 창, 벽 또는 할당된 사무 공간 내에 어떠한 것도 부착, 설치하거나, 시설 (전선, 바닥 등 포함)을 변경 또는 추가할 수 없습니다. 코트 매니저의 동의하에 진행되는 제1문의 작업은 반드시 당사의 협력사를 통하여 진행되어야 합니다. 


제6조	추가 서비스

1. 정규 운영 시간 이외에 촬영 또는 행사의 목적으로 공용공간 및 배정 사무공간을 사용하려는 경우 코트 매니저의 승인을 받아야 하며, 특별 사용에 대한 추가 요금이 부과될 수 있습니다.

2. 회원이 이용하고 있는 모든 추가 서비스(예 : 월별 락커 사용, 추가 가구 대여 등)는 익월 결제일 30일 전까지 코트 매니저에게 사용 해지를 통지하지 않는 한 유지됩니다. 서비스 사용을 중도 해지할 경우, 그 사용 요금에 대하여 일할로 계산하여 환불하지 않으며, 당해 사용기간 만료일까지의 비용을 모두 지급해야 합니다.


제7조	환불 규정 

1. 회원은 시간제 예약 상품에 한정하여 사용 취소 의사를 통지함으로써 사용 신청을 취소할 수 있습니다. 다만, 회원이 서비스를 이용을 시작하기로 한 시각의 30분 이전까지만 취소 및 환불이 가능하며 그 이후에 요청할 경우 취소 및 환불이 불가능합니다.

2. 신용카드로 결제한 회원이 환불을 요청할 경우 구매 취소 시점과 해당 카드사의 환불 처리 기준에 따라 환급 방법과 환급일은 다소 차이가 있을 수 있으며, 사용한 신용카드의 환불 정책은 해당 카드사 정책에 따릅니다.

3. 제1항의 회원의 해지 신청에도 불구하고 다음 각 호 중 하나에 해당하는 경우 취소 및 환불 요청이 제한될 수 있으며, 제한 사유가 해소된 후 취소 및 환불이 가능합니다.

ⅰ. 회원에게 미납된 사용요금, 연체 이자율 및 위약금이 있는 경우
ⅱ. 회원의 귀책으로 코트 공간 내외부 시설의 파손, 훼손, 변경 및 기타 손해가 발생한 경우
ⅲ. 기타 회원의 귀책으로 회사에 손해가 발생한 경우


제8조	IT 와 기술 정책

1. 회원 및 사용자는 당사가 제공하는 인터넷과 통신 연결 서비스 및 장비의 사용과 관련하여 모두 지정된 정책을 준수해야 합니다.

2. 당사는 모든 회원과 그 손님이 사용하도록 공유 인터넷 연결을 제공합니다. 이러한 인터넷 연결은 모든 회원 간에 공유되므로 회원은 코트 매니저의 지시에 따라 인터넷 연결을 공정하게 사용하는 데 동의합니다.


제9조	인터넷 및 통신 정책

1. 회원 및 사용자는 당사가 인터넷 액세스, 전화, 팩스 회선, 데이터 회선 (이하 "통신 회선") 등 당사의 통신 회선이나 장비를 통해 전송된 정보의 내용을 모니터링하지 않음에 동의하며, 당사는 회원 개인의 발송 내역 또는 결과에 대해 책임지지 않음을 인정합니다.

2. 회원 및 사용자의 인터넷 액세스는 합법적인 목적으로만 사용할 수 있으며 주, 연방 또는 국제 법률, 규정 또는 기타 정부 요구 사항에 대한 형사 또는 민사상 위반 행위와 관련하여 사용해서는 안 됩니다. 해당 위반 행위에는 저작권, 상표, 영업 비밀 또는 기타 유형의 지적재산권 도용 또는 침해, 사기, 위조, 자금/신용 카드/개인 정보의 절도 또는 횡령, 수출 통제 법규의 위반, 비방 또는 명예 훼손, 신체적 상해 위협이나 괴롭힘, 또는 형사 범죄 행위나 민사상 책임을 초래하는 행위 등이 포함됩니다. 

3. 당사는 등록된 회원에게만 인터넷 액세스 권한을 부여합니다. 회원 및 사용자는 유선 분할, 라우터 또는 네트워크 스위치 등 안전하지 않은 무선 장치 등을 사용하여 통신 회선의 액세스 포인트를 증가시킬 수 없으며, 이 조항을 위반할 경우, 당사는 회원 및 사용자에게 영업일 기준 3일 전에 서면 통지 후에 통신 회선에 대한 모든 액세스를 차단할 수 있습니다. 승인되지 않은 통신 회선 사용으로 인해 추가 요금이 발생한 경우, 회원 및 사용자는 모든 요금을 납부해야 합니다.

4. 시스템 또는 네트워크 보안과 관련한 모든 위반 행위가 금지됩니다. 해킹, 크래킹, 모니터링,  포트 스캐닝, 서비스 거부(DoS) 공격 수행, 및 바이러스 또는 기타 유해한 소프트웨어를 배포하는 행위 등이 위반 행위에 포함되며, 당사는 해당 행위에 대해 제3자, 정부 기관 또는 규제 당국으로부터 통지를 받을 경우, 회원 및 사용자의 인터넷 액세스를 즉각 중단할 권리를 보유하며 회원 및 사용자는 그러한 남용에 대해 당사를 면책해야 합니다. 

5. 회원 및 사용자는 시스템 및 하드웨어에 자체 바이러스 보호 수단을 설치, 유지하고 최신 상태로 유지할 책임이 있으며, 당사는 바이러스, 맬웨어를 포함한 사이버 공격으로 인하여 회원 및 사용자가 입은 모든 손실 또는 손해에 대해 어떠한 책임도 지지 않습니다.

6. 인터넷 서비스는 배정 사무 공간에서만 사용 가능합니다. 회원 및 사용자는 당사의 동의 없이 당사 네트워크와 다른 네트워크 또는 통신 서비스간에 링크를 만들 수 없습니다.

7. 당사는 회원 및 사용자에게 서비스 제공을 지원하기 위해 요구되는 서류와 이용자 정보를 모든 회원 및 사용자에게 요청할 수 있습니다. 

8. 자체 무선 액세스 포인트를 사용하는 회원 및 사용자는 설치 이전에 당사의 서면 승인이 필요합니다. 회원 및 사용자가 전용 라우터를 사용할 경우 할당된 사무실 공간에 따라 당사가 정한 서비스 요금이 부과됩니다.


제10조	 계약 종료 및 해지에 대한 규칙

1. 회원 계약이 만료되거나 조기 해지될 경우 그 즉시 사용을 중단해야 하고, 당사의 모든 관련된 지시를 준수해야 합니다.

2. 계약이 종료될 경우 회원은 다음과 의무와 책임이 있음을 인정하고 동의합니다.

ⅰ. 회원은 배정 사무 공간의 입주 시 제공된 지급 가구 및 물품 목록에 기재된 지급 가구 및 물품을 제외하고 모든 물품을 수거하고 원상복구한 상태로 배정 사무 공간을 반납해야 합니다. 이를 이행하지 않아 발생한 일체의 수거 및 철거 비용은 회원이 당사에게 지급하여야 합니다.
ⅱ. 지급 가구 및 물품이 분실되거나 손상된 경우에 당사의 코트매니저는 회원에게 당사의 요금 기준에 따라 교체에 따른 일체의 비용을 청구할 수 있으며, 회원은 이를 배상해야 합니다.
ⅲ. 계약이 종료되는 즉시 회원 또는 회원의 직원에게 발급된 출입 권한은 제한됩니다.
ⅳ. 계약 종료일로부터 25일 이내에 관련 기관에 코트의 주소로 등록된 법적 또는 사업자 주소를 변경 또는 말소해야 하며, 변경된 사업자 주소지로 발급된 사업자 등록증명 (사업자등록증과 상이함) 또는 폐업 신고서를 코트 매니저에게 전달해야 합니다. 이와 관련하여 필요한 조치는 회원이 행하며, 이에 따른 일체의 비용은 회원이 부담합니다. 위 사항을 이행하지 않을 시, 당사는 회원에게 위약벌로서 계약 종료일부터 일 20,000원(부가세 별도)로 계산한 금액을 청구할 수 있으며, 회원은 이를 배상해야 합니다. 위 위약벌은 회원의 보증금에서 별도의 고지 없이 공제될 수 있습니다. 


제11조 우편

1. 당사는 회원을 대신하여 소포를 제외한 우편만 대리 수령합니다. 

2. 회원은 수신된 우편과 관련하여 발생하는 모든 책임(분실 또는 훼손 포함)으로부터 당사를 면책하며, 당사는 이에 대한 책임을 지지 않습니다.


제12조	 개인정보보호

1. 서비스 또는 추가 서비스를 귀하에게 제공하는 과정에서 당사는 회원 및 사용자로부터 개인 정보를 수집할 수 있습니다. 회원 및 사용자는 코트의 서비스를 제공받는 조건의 정보 수집에 동의하고 당사는 해당 서비스를 제공할 목적의 정보 사용에 동의합니다.

2. 서비스를 제공받는 과정(방문객 등록 등)에서 제3자의 개인 정보를 당사에 공개하는 경우, 회원은 당사가 그 서비스를 제공할 수 있도록 제3자의 동의를 얻어 개인 정보를 공개하는 것임에 동의합니다. 

3. 본 이용 규칙에 따른 당사의 개인 정보 수집은 당사의 사이트에 명시된 개인정보처리방침에 따라 이루어집니다.

`}
        </section>
      </article>
    </div>
  );
};

export default OperationPolicy;
