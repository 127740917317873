import { useCallback, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useAnonymousPathPatterns } from "src/pages/hooks/anonymous-path-patterns";
import log from "src/utils/log";
import { route } from "src/vars";
import { CourtAuthError, CourtAuthProvider, CourtAuthState } from "../court-auth";
import Layout from "./Layout";
import MetaTag from "./MetaTag";

/**
 * App > Container
 */
function Container() {
  const navigate = useNavigate();
  // useNaverMap();
  // 인증을 거치지 않는 anonymous route path 를 관리하는 hook
  const { matchAnonymous } = useAnonymousPathPatterns();

  // 인증(사용자 로그인) 후 리다이렉트 되었을 경우 호출
  const onRedirect = useCallback(
    (state: CourtAuthState) => {
      const { returnTo } = state;
      if (returnTo) {
        log("Container > onRedirect", `returnTo: ${JSON.stringify(returnTo, null, 2)}`);
        const { uri } = returnTo.latest;
        const basePath = route.basename;
        const basePathIndex = uri.indexOf(basePath);
        const returnUri = basePathIndex >= 0 ? uri.substring(basePathIndex + basePath.length) : uri;
        console.debug("returnUri", returnUri);
        navigate(returnUri, { replace: true });
      } else {
        log("Container > onRedirect", `returnTo: 없음`);
        // 마이페이지 > 신청/계약 목록으로 이동
        navigate(route.mainPath);
      }
    },
    [navigate],
  );

  // 인증 처리(사용자 로그인, 쿠키에서 로드, 토큰 갱신) 중 에러 발생 시 호출
  const onError = useCallback(
    (error: any) => {
      log("Container > onError", error);
      if ("code" in error) {
        const tokenError = error as CourtAuthError;
        switch (tokenError.code) {
          case "jwt::expired":
            log("Container > onError", `code: ${tokenError.code}, message: ${tokenError.message}`);
            break;
        }
      }

      if (!matchAnonymous) {
        log("Container > onError > 인증 페이지인데 오류 발생");

        // 로그인 페이지 이동은 auth-required.tsx 에서 처리함
      }
    },
    [navigate, matchAnonymous],
  );

  return (
    <CourtAuthProvider
      issuer={process.env.REACT_APP_COURT_AUTH_ISSUER!}
      clientId={process.env.REACT_APP_COURT_AUTH_CLIENT_ID!}
      endpoints={{
        authorize: `${process.env.REACT_APP_COURT_AUTH_ISSUER}/authorize`,
        token: `${process.env.REACT_APP_COURT_AUTH_ISSUER}/oauth/token`,
        revoke: `${process.env.REACT_APP_COURT_AUTH_ISSUER}/oauth/revoke`,
      }}
      redirectUri={process.env.REACT_APP_COURT_AUTH_REDIRECT_URI!}
      scope="openid email phone"
      pending={<></>} // loading 안보이게 처리
      onRedirect={onRedirect}
      onError={onError}
    >
      <HelmetProvider>
        <MetaTag />
        <Layout />
      </HelmetProvider>
    </CourtAuthProvider>
  );
}

export default Container;
