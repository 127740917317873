import { useCallback, useState } from "react";
import { ConfirmModal } from "src/components";

type Props = {
  name?: string;
  value?: string;
  isInvalid?: boolean; // 유효하지 않을 경우 true
  leftComponent?: React.ReactNode;
  isLoading?: boolean; // 로딩중
  isCompleted?: boolean; // 완료되었을 경우 true (check-green-btn 노출)
  placeholder?: string;
  inputRef?: any;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  wrapClassName?: string;
  inputClassName?: string;
  completeButtonClassName?: string; // 없으면 기본 check-green-btn
  validationTextClassName?: string; // 없으면 undefined
  onChange?: (value?: string, e?: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUpEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

/**
 * 마이페이지 > 휴대폰 변경 > 인증번호 인풋
 */
const SmsCodeInput = ({
  name,
  value,
  isInvalid,
  leftComponent,
  isLoading,
  isCompleted,
  placeholder,
  inputRef,
  readOnly,
  disabled,
  maxLength,
  wrapClassName,
  inputClassName,
  completeButtonClassName,
  onChange,
  onKeyUp,
  onKeyUpEnter,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onChange) {
      onChange(e.target?.value, e);
    }
  };

  const _onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onKeyUp) {
      onKeyUp(e);
    }
    if (e.key === "Enter") {
      if (onKeyUpEnter) {
        onKeyUpEnter(e);
      }
    }
  };

  // 전화문의 버튼 클릭
  const onClickTel = useCallback(() => {
    window.location.href = "tel:02-3442-6610";
  }, []);

  return (
    <div className={`${wrapClassName ? wrapClassName : "base-input-wrap"}`}>
      <input
        ref={inputRef}
        type="text"
        className={`${inputClassName ? inputClassName : "base-input"} ${isInvalid ? "border-bottom-red" : ""}`}
        name={name}
        value={value || ""}
        onChange={_onChange}
        onKeyUp={_onKeyUp}
        placeholder={placeholder}
        readOnly={readOnly ? true : false}
        disabled={disabled ? true : false}
        maxLength={maxLength}
      />

      {isLoading && <button type="button" className={"only-text --loading"} disabled></button>}

      {isCompleted && <button type="button" className={completeButtonClassName ? completeButtonClassName : "check-green-btn"} disabled></button>}

      <div className="validation-wrap position-absolute">
        {leftComponent && leftComponent}
        <div className="validation-wrap__right">
          <button type="button" className="right-chevron-btn" onClick={() => setIsModalOpen(true)}>
            인증 번호가 오지 않나요?
          </button>
        </div>
      </div>

      <ConfirmModal
        isOpen={isModalOpen}
        btnLeftTitle="고객센터"
        btnRightTitle="확인"
        onClose={() => {
          onClickTel();
        }}
        onClick={() => {
          setIsModalOpen(false);
        }}
        className="px20 pb20"
        children={
          <div className="email-auth-info-modal">
            <p className="font18 font-weight-600 mb20">인증번호가 안 온다면?</p>
            <span className="font-weight-600">1. 수신차단 확인하기</span>
            <p>“010-1234-1234” 번호가 수신 차단되어있는지 확인해 주세요.</p>
            <br />
            <span className="font-weight-600">2. 통신사 차단 확인하기</span>
            <p>이용중인 통신사 고객센터를 통해 XXXX, XX 등의 번호에 대한 자동 차단 서비스에 가입되어 있는지 확인해주세요.</p>
            <br />
            <p>통신사 고객센터 : 국번 없이 114</p>
            <br />
            <p>
              그래도 해결되지 않는다면 고객센터로 문의해주세요.
              <br />
              <span className="text-primary3">(운영시간 - 평일 09:00~18:00)</span>
            </p>
          </div>
        }
      />
    </div>
  );
};

export default SmsCodeInput;
