const PageNotAllowed = () => {
  return (
    <div className="error-page">
      <section>
        <div className="error-icon ic-none"></div>
        <div className="error-message-wrap">
          <h2>권한 없음</h2>
          <p>요청 페이지에 대한 접근 권한이 없습니다</p>
        </div>
      </section>
    </div>
  );
};
export default PageNotAllowed;
