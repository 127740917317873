import React, { useState } from "react";
import { BuildingFloorModel, BuildingRoomModel } from "src/api/building/building-types";
import { ProductBuildingFloor, ProductBuildingRoom } from "src/api/product/product-types";
import { calculatePyeong, numberToStringWithComma } from "src/utils/common-util";
type Props = {
  moreData?: BuildingRoomModel[];
};
const BaseShowMore = ({ moreData }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      {isOpen && moreData && (
        <>
          {moreData?.map((room: any) => {
            return (
              <div className="mt16" key={room.id}>
                <p className="label">{`${room.floorNum}층 ${room.roomNum}호`}</p>
                <div className="building-area-wrap__line" key={room.id + "roomList"}>
                  <p>전용 :{`${numberToStringWithComma(Number(room.leasableAreaNet))}㎡ (${calculatePyeong(Number(room.leasableAreaNet), "")}평)`}</p>
                  <p className="right">
                    임대 : {`${numberToStringWithComma(Number(room.leasableArea))}㎡ (${calculatePyeong(Number(room.leasableArea), "")}평)`}
                  </p>
                </div>
              </div>
            );
          })}
        </>
      )}

      <button className="more" onClick={() => setIsOpen((prev) => !prev)}>
        {isOpen ? "접기" : "더보기"}
      </button>
    </div>
  );
};

export default BaseShowMore;
