import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { route } from "src/vars";
import Container from "./components/layout/Container";

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <BrowserRouter basename={route.basename}>
          <Container />
        </BrowserRouter>
      </RecoilRoot>
    </div>
  );
}

export default App;
