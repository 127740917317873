import { useState } from "react";

type TabProps = {
  title: string;
  key: string;
};
type Props = {
  onSelected: (selected: string) => void;
  tabs: TabProps[];
};

export const tabOptions = [
  { title: "예약", key: "TIME_COURT" },
  { title: "임대", key: "FULL_COURT" },
  { title: "전체", key: "" },
];

export const Tabs = (props: Props) => {
  const [selected, setSelected] = useState(props.tabs[0].key);

  return (
    <div className="tab-wrap">
      {props.tabs.map((tab, i) => (
        <button
          className={`tab ${selected === tab.key ? "--active" : ""}`}
          key={tab.key}
          onClick={() => {
            props.onSelected(tab.key);
            setSelected(tab.key);
          }}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
};
