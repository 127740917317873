import React, { useEffect, useMemo, useRef, useState } from "react";
import { BuildingModel } from "src/api/building/building-types";
import { Address } from "src/api/public-types";
import { useLocation } from "react-router-dom";
import { useToast } from "../hooks/toast";
import MetaTag from "src/components/layout/MetaTag";
import qs from "qs";

type Props = {
  building: BuildingModel;
  className?: string;
};

const MapView = ({ building, className }: Props) => {
  // (무인증) 건물 상세정보 목록 api

  const mapElement = useRef(null);
  const location = useLocation();
  const [mapPoint, setMapPoint] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isScriptLoading, setIsScriptLoading] = useState<boolean>(false);

  const { openToast } = useToast();
  const handleCopyClipBoard = async () => {
    const text = `${buildingAddress?.address} ${buildingAddress?.addressDetail}`;
    try {
      await navigator.clipboard.writeText(text);
      openToast({ content: "주소가 복사되었습니다" });
    } catch (err) {
      console.log(err);
      openToast({ content: "문제가 발생했습니다" });
    }
  };

  // 건물 상세

  // 건물 주소
  const buildingAddress: Address | null = useMemo(() => {
    if (!building || (building?.addressList || []).length === 0) return null;
    return (building?.addressList || [])[0];
  }, [building]);
  useEffect(() => {
    const { naver } = window;
    if (buildingAddress) {
      const checkScriptExistence = () => {
        const naverMapScript = document.getElementById("navermap");
        if (naverMapScript) {
          setIsScriptLoading(true);
          const fetchGeoCode = () => {
            naver?.maps.Service.geocode({ query: buildingAddress?.address || "" }, function (status, response) {
              if (status === naver.maps.Service.Status.ERROR) {
                return alert("Something wrong!");
              }
              if (response.v2.addresses.length > 0) {
                setMapPoint({
                  x: Number(response.v2.addresses[0].x || 0),
                  y: Number(response.v2.addresses[0].y || 0),
                });
              } else {
                if (retryCount < 5) {
                  retryCount++;
                  setTimeout(fetchGeoCode, 200);
                } else {
                  console.error("실패");
                }
              }
            });
          };
          fetchGeoCode();
          clearInterval(intervalId);
        }
      };
      const intervalId = setInterval(checkScriptExistence, 300);
    }
    let retryCount = 0;
  }, [buildingAddress, isScriptLoading]);

  useEffect(() => {
    // 좌표 (mapPoint) 가 변경될때 map 에 표기
    const { naver } = window;
    if (!mapElement.current || !naver) return;
    if (mapPoint.x === 0 && mapPoint.y === 0) return;

    // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
    const location = new naver.maps.LatLng(mapPoint.y, mapPoint.x);
    const mapOptions: naver.maps.MapOptions = {
      center: location,
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  }, [mapPoint]);

  return (
    <>
      <MetaTag naverLanguage={"ko"} />
      <section className={`${className ? className : ""} base-accordion no-icon`}>
        <div className="base-accordion__contents">
          <div className="flex-start-between pb10">
            <div className="map-address">
              <p>
                {buildingAddress?.address} {buildingAddress?.addressDetail}
              </p>
              <p>{building.feature}</p>
            </div>
            <span className="no-wrap ml10 map-address-copy" onClick={handleCopyClipBoard}>
              주소복사
            </span>
          </div>
          <div ref={mapElement} className="map-container bg-temp"></div>
        </div>
      </section>
    </>
  );
};

export default MapView;
