import { useCallback, useState } from "react";
import BaseFullModal from "src/components/BaseFullModal";
import ServiceTypeGuidelines from "./guide/ServiceTypeGuidelines";
import ServiceTypeRefundPolicy from "./guide/ServiceTypeRefundPolicy";

const ServiceTypeGuide = ({ guideInfo, refundInfo }: any) => {
  const [guideOpen, setGuideOpen] = useState(false);
  const [guideRefund, setRefundOpen] = useState(false);
  const closeGuide = useCallback(() => setGuideOpen(false), [setGuideOpen]);
  const closeRefund = useCallback(() => setRefundOpen(false), [setRefundOpen]);
  // console.log(guideInfo,refundInfo,'---------')
  return (guideInfo !== null && guideInfo !== undefined && guideInfo.initValue !== undefined && guideInfo.initValue !== "") ||
    (refundInfo !== null && refundInfo !== undefined && refundInfo.initValue !== undefined && refundInfo.initValue !== "") ? (
    <section className="base-section-layout top-divider">
      <div className="base-section-layout__wrap">
        {guideInfo !== null && guideInfo !== undefined && guideInfo.initValue !== undefined && guideInfo.initValue !== "" && (
          <div className="base-section-layout-line">
            <div className="base-section-layout-index cursor-pointer w-100">
              <div className="mt15 no-wrap" onClick={() => setGuideOpen(true)}>
                <div className="flex-center-between">
                  <span>유의사항</span>
                  <div className="arrow-right"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {refundInfo !== null && refundInfo !== undefined && refundInfo.initValue !== undefined && refundInfo.initValue !== "" && (
          <div className="base-section-layout-line">
            <div className="base-section-layout-index cursor-pointer w-100">
              <div className="mt15 no-wrap " onClick={() => setRefundOpen(true)}>
                <div className="flex-center-between">
                  <span>결제 및 취소 안내</span>
                  <div className="arrow-right"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {guideOpen && <BaseFullModal isOpen={guideOpen} children={<ServiceTypeGuidelines guideInfo={guideInfo} onClose={closeGuide} />} />}
        {guideRefund && <BaseFullModal isOpen={guideRefund} children={<ServiceTypeRefundPolicy refundInfo={refundInfo} onClose={closeRefund} />} />}
      </div>
    </section>
  ) : (
    <></>
  );
};
export default ServiceTypeGuide;
