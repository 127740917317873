import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getProductListAsync, getSearchProductListAsync } from "src/api/product/product-api";
import { BaseButton } from "src/components";
import { useWindowDimension } from "src/pages/hooks/window-dimension";
import { route } from "src/vars";

interface TaapCourtMatchingPath {
  taap: string;
  court: string;
  meta?: TaapCourtMatchingPathOption;
}

interface TaapCourtMatchingPathOption {
  type?: "reservation";
}

interface PathMatchingResult {
  result: boolean;
  variable: {
    [props: string]: string;
  };
}

interface PathMatchingInfo extends PathMatchingResult {
  index?: number;
  taap?: string;
  court?: string;
  meta?: TaapCourtMatchingPathOption;
}

const appScheme = process.env.REACT_APP_TAAP_APP_SCHEME!;
const appLink = `${appScheme}://${window.location.href.substring(window.location.href.indexOf("/front/taap") + 1)}`;
const redirectWebUrl = `https://static.taapspace.kr/link/taap.html?target=${window.location.href}`;

const taapCourtMatchingPaths: Array<TaapCourtMatchingPath> = [
  {
    taap: "/taap/my/contracts/:contractId",
    court: "/court/mypage/contracts/:contractId",
  },
  {
    taap: "/taap/my/contracts/:contractId/bills/:billOrder",
    court: "/court/mypage/contracts/:contractId/bills/:billOrder",
  },
  {
    taap: "/taap/reservation/room",
    court: "/court/product/:productId/facility/:facilityId",
    meta: {
      type: "reservation",
    },
  },
  {
    taap: "/taap/reservation/desk",
    court: "/court/product/:productId/facility/:facilityId",
    meta: {
      type: "reservation",
    },
  },
];

/**
 * /front/taap 으로 접근시 (taap 의 universal link) redirect 핸들링하는 화면
 */
const Taap = () => {
  /**
   * useNavigate
   */
  const navigate = useNavigate();

  /**
   * useRef
   */
  const intervalRef = useRef<NodeJS.Timer>();

  /**
   * useState
   */
  // 이동할 경로
  const [courtPath, setCourtPath] = useState<string>();

  // 높이값
  const { height } = useWindowDimension();

  // 카운트
  const [seconds, setSeconds] = useState<number>(5);

  /**
   * useApiOperation
   */
  // (무인증) 상품목록 api
  const { executeAsync: getProductList } = useApiOperation(getProductListAsync, {
    noAuthenticationRequired: true,
    noHandleError: true,
  });

  /**
   * useMemo
   */
  // 타이틀 마진
  const titleMarginTop = useMemo(() => {
    return Math.max(Math.min(40, height - 726), -50);
  }, [height]);

  /**
   * 함수
   */
  // 동일한 path 가 있는지 확인
  const getTaapCourtMatchingPathInfo = (): PathMatchingInfo => {
    const targetUrl = window.location.href;
    let pathMatchingResult: PathMatchingResult = { result: false, variable: {} };

    const taapCourtMatchingPathIndex = taapCourtMatchingPaths.findIndex(({ taap }) => {
      const [targetPath] = targetUrl.split("?");
      const result = sameEndPath(targetPath, taap);
      if (result.result) {
        pathMatchingResult = result;
        return true;
      }
      return false;
    });

    if (!pathMatchingResult.result) {
      return pathMatchingResult;
    }

    const taapCourtMatchingPath = taapCourtMatchingPaths[taapCourtMatchingPathIndex];
    return {
      result: pathMatchingResult.result,
      variable: pathMatchingResult.variable,
      index: taapCourtMatchingPathIndex,
      taap: taapCourtMatchingPath.taap,
      court: taapCourtMatchingPath.court,
      meta: taapCourtMatchingPath.meta,
    };
  };

  // 동일 path 인지 체크
  const sameEndPath = (target: string, source: string): PathMatchingResult => {
    var targetPaths = target.split("/").reverse();
    var sourcePaths = source
      .split("/")
      .filter((item) => !!item)
      .reverse();

    const variable: { [props: string]: string } = {};

    const result = sourcePaths.every((item, index) => {
      if (item.startsWith(":") && targetPaths?.[index]) {
        variable[item.substring(1)] = targetPaths?.[index];
        return true;
      }

      return item === targetPaths?.[index];
    });

    return {
      result,
      variable,
    };
  };

  // path 생성 (path variable 포함)
  const generatePath = (url: string, variable: { [props: string]: string }) => {
    const [path, queryParam] = url.split("?");
    const pathItems = path.split("/");

    const resultPath = pathItems
      .map((item) => {
        if (item.startsWith(":")) {
          return variable[item.substring(1)];
        }
        return item;
      })
      .join("/");

    if (!queryParam) {
      return resultPath;
    }

    return `${resultPath}?${queryParam}`;
  };

  /**
   * reservation path 생성 - 이동가능한지 판단후 이동 설정
   * 만약 이동 불가할 경우 홈으로 이동
   */
  const generateReservationPath = async (taapCourtMatchingInfo: PathMatchingInfo) => {
    const urlParams = new URLSearchParams(window.location.search);
    const facilityId = urlParams.get("facilityId");
    if (!facilityId) {
      console.debug("!facilityId", !facilityId);
      return route.mainPath;
    }

    try {
      const { data } = await getProductList({
        buildingCommonFacilityId: facilityId,
        productType: "TIME_COURT",
        page: 0,
        size: 1,
        sort: {
          orders: [
            {
              property: "modifiedDate",
              direction: "DESC",
            },
          ],
        },
      });

      console.debug(
        "data.data.content[].id",
        data?.data?.content?.map((item) => item?.id),
      );

      const productId = data?.data?.content?.[0]?.id;
      if (!productId) {
        return route.mainPath;
      }

      const { court } = taapCourtMatchingInfo;

      const path = generatePath(court!, { productId: productId!, facilityId: facilityId });
      console.debug("path", path);

      return path;
    } catch (error) {
      console.error("error", error);
      return route.mainPath;
    }
  };

  // 리다이렉트 처리
  const redirect = useCallback(
    async () => {
      // 1. Deep link 연결 시도 (업이 설치된 경우 이동됨)
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          try {
            window.location.replace(appLink);
          } catch (error) {
            // do nothing
          }

          resolve();
        }, 100);
      });

      // 2-1. Court 페이지 리다이렉트 필요한한 페이지인지 확인
      const taapCourtMatchingInfo = getTaapCourtMatchingPathInfo();
      console.debug("taapCourtMatchingInfo", taapCourtMatchingInfo);

      // 2-2. 매칭되는 court path가 있을경우 court 페이지로 이동
      const { result, variable, court, meta: option } = taapCourtMatchingInfo;
      if (result) {
        // path option 타입 이 있는지 확인, 없으면 path 이동
        if (!option?.type || option?.type !== "reservation") {
          const courtPath = generatePath(court!, variable);
          console.debug("courtPath", courtPath);
          setCourtPath(courtPath);
          return;
        }

        /**
         * reservation 타입인 경우 - 이동가능한지 판단후 이동 설정
         * 만약 이동 불가할 경우 홈으로 이동
         */
        const courtPath = await generateReservationPath(taapCourtMatchingInfo);
        setCourtPath(courtPath);
        return;
      }

      // 3. static page 로 이동
      setTimeout(() => {
        window.location.replace(redirectWebUrl);
      }, 500);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // 해당 경로로 이동하기
  const onClickMoveButton = () => {
    if (courtPath) {
      navigate(courtPath);
    }
  };

  /**
   * useEffect
   */
  // 리다이렉트 처리
  useEffect(() => {
    redirect();
  }, [redirect]);

  // 카운트다운
  useEffect(() => {
    if (!courtPath && intervalRef.current) {
      return;
    }

    intervalRef.current = setInterval(() => {
      setSeconds((prev) => {
        if (prev === 0) {
          console.debug("seconds", prev);
          console.debug("seconds clearInterval");
          clearInterval(intervalRef.current);
          intervalRef.current = undefined;
          return prev;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      console.debug("useEffect return clearInterval");
      clearInterval(intervalRef.current);
    };
  }, [courtPath]);

  // 페이지 자동 이동
  useEffect(() => {
    if (courtPath && seconds === 0) {
      navigate(courtPath);
    }
  }, [courtPath, navigate, seconds]);

  // courtPath 가 없으면 표시 내용 없음
  if (!courtPath) {
    return <></>;
  }

  return (
    <div className="taap-page" style={{ height: height }}>
      <div className="taap-contents">
        <div className="title" style={{ marginTop: titleMarginTop }}>
          Play work with Taap
        </div>
        <div className="taap-icon"></div>
        <p className="taap-desc">
          출입부터 예약, 방문자 초대, 불편사항 접수까지
          <br />더 스마트한 라이프를 지원합니다.
        </p>
      </div>
      <div className="redirect-area">
        <div className="redirect-desc">
          <div className="redirect-desc-icon"></div>
          <div className="redirect-desc-text">{seconds}초 후에 페이지가 자동으로 이동합니다</div>
        </div>
        <BaseButton title="해당 페이지로 이동하기" onClick={onClickMoveButton} />
      </div>
    </div>
  );
};
export default Taap;
