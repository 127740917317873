import React, { ComponentType, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { withAuthRequired } from "src/components/court-auth";
import Unauthenticated from "src/pages/Unauthenticated";
import log from "src/utils/log";

interface ProtectedRouteProps {
  component: ComponentType;
}

/**
 * 인증 검증이 필요한 Route 컴포넌트의 경우 ProtectedRoute 로 감싸서 사용한다
 */
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const location = useLocation();
  log("ProtectedRoute", "location.pathname", location.pathname);

  // 인증 확인해서 인증하지 않았을 경우 Unauthenticated 컴포넌트 return

  const Element = useMemo(
    () =>
      withAuthRequired(component, {
        Unauthenticated,
      }),
    [component],
  );

  return <Element />;
};
// withAuthRequired
