import { useCallback, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ProductGuide, ProductGuideDetail, ProductGuideNetwork, ProductGuideNetworkCatalog, VisitorGuide } from "src/api/guide/guide-types";
import { useToast } from "src/pages/hooks/toast";
import LinkifyText from "src/components/LinkifyText";

type Props = {
  productGuides?: ProductGuideDetail[];
};

type WifiGuideLocation = {
  id?: string; // 사용안내 ID
  location?: string; // 위치
  description?: string; // 설명
  wifiNetworks?: Array<WifiNetwork>; // 네트워크
};

type WifiNetwork = {
  id?: string; // 사용안내 네트워크 ID
  orderNums?: number; // 순서
  wifiName?: string; // Wifi
  wifiPassword?: string; // Wifi Password
  isVisiblePw?: boolean; // 비밀번호 보여주기 여부
};

/**
 * 방문자 초대 상세 웹뷰 화면 > 와이파이 이용안내
 */
const WifiGuide = ({ productGuides }: Props) => {
  // 와이파이 안내 위치 목록
  const [wifiGuideLocations, setWifiGuideLocations] = useState<Array<WifiGuideLocation>>([]);

  // 공간상품 사용안내 방문자용 정보로 와이파이 안내정보 바인딩
  useEffect(() => {
    const wifiGuide = (productGuides || []).find((guide) => guide.isDisplayed === true && guide.productGuideType === "PRODUCT_GUIDE_WIFI");
    if (wifiGuide) {
      // 방문자용 와이파이 (공개용 네트워크(방문자용))
      const visitorWifiList = (wifiGuide?.networkCatalogList || []).filter((network: ProductGuideNetworkCatalog) => network?.isOpened === true);

      const _wifiGuideLocations: Array<WifiGuideLocation> = visitorWifiList
        .map((catalog: ProductGuideNetworkCatalog) => {
          const _wifiNetworks: Array<WifiNetwork> = (catalog?.productGuideNetworkList || [])
            .map((productGuideNetwork: ProductGuideNetwork) => {
              const wifiNetwork: WifiNetwork = {
                id: productGuideNetwork.id,
                orderNums: productGuideNetwork.orderNums,
                wifiName: productGuideNetwork.wifiName,
                wifiPassword: productGuideNetwork.wifiPassword,
                isVisiblePw: false, // 기본 비밀번호 노출 여부 false 로 바인딩
              };
              return wifiNetwork;
            })
            .filter((productGuideNetwork: ProductGuideNetwork) => !!productGuideNetwork.wifiName && !!productGuideNetwork.wifiPassword) // 와이파이, 비밀번호 빈값인 것 제외
            .sort((a: WifiNetwork, b: WifiNetwork) => Number(a.orderNums || 0) - Number(b.orderNums || 0));
          const _wifiGuideLocation: WifiGuideLocation = {
            id: catalog.id,
            location: catalog.location,
            description: catalog.description,
            wifiNetworks: _wifiNetworks,
          };
          return _wifiGuideLocation;
        })
        .filter((productGuide: ProductGuide) => !!productGuide.location) // 위치 빈값인 것 제외
        .sort((a: WifiGuideLocation, b: WifiGuideLocation) => Number(a.id || 0) - Number(b.id || 0));
      setWifiGuideLocations(_wifiGuideLocations);
    }
  }, [productGuides]);

  // 방문자 화면 토스트
  const { openToast } = useToast();

  // 비밀번호 확인 클릭
  const clickViewWifiPassword = useCallback(
    (wifiGuideLocationId: string, wifiNetworkId: string) => {
      const newWifiGuideLocations: Array<WifiGuideLocation> = [];
      let changedIsVisiblePw = false;
      for (let i = 0; i < wifiGuideLocations.length; i++) {
        const currentLocation = wifiGuideLocations[i];
        if (currentLocation.id === wifiGuideLocationId) {
          const newWifiNetworks: Array<WifiNetwork> = [];
          for (let j = 0; j < (currentLocation?.wifiNetworks || []).length; j++) {
            const currentNetwork = (currentLocation?.wifiNetworks || [])[j];
            if (currentNetwork.id === wifiNetworkId) {
              changedIsVisiblePw = !currentNetwork.isVisiblePw; // 현재 네트워크 비밀번호 노출여부 변경
              newWifiNetworks.push({ ...currentNetwork, ...{ isVisiblePw: changedIsVisiblePw } });
            } else {
              newWifiNetworks.push(currentNetwork);
            }
          }
          const newWifiGuideLocation = { ...currentLocation, ...{ wifiNetworks: newWifiNetworks } };
          newWifiGuideLocations.push(newWifiGuideLocation);
        } else {
          newWifiGuideLocations.push(currentLocation);
        }
      }
      setWifiGuideLocations(newWifiGuideLocations);

      if (changedIsVisiblePw) {
        // 비밀번호 노출로 변경했을 경우
        openToast({ content: "비밀번호가 복사되었습니다" });
      }
    },
    [wifiGuideLocations, openToast],
  );

  return (
    <>
      <section className="base-accordion pb50">
        <div className="base-accordion__title-area">
          <span>와이파이 이용안내</span>
        </div>
        <div className="base-accordion__contents">
          {wifiGuideLocations.map((wifiGuideLocation: WifiGuideLocation, wifiGuideLocationIndex: number) => (
            <section className="location" key={wifiGuideLocationIndex.toString()}>
              <h2>- 위치: {wifiGuideLocation.location}</h2>
              {(wifiGuideLocation.wifiNetworks || []).map((wifiNetwork: WifiNetwork, wifiNetworkIndex: number) => (
                <div className="input-wrap" key={wifiNetworkIndex.toString()}>
                  <div className="like-disabled-input">
                    <div className="like-disabled-input__prefix">
                      <span className="font14 font-family-roboto font-weight-400">네트워크</span>
                    </div>
                    <div className="like-disabled-input__value">
                      <span>{wifiNetwork.wifiName}</span>
                    </div>
                  </div>
                  <div className="like-disabled-input">
                    <div className="like-disabled-input__prefix">
                      <span>비밀번호</span>
                    </div>
                    <div className="like-disabled-input__value">
                      {!wifiNetwork.isVisiblePw && <span className="password">●●●●●●●●●</span>}
                      {wifiNetwork.isVisiblePw && <span className="font16 font-family-roboto font-weight-700">{wifiNetwork.wifiPassword || ""}</span>}
                    </div>
                    <div className="like-disabled-input__suffix">
                      <CopyToClipboard text={wifiNetwork.wifiPassword || ""}>
                        <button
                          className={`visible-btn ${wifiNetwork.isVisiblePw ? "--active" : ""}`}
                          onClick={() => clickViewWifiPassword(wifiGuideLocation.id || "", wifiNetwork.id || "")}
                        ></button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              ))}
              <p className="desc">
                <LinkifyText text={wifiGuideLocation?.description} />
              </p>
            </section>
          ))}
          {wifiGuideLocations.length === 0 && (
            <p className="desc">
              <ul className="">
                <li>등록된 방문자용 와이파이 정보가 없습니다.</li>
                <li>담당자에게 직접 문의해 주세요.</li>
              </ul>
            </p>
          )}
        </div>
      </section>
    </>
  );
};
export default WifiGuide;
