import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { authRequired } from "src/pages/contract/apply/atom";
import { useMemberInfoState } from "src/recoil/member/hook";
import { routeList } from "./routes";
import { pagePath } from ".";

// prod 에서만 오픈하는 페이지를 관리하기 위한 환경변수
const isOnlyServePage = process.env.REACT_APP_ONLY_SERVE_PAGE;

const CONTRACT_APPLY_URL = pagePath.quotation;

const RoutesList = () => {
  const { member } = useMemberInfoState();

  // 공용공간 > 견적서 페이지
  const authRequiredCheck = useRecoilValue(authRequired);
  // (임시)
  // Taapspace 이용신청 페이지 (견적서 상세 페이지) 인증/무인증 페이지 조건 변경
  // 로그인 X - 무인증 페이지로 / 로그인 O - 인증 페이지 상태로
  const newRoutesList = useMemo(
    () =>
      routeList.map((route) => {
        if (route.path === CONTRACT_APPLY_URL && !member) {
          route = { ...route, isProtectRoute: authRequiredCheck };
        }
        return route;
      }),
    [member, authRequiredCheck],
  );
  return (
    <Routes>
      {
        // routeList
        newRoutesList
          .filter((route) => {
            if (route?.isDisabledProd) {
              // prod 에서는 노출시키지 않는 옵션 적용
              return isOnlyServePage ? false : true;
            } else {
              return true;
            }
          })
          .map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.isProtectRoute ? <ProtectedRoute component={route.component} /> : <route.component />}
            />
          ))
      }

      {/* page not found */}
      <Route path="*" element={<Navigate to="/court/error/page-not-found" replace></Navigate>} />
    </Routes>
  );
};

export default RoutesList;
