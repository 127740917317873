import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ConfirmModal } from "src/components";
import "react-datepicker/dist/react-datepicker.css";
import RangeDatepicker from "src/components/RangeDatepicker";
import { useApiOperation } from "src/api/hooks";
import { getContractAccessLogs } from "src/api/member/member-api";
import { ContractAccessLog, GetContractAccessLogParams } from "src/api/member/member-types";
import * as xlsx from "xlsx";
import { useNavigate, useParams } from "react-router-dom";
import DownloadExcelButton from "./DownloadExcelButton";
import Pagination from "./Pagination";
import { getAccessStatus, transformContractAccessLogData } from "./utils";
import AccessLogList from "./AccessLogList";
import Spinner from "./Spinner";
import DateChip from "src/components/DateChip";
import MetaTag from "src/components/layout/MetaTag";
import Header from "src/components/layout/Header";
import usePostMessage from "src/pages/hooks/post-message";

const EIGHT_DAYS_AGO = moment().subtract(8, "days").toDate();
const ONE_DAYS_AGO = moment().toDate();
const TODAY = moment().toDate();

const PAGE_SIZE = 20;

const DATE_CHIP = {
  month_1: {
    label: "1개월",
    selected: false,
    value: 1,
  },
  month_3: {
    label: "3개월",
    selected: false,
    value: 3,
  },
  month_6: {
    label: "6개월",
    selected: false,
    value: 6,
  },
};

function AccessLog() {
  const navigate = useNavigate();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [date, setDate] = useState<[Date | null, Date | null]>([EIGHT_DAYS_AGO, ONE_DAYS_AGO]);
  const [modal, setModal] = useState({
    contentEmptyAlert: {
      isOpen: false,
    },
    downloadAlert: {
      isOpen: false,
    },
    errorAlert: {
      isOpen: false,
    },
  });
  const [accessLogs, setAccessLogs] = useState<ContractAccessLog[]>([]);
  const { contractManageId, contractApplyNumber } = useParams();
  const [dateChip, setDateChip] = useState(DATE_CHIP);
  const [page, setPage] = useState<{
    currentPage: number;
    totalPage: number;
  }>();

  const formatedAccessLogs = useMemo(() => transformContractAccessLogData(accessLogs), [accessLogs]);

  const { executeAsync: getContractAccessLogsAsync } = useApiOperation(getContractAccessLogs);

  const { postMessageBase64FileDownload } = usePostMessage();

  const downloadExcel = ({
    data,
    fileName,
  }: {
    data: { accessDate: string; phoneNumber: string; eventName: string; deviceName: string }[];
    fileName: string;
  }) => {
    const workbook = xlsx.utils.book_new();

    const _data = data.map((item) => {
      return [item.accessDate, item.phoneNumber, item.deviceName, item.eventName];
    });

    const HEADER = [["일시", "이용자", "출입문 이름", "이벤트"]];

    xlsx.utils.sheet_add_aoa(workbook, HEADER);

    const worksheet = xlsx.utils.sheet_add_json(workbook, _data, {
      skipHeader: true,
      origin: "A2",
    });

    xlsx.utils.book_append_sheet(workbook, worksheet, "출입기록");

    // Taap 웹뷰
    if ((window as any).ReactNativeWebView) {
      const base64Text = xlsx.write(workbook, { type: "base64", bookType: "xlsx" });
      postMessageBase64FileDownload(base64Text, "출입기록.xlsx");
    }

    // TaapSpace 웹
    else {
      xlsx.writeFile(workbook, fileName);
    }
  };

  const isSelectDate = ([startDate, endDate]: [Date | null, Date | null]) => Boolean(startDate && endDate);

  const search = async (params: GetContractAccessLogParams) => {
    if (searchLoading) return;

    try {
      setSearchLoading(true);
      const result = await getContractAccessLogsAsync(params);

      setAccessLogs(result.data.data.content.map((item) => ({ ...item, phoneNumber: changePhoneNumber(String(item.phoneNumber || "")) })));
      setPage({
        currentPage: result.data.meta.pageMeta?.pageRequest.page || 0,
        totalPage: result.data.meta.pageMeta?.totalPages || 0,
      });

      return result;
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  };

  const openModal = (key: string) => {
    setModal({ ...modal, [key]: { isOpen: true } });
  };
  const closeModal = (key: string) => {
    setModal({ ...modal, [key]: { isOpen: false } });
  };
  const openContentEmptyAlert = () => openModal("contentEmptyAlert");
  const closeContentEmptyAlert = () => closeModal("contentEmptyAlert");
  const openDownloadAlert = () => openModal("downloadAlert");
  const closeDownloadAlert = () => closeModal("downloadAlert");
  const openErrorAlert = () => openModal("errorAlert");
  const closeErrorAlert = () => closeModal("errorAlert");

  const handleOnDateRangeChange = async (date: [Date | null, Date | null]) => {
    if (searchLoading) return;

    setDate(date);

    const [startDate, endDate] = date;

    if (isSelectDate(date) === false) {
      setAccessLogs([]);
      setPage({
        currentPage: 0,
        totalPage: 0,
      });
      return;
    }

    search({
      accessLogParams: { startDate: moment(startDate).startOf("day").format(), endDate: moment(endDate).endOf("day").format() },
      pagination: { page: 0, size: PAGE_SIZE, direction: ["DESC"], property: ["datetime"] },
      contractManageId: Number(contractManageId),
    });
  };

  // 핸드폰 포맷 변경
  const changePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber) {
      let cp = phoneNumber.replace("+82", "0");
      let second = cp.substring(3, 11);
      let middle = second.substring(0, 4);
      let last = second.substring(4, 8);
      return "010-" + middle + "-" + last;
    }
  };

  // 엑셀 다운로드 버튼
  const handleClickDownloadButton = async () => {
    try {
      setDownloadLoading(true);
      const [startDate, endDate] = date;

      if (isSelectDate(date) === false) {
        openErrorAlert();
        return;
      }

      // 엑셀 다운로드 목록 사이즈
      const DOWNLOAD_SIZE = 5000;

      const requestData = {
        accessLogParams: { startDate: moment(startDate).startOf("day").format(), endDate: moment(endDate).endOf("day").format() },
        contractManageId: Number(contractManageId),
        pagination: {
          property: ["datetime"],
          direction: ["DESC"],
          page: 0,
          size: DOWNLOAD_SIZE,
        },
      };

      const { data: firstData } = await getContractAccessLogsAsync(requestData);

      // 목록 전체 페이지
      const totalPages = firstData.meta.pageMeta?.totalPages;

      let excelList = [];

      if (firstData.data.content.length === 0) {
        openContentEmptyAlert();
      } else {
        excelList = [...firstData.data.content];

        if (totalPages && totalPages > 1) {
          const promise = [];
          for (let i = 1; i < totalPages; i++) {
            promise.push(getContractAccessLogsAsync({ ...requestData, pagination: { ...requestData.pagination, page: i } }));
          }
          const result = await Promise.all(promise);

          const final = result.map((res) => res.data.data.content);

          excelList = [...excelList, ...final].flat();
        }

        const requestExcelList = excelList.map((item) => ({ ...item, phoneNumber: changePhoneNumber(String(item.phoneNumber || "")) }));

        downloadExcel({
          data: transformContractAccessLogData(requestExcelList),
          fileName: `Taap-출입로그-${contractApplyNumber}-${moment(startDate).startOf("day").format("YYYY-MM-DD")}_${moment(endDate)
            .endOf("day")
            .format("YYYY-MM-DD")}.xlsx`,
        });

        if (!(window as any).ReactNativeWebView) {
          openDownloadAlert();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleOnPageChange = async (page: number) => {
    const [startDate, endDate] = date;

    if (isSelectDate(date) === false) return;
    if (searchLoading) return;

    search({
      accessLogParams: { startDate: moment(startDate).startOf("day").format(), endDate: moment(endDate).endOf("day").format() },
      pagination: { page: page - 1, size: PAGE_SIZE, direction: ["DESC"], property: ["datetime"] },
      contractManageId: Number(contractManageId),
    });
  };

  const handleClickDateChip = async (value: number) => {
    if (searchLoading) return;

    const _startDate = moment(ONE_DAYS_AGO).subtract(value, "months").toDate();
    const _endDate = ONE_DAYS_AGO;

    setDate([_startDate, _endDate]);
    setDateChip({ ...DATE_CHIP, [`month_${value}`]: { selected: true } });

    search({
      accessLogParams: { startDate: moment(_startDate).startOf("day").format(), endDate: moment(_endDate).endOf("day").format() },
      pagination: { page: 0, size: PAGE_SIZE, direction: ["DESC"], property: ["datetime"] },
      contractManageId: Number(contractManageId),
    });
  };

  useEffect(() => {
    (async () => {
      const [startDate, endDate] = date;

      if (isSelectDate(date) === false) return;

      search({
        accessLogParams: { startDate: moment(startDate).startOf("day").format(), endDate: moment(endDate).endOf("day").format() },
        pagination: { page: 0, size: PAGE_SIZE, direction: ["DESC"], property: ["datetime"] },
        contractManageId: Number(contractManageId),
      });
    })();
  }, []);

  return (
    <>
      <MetaTag title="이용자 목록" />
      <Header
        headerType="CLOSE"
        onClickCloseButton={() => {
          navigate(-1);
        }}
      />
      <div className="access-log-container">
        <div className="w-100 flex-center-between pl24 pr24 mb10">
          <h1>출입 기록</h1>

          <div className="file-upload-button-container">
            <DownloadExcelButton title="엑셀 받기" onClick={handleClickDownloadButton} />
          </div>
        </div>
        <div className="body">
          <div className="date-container">
            <div className="datepicker">
              <RangeDatepicker dateRange={date} onDateRangeChange={handleOnDateRangeChange} disabled={searchLoading} />
              <div className="datechip">
                {Object.keys(DATE_CHIP).map((key) => {
                  const dateChipKey = key as keyof typeof DATE_CHIP;
                  return (
                    <DateChip key={key} onClick={() => handleClickDateChip(DATE_CHIP[dateChipKey].value)} selected={dateChip[dateChipKey].selected}>
                      {DATE_CHIP[dateChipKey].label}
                    </DateChip>
                  );
                })}
              </div>
            </div>
          </div>
          {searchLoading ? (
            <div className="spinner-container">
              <Spinner />
            </div>
          ) : (
            <>
              <AccessLogList accessLogs={formatedAccessLogs} />
              <Pagination
                totalPage={page?.totalPage || 0}
                currentPage={page?.currentPage ? page.currentPage + 1 : 1}
                onPageChange={handleOnPageChange}
              />
            </>
          )}
        </div>
        {downloadLoading && (
          <ConfirmModal isOpen={true}>
            <section className="contents-indicator">
              <div className="--loading"></div>
            </section>
            <div className="font18 font-weight-600">
              <p>다운로드 중</p>
            </div>
          </ConfirmModal>
        )}
        <ConfirmModal isOpen={modal.contentEmptyAlert.isOpen} btnRightTitle="확인" onClick={closeContentEmptyAlert}>
          <div className="font18 font-weight-600">
            <p>해당 기간동안</p>
            <p>출입 기록이 존재하지 않습니다.</p>
          </div>
        </ConfirmModal>
        <ConfirmModal isOpen={modal.downloadAlert.isOpen} btnRightTitle="확인" onClick={closeDownloadAlert}>
          <div className="font18 font-weight-600">
            <p>다운로드가 완료되었습니다.</p>
          </div>
        </ConfirmModal>
        <ConfirmModal isOpen={modal.errorAlert.isOpen} btnRightTitle="확인" onClick={closeErrorAlert}>
          <div className="font18 font-weight-600">
            <p>기간을 선택해주세요.</p>
          </div>
        </ConfirmModal>
      </div>
    </>
  );
}
export default AccessLog;
