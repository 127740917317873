import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse, MediaServiceType } from "../public-types";
import { BuildingModel } from "./building-types";
import { MediaFile } from "../file/file-types";

/**
 * TPB04. (무인증) 건물 상세정보 목록
 */
export async function getBuildingsDetailsAsync(
  axios: AxiosInstance,
  params?: {
    buildingIds: Array<number | string>;
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<{ building: BuildingModel }> }>>> {
  if ((params?.buildingIds || []).length === 0) {
    throw Error("buildingIds is not found.");
  }
  return await axios.post(`/api/court/pr/public/buildings/details`, {
    building: {
      ids: (params?.buildingIds || []).map((id) => String(id)),
    },
  });
}

//**TPM01.  (무인증) 건물/상품 미디어파일 목록 */

export async function getMediasAsync(
  axios: AxiosInstance,
  params?: {
    serviceId: string;
    mediaServiceType: MediaServiceType;
  },
): Promise<AxiosResponse<ApiResponse<{ content: MediaFile[] }>>> {
  return await axios.get("/api/court/pr/public/mediaFile", {
    params,
  });
}
