import { useCallback, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ProductGuideDetail, ProductGuideNetwork, ProductGuideNetworkCatalog, UserGuide } from "src/api/guide/guide-types";
import { useToast } from "src/pages/hooks/toast";
import LinkifyText from "../../../../components/LinkifyText";
import _ from "lodash";

type Props = {
  userGuide?: UserGuide;
};

/**
 * 이용안내 > 상세화면이 네트워크인 경우
 */
const NetworkGuide = ({ userGuide }: Props) => {
  const [wifiGuide, setWifiGuide] = useState<any>({});
  const { openToast } = useToast();

  useEffect(() => {
    if (userGuide) {
      const addPwStateArray = userGuide?.wifiList?.map((wifi) => {
        return { ...wifi, isVisiblePw: false };
      });
      const findWifiGuide = { wifiList: addPwStateArray, location: userGuide?.location, description: userGuide?.description };
      setWifiGuide(findWifiGuide);
    }
  }, [userGuide, wifiGuide.userGuide?.description, wifiGuide.userGuide?.location]);

  const handleClickPassword = (wifiIndex: number) => {
    setWifiGuide((prev: any) => {
      let copyWifiGuide: any = _.cloneDeep(prev);
      copyWifiGuide.wifiList[wifiIndex].isVisiblePw = !prev.wifiList[wifiIndex].isVisiblePw;
      return copyWifiGuide;
    });
    openToast({ content: "비밀번호가 복사되었습니다" });
  };
  console.log("wifiGuide", wifiGuide);

  return (
    <div className="contents__item-content">
      <div className="wifi-info">
        <h2>방문자용</h2>
        <section className="wifi-info__wrap">
          <h3>- 위치: {wifiGuide.location}</h3>
          {(wifiGuide?.wifiList || []).map((wifi: any, wifiIndex: number) => (
            <div key={wifi.id + wifi.wifiName.toString()} className="input-wrap">
              <div className="like-disabled-input">
                <div className="like-disabled-input__prefix">
                  <span>네트워크</span>
                </div>
                <div className="like-disabled-input__value">
                  <span className="font14 font-family-roboto font-weight-400">{wifi.wifiName}</span>
                </div>
              </div>
              <div className="like-disabled-input">
                <div className="like-disabled-input__prefix">
                  <span>비밀번호</span>
                </div>
                <div className="like-disabled-input__value">
                  {!wifi.isVisiblePw && <span className="password">●●●●●●●●●</span>}
                  {wifi.isVisiblePw && <span className="font16 font-family-roboto font-weight-700">{wifi.wifiPassword || ""}</span>}
                </div>
                <div className="like-disabled-input__suffix">
                  <CopyToClipboard text={wifi.wifiPassword || ""}>
                    <button className={`visible-btn ${wifi.isVisiblePw ? "--active" : ""}`} onClick={() => handleClickPassword(wifiIndex)}></button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          ))}
          <p className="desc">
            <LinkifyText text={wifiGuide?.description} />
          </p>
        </section>
      </div>
    </div>
  );
};
export default NetworkGuide;
