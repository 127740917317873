import Header from "src/components/layout/Header";
type Props = {
  onClose: Function;
};
const TermsOfPolicy = ({ onClose }: Props) => {
  return (
    <>
      <Header
        headerType="CLOSE"
        title="개인정보 수집 및 이용 동의"
        onClickCloseButton={() => {
          onClose()!;
        }}
      />
      <section className="base-section-layout w-100">
        <div className="base-section-layout__container">
          <div className="base-section-layout__wrap">
            <div className="base-section-layout__title-wrap">
              <div className="flex-row">
                <span className="font16 font-weight-600">수집하는 개인정보 항목</span>
                <br />
                - 예약 정보 : 닉네임, 이메일 주소, 휴대폰 번호
                <br />
                - 결제 정보 : 신용카드 번호 및 은행 계좌 정보 일부 등<br />
                <br />
                <span className="font16 font-weight-600">개인정보 이용 목적</span>
                <br />
                - 공간 예약 및 이용 서비스 제공
                <br />
                <br />
                <span className="font16 font-weight-600">개인정보 보관 기간</span>
                <br />
                - 예약 완료 후 관련 법령에 따라 5년간 개인정보를 보관합니다.
                <br />
                <br />
                <span className="font16 font-weight-600">개인정보 보유 및 이용 기간</span>
                <br />
                - 서비스 제공 기간 동안 보유
                <br />
                - 단, 관련 법령에 따라 보관이 필요한 경우나 사전 동의가 있는 경우, 해당 기간 동안 보관됩니다.
                <br />
                <br />
                <span className="font16 font-weight-600">개인정보 수집 및 이용에 대한 동의 거부에 관한 안내</span>
                <br />
                - 개인정보 주체는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다.
                <br />
                - 다만, 공간 예약을 위해 필요한 최소한의 개인정보 수집을 거부할 경우, 예약이 어려울 수 있습니다.
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfPolicy;
