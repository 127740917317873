import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import { OrganizationResponseData } from "./organization-types";

/**
 * TPB10. (무인증) 회사 정보 조회
 */
export async function getPublicOrganizationAsync(
  axios: AxiosInstance,
  params?: {
    organizationId: number;
  },
): Promise<AxiosResponse<ApiResponse<OrganizationResponseData>>> {
  if (!params?.organizationId) {
    throw Error("organizationId is not found.");
  }
  return await axios.get(`/api/court/mb/public/organizations/${params.organizationId}`);
}
