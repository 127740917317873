import moment from "moment";
import { useEffect, useState } from "react";
import { CommonFacilityModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { PriceModel, PriceParams, ProductModel } from "src/api/product/product-types";
import { getClosedPeriodAsync, getPriceAsync, getReservedTimesAsync, getReserveDurationAsyc } from "src/api/reservation/reservation-api";
import { AcknowledgeReservation, AvailableReservations, ClosedPeriodModel, ScheduleData } from "src/api/reservation/reservation-type";
import { YmdFormat } from "src/utils/common-util";

const compareDateTime = (date: string) => {
  return Math.floor(new Date(date).getTime() / 1000);
};

type Props = {
  product?: ProductModel;
  facility?: CommonFacilityModel;
};

//Api 호출영역 커스텀 훅
const useReservationApi = () => {
  //Api 호출
  const { executeAsync: getPrices } = useApiOperation(getPriceAsync, { noAuthenticationRequired: true }); // 요금조회 api
  const { executeAsync: getClosedPeriod } = useApiOperation(getClosedPeriodAsync, { noAuthenticationRequired: true });
  const { executeAsync: getReservedTimes } = useApiOperation(getReservedTimesAsync, { noAuthenticationRequired: true }); // 스케쥴 예약 점유확인 api
  const { executeAsync: getReserveDuration } = useApiOperation(getReserveDurationAsyc, { noAuthenticationRequired: true }); // 스케쥴 예약 가능한 구간 api

  // 요금 가져오기
  const fetchPrices = async (product: ProductModel, facility: CommonFacilityModel, selectedDate: string) => {
    // setCalendarDate(selectedDate);

    const priceData: PriceParams = {
      providerId: String(product?.providerId),
      serviceId: String(product?.id),
      serviceType: "SERVICE_PRODUCT",
      startDate: moment(selectedDate).startOf("day").format(YmdFormat.WITH_TIME_ZONE),
      endDate: moment(selectedDate).add(1, "days").startOf("day").format(YmdFormat.WITH_TIME_ZONE),
      buildingCommonFacilityId: String(facility?.id),
    };

    try {
      const { data } = await getPrices(priceData);
      return data.data.content;
    } catch (error) {
      console.error("Error fetching prices:", error);
      return [];
    }
  };

  // 점유 목록
  const fetchReservedTime = async (facility: CommonFacilityModel, selectedDate: string) => {
    try {
      const { data } = await getReservedTimes({
        facilityId: facility?.id,
        start: moment(selectedDate).startOf("day").format(YmdFormat.WITH_TIME_ZONE),
        end: moment(selectedDate).add(1, "days").startOf("day").format(YmdFormat.WITH_TIME_ZONE),
      });
      return data.data.content;
    } catch (error) {
      console.error("Error fetching reserved:", error);
      return [];
    }
  };

  // 선택 날짜의 공간 휴무시간
  const fetchClosedPeriod = async (product: ProductModel, facility: CommonFacilityModel, selectedDate: string) => {
    if (!product) return;
    try {
      const { data } = await getClosedPeriod({
        serviceId: String(product.id),
        serviceType: "SERVICE_PRODUCT",
        date: selectedDate,
        commonFacilityType: facility?.commonFacilityType || "COMMONFACILITY_UNRECOGNIZED",
        buildingCommonFacilityId: String(facility?.id),
      });
      return data.data.content;
    } catch (error) {
      console.error("Error fetching ClosedPeriod:", error);
      return [];
    }
  };

  // 점유 목록
  const fetchReserveDuration = async (product: ProductModel, facility: CommonFacilityModel) => {
    const { data, status } = await getReserveDuration({
      serviceId: String(product?.id),
      serviceType: "SERVICE_PRODUCT",
      commonFacilityType: facility?.commonFacilityType || "COMMONFACILITY_UNRECOGNIZED",
      buildingCommonFacilityId: String(facility?.id),
    });

    if (status >= 200 && status <= 299) {
      return data.data.content[0];
    }
  };

  return { fetchPrices, fetchReservedTime, fetchClosedPeriod, fetchReserveDuration };
};

// 화면에 노출하는 스케줄 슬롯 영역 커스텀훅
const useSchedule = ({ product, facility }: Props) => {
  //state
  const [prices, setPrices] = useState<PriceModel[]>([]); // 요금 목록
  const [reserved, setReserved] = useState<AcknowledgeReservation[]>([]); // 점유 예약내역
  const [reserveDuration, setReserveDuration] = useState<AvailableReservations>(); //예약 가능 구간 > 현재 시간으로부터 ~부터 클릭 가능 && 최대 몇시간 선태 가능
  const [closedPeriod, setClosedPeriod] = useState<ClosedPeriodModel[]>([]); //휴무시간 목록
  const [calendardate, setCalendarDate] = useState<string>(moment().format(YmdFormat.WITH_TIME_ZONE)); //캘린더에 선택된 날짜 > default:오늘날짜
  const [scheduleSlots, setScheduleSlots] = useState<ScheduleData[]>([]); // 화면에 보여지는 요금 slots 데이터

  const { fetchPrices, fetchReservedTime, fetchClosedPeriod, fetchReserveDuration } = useReservationApi();

  useEffect(() => {
    if (product?.id && facility) {
      const fetchData = async () => {
        const [priceData, reservedData, closedPeriodData, reserveDurationData] = await Promise.all([
          fetchPrices(product, facility, calendardate),
          fetchReservedTime(facility, calendardate),
          fetchClosedPeriod(product, facility, calendardate),
          fetchReserveDuration(product, facility),
        ]);
        setPrices(priceData);
        setReserved(reservedData);
        setClosedPeriod(closedPeriodData || []);
        setReserveDuration(reserveDurationData);
      };
      fetchData();
    }
  }, [product?.id, calendardate]);

  //현재 시간 확인
  //예약구간 최소시간 확인
  //slots > start 또는 end time 비교해서 최소구간 전에 비활성화

  const generateScheduleSlots = () => {
    const schedule = prices.map((item: ScheduleData) => {
      const startDate = compareDateTime(item.startDate);
      const endDate = compareDateTime(item.endDate);

      // 점유 목록 > isClosed 가 true 인 시간 비교 후 바인딩
      reserved.forEach((reservationItem) => {
        const reserveStart = compareDateTime(reservationItem.start);
        const reserveEnd = compareDateTime(reservationItem.end);

        if (startDate >= reserveStart && endDate <= reserveEnd) {
          item = { ...item, isClosed: true };
        }
      });

      // 휴무시간 목록 > isClosed 가 true 인 시간 비교 후 바인딩
      closedPeriod.forEach((closeItem) => {
        const closedStart = compareDateTime(closeItem.startDate);
        const closedEnd = compareDateTime(closeItem.endDate);
        if (startDate >= closedStart && endDate <= closedEnd && closeItem.isClosed) {
          item = { ...item, isClosed: true };
        }
      });

      //1. 예약구간 등록 내용이 없으면 > null 이면 [점유/요금/휴무기간] 배열 노출
      if (!reserveDuration?.id) {
        return item;
      }
      //예약구간 데이터가 있으면
      else {
        //예약 구간 최소/최대시간 데이터가 있지만

        // 최소/최대 시간 적용
        const availableStartTime = reserveDuration.availableReservationTimeStart;
        const availableEndTime = reserveDuration.availableReservationTimeEnd;

        const currentTime = moment();
        //현재시간 + 최소시간
        const minTime = moment(currentTime.clone().add(availableStartTime, "minutes"));

        //현재시간 + 최대시간
        const maxTime = moment(currentTime.clone().add(availableEndTime, "minutes"));
        //maxTime 2024-10-25 11:46
        const isBeforeMinTime = moment(item.startDate).isSameOrBefore(minTime);
        const isAfterMaxTime = maxTime.isSameOrBefore(item.endDate);

        let isBlockSlot = false;

        if ((Number(availableStartTime) < 0 && Number(availableEndTime) < 0) || !reserveDuration.isLimitedAvailableReservationTime) {
          return item;
        }

        //case 최소시간 > -1
        if (Number(availableStartTime) > 0) {
          isBlockSlot = isBeforeMinTime;
        }

        if (Number(availableEndTime) > 0) {
          if (isAfterMaxTime) isBlockSlot = isAfterMaxTime;
        }

        // 이전인 시간들은 isClosed:true 로 비활성처리
        return { ...item, isClosed: isBlockSlot ? isBlockSlot : item.isClosed };
      }
    });
    setScheduleSlots(schedule);
  };
  useEffect(() => {
    generateScheduleSlots();
  }, [prices, reserved, closedPeriod, reserveDuration, calendardate]);

  return { scheduleSlots, calendardate, setCalendarDate };
};

export default useSchedule;
