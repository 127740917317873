interface Props extends React.HTMLAttributes<HTMLDivElement> {
  selected?: boolean;
}

function DateChip({ selected, ...props }: Props) {
  return (
    <div {...props} className={`date-chip ${selected && "selected"}`}>
      {props.children}
    </div>
  );
}
export default DateChip;
