import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Swiper from "react-id-swiper";
import { ClosedDay } from "src/api/reservation/reservation-type";
import { YmdFormat } from "src/utils/common-util";
import SwiperCore, { A11y, Navigation } from "swiper";
import SlideItem from "./components/SlideItem";
SwiperCore.use([Navigation, A11y]);

export type Dates = {
  date: string;
};

type Props = {
  onDateClick: (date: string) => void;
  fetchClosedList: (startDate: string, endDate: string) => void;
  closedDays: ClosedDay[];
};
const today = moment();
//
// moment.js를 사용하여 오늘 이후의 각 월을 생성
const yearOfMonths = Array.from({ length: 12 }, (_, index) => {
  return moment(today).add(index, "month");
});
//

const weeks = ["일", "월", "화", "수", "목", "금", "토"];

const SwiperCalendar = ({ fetchClosedList, onDateClick, closedDays }: Props) => {
  const [selectedDay, setSelectedDay] = useState(moment(today).format("YYYY/MM/DD"));
  const swiperRef = useRef<any>(null);
  const [page, setPage] = useState(0);

  //공용공간 > 캘린더 날짜 영역을 그려주는 함수
  const createCalendarDate = (passDate: moment.Moment): { date: string }[] => {
    const _days = [];
    for (let i = 0; i < 7 * 5; i++) {
      _days.push({
        date: passDate.format("YYYY/MM/DD"),
      });
      passDate.add(1, "day");
    }
    return _days;
  };

  useEffect(() => {
    // 현재의 "month (월)" 을 찾기
    const findDate = moment(yearOfMonths[page]).startOf("months").startOf("weeks");

    // 월에 맞는 날짜들 그리기 > 1일 ~31일
    const calendarDate = createCalendarDate(findDate);

    fetchClosedList(calendarDate[0].date, calendarDate[calendarDate.length - 1].date);
  }, [page]);

  const handleClickDay = (e: React.MouseEvent<HTMLButtonElement>, day: Dates) => {
    e.preventDefault();
    setSelectedDay(day.date);
    onDateClick(moment(day.date).format(YmdFormat.WITH_TIME_ZONE));
  };

  // 캘린더 날짜 화살표
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className="swiper-calendar">
      <div className="calendar-container">
        <div className="flex-center-center">
          <div className="py2 px4">
            <button className={`w-100 custom-calendar-button${page > 0 ? "__prev" : "__prev-gray"}`} onClick={goPrev}></button>
          </div>
          <p className="swiper-calendar__date minmax150 px30">{yearOfMonths[page].format("YYYY년 MM월")}</p>
          <div>
            <button className="custom-calendar-button__next" onClick={goNext}></button>
          </div>
        </div>
        <div className="week-header">
          {weeks.map((week, i) => (
            <div key={i}>{week}</div>
          ))}
        </div>
        {yearOfMonths && yearOfMonths.length > 0 && (
          <Swiper
            ref={swiperRef}
            threshold={20}
            on={{
              slideChange: (e) => {
                setPage(e.activeIndex);
              },
            }}
            // initialSlide={currentSlideIndex}
            initialSlide={0} // 새로운 "월"이 시작되면 그 "월" 의 페이지의 index가 0이 되는것 같음
            preventClicks={true}
            preventClicksPropagation={true}
            preventInteractionOnTransition={true}
            resistance={false}
            // threshold={250}
            a11y={{ enabled: false }}
          >
            {yearOfMonths.map((month, idx) => {
              return (
                <div key={idx}>
                  <SlideItem
                    month={month}
                    selectedDay={selectedDay}
                    handleClickDay={handleClickDay}
                    closedDays={closedDays}
                    createCalendarDate={createCalendarDate}
                  />
                </div>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
};
export default SwiperCalendar;
