import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BaseButton, BaseModal, BaseTextInput, ConfirmModal } from "src/components";
import Header from "src/components/layout/Header";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import { Modal } from "../visitor-types";
import { useApiOperation } from "src/api/hooks";
import { useToast } from "src/pages/hooks/toast";
import { getExternalVisitorAsync, updateExternalVisitorAsync } from "src/api/visitor/visitor-api";
import { Visitor } from "src/api/visitor/visitor-types";

const ChangeCarNumber = () => {
  const { goBack } = useNavigateGoBack();
  const { visitorKey } = useParams();
  const [visitor, setVisitor] = useState<Visitor>();
  const [visitorCarNumber, setVisitorCarNumber] = useState<string>("");
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const { executeAsync: getVisitor } = useApiOperation(getExternalVisitorAsync, { noAuthenticationRequired: true, noHandleError: true });
  const { executeAsync: updateVisitor, loading: updateVisitorLoading } = useApiOperation(updateExternalVisitorAsync, {
    noAuthenticationRequired: true,
  });

  const saveVisitorCarNumber = useCallback(async () => {
    try {
      if (!visitorKey) throw Error("visitorKey is not found");
      const { data } = await updateVisitor({ visitorKey, visitorCarNumber });
      if (data?.data?.contractVisitorId) {
        return data?.data?.contractVisitorId;
      }
    } catch (error) {
      console.error(error);
    }
  }, [visitorKey, updateVisitor, visitorCarNumber]);

  // 방문초대자 초대내역 정보 조회
  const fetchVisitor = useCallback(
    async (visitorKey: string) => {
      try {
        const { data } = await getVisitor({ visitorKey });
        const visitor: Visitor = data?.data;
        setVisitor(visitor);
        if (visitor.visitorCarNumber) setVisitorCarNumber(visitor.visitorCarNumber);
      } catch (error) {}
    },
    [getVisitor],
  );
  useEffect(() => {
    if (visitorKey) {
      fetchVisitor(visitorKey);
    }
  }, []);
  return (
    <>
      <Header headerType="CLOSE" onClickCloseButton={() => goBack()} />
      {visitorKey ? (
        <article className="change-car-number">
          <div className="info-area">
            <h2>{visitor?.visitorCarNumber ? "차량번호를 수정해 주세요" : "차량번호를 입력해 주세요"}</h2>
            <ul className="base-list mt10">
              <li>차량번호를 입력하시면 무료주차 혜택이 적용됩니다. 번호 입력이 잘못된 경우 혜택 적용이 불가하오니, 입력에 유의하시기 바랍니다.</li>
              <li>무료 주차 시간은 초대받은 공간의 운영 정책에 따라 상이할 수 있습니다.</li>
              <li>상세한 무료 주차 혜택은 관리자에게 확인하시기 바랍니다.</li>
            </ul>
          </div>
          <div className="mt30 w-100">
            <BaseTextInput
              placeholder="차량번호를 입력하세요"
              value={visitorCarNumber}
              onChange={(value: string | undefined) => setVisitorCarNumber(value!)}
            />
          </div>
          <BaseButton
            className="mt80 base-btn"
            disabled={!visitorCarNumber}
            title="완료"
            onClick={async () => {
              if (visitor?.visitorCarNumber) {
                setConfirmModal({ isOpen: true, message: "차량번호를 수정하시겠습니까?" });
              } else {
                const contractVisitorId = await saveVisitorCarNumber();
                if (contractVisitorId) {
                  setConfirmModal({ isOpen: true, type: "FIRST", message: "차량번호를 등록했습니다" });
                }
              }
            }}
          />
          {confirmModal.isOpen && (
            <ConfirmModal
              isOpen={true}
              onClose={() => setConfirmModal({ isOpen: false })}
              onClick={async () => {
                if (confirmModal.type === "FIRST") {
                  goBack();
                } else {
                  const contractVisitorId = await saveVisitorCarNumber();
                  setConfirmModal({ isOpen: false });
                  if (contractVisitorId) {
                    goBack();
                  }
                }
              }}
              btnLeftTitle={confirmModal.type === "FIRST" ? "" : "취소"}
              btnRightTitle="확인"
            >
              <div>
                <b className="font18">{confirmModal.message}</b>
              </div>
            </ConfirmModal>
          )}
        </article>
      ) : (
        <article>없어</article>
      )}
    </>
  );
};
export default ChangeCarNumber;
