import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React from "react";
import ReactDOM from "react-dom/client";
import "src/assets/styles/main.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/swiper.scss"; // core Swiper
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./utils/ensure-basename";
import * as Sentry from "@sentry/react";

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

//아래 링크 참고
//https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/682525398/-+Sentry4+on-prem+AWS?focusedCommentId=684949506
//https://velog.io/@haerim95/Sentry-%EB%A1%9C-%EC%97%90%EB%9F%AC-%EC%B6%94%EC%A0%81-%EA%B0%84%ED%8E%B8%ED%95%98%EA%B2%8C-%ED%95%98%EA%B8%B0-feat.-vite
//https://docs.sentry.io/platforms/javascript/guides/react/tracing/trace-propagation/
if (window.location.hostname !== "localhost") {
  Sentry.init({
    //이벤트를 전송하기 위한 식별 키
    dsn: process.env.REACT_APP_SENTRY_DSN || "",
    //애플리케이션 환경 (dev, production 등)
    environment: process.env.REACT_APP_SENTRY_ENV,

    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    //Sentry에 보내는 트랜잭션의 샘플 비율 (0.0 ~ 1.0)
    //너무 많은 이벤트를 기록하지 않도록 부하를 조절
    tracesSampleRate: 0.6,

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // 설정된 URL 패턴에 대해서만 추적 정보를 전파
    tracePropagationTargets: ["localhost", /^\/api\//, process.env.REACT_APP_PPOINT_BASEURL || ""],

    // Session Replay
    // Sentry SDK에서 세션 리플레이 기능의 샘플링 비율을 제어
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (window.location.hostname !== "localhost") {
  // localhost 가 아닐 경우 firebase google anaytics 적용
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
}

// if (process.env.NODE_ENV === "development") {
//   const { worker } = require("./mocks/browser");

//   worker.start();
// }

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
