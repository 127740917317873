import { CourtAuthError, CourtAuthErrorCode, TokenResponse } from "../contexts/CourtAuthContext";

export default async function refreshTokenAsync(tokenEndpoint: string, clientId: string, token: string) {
  const response = await fetch(tokenEndpoint, {
    method: "post",
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: new URLSearchParams({ grant_type: "refresh_token", client_id: clientId, refresh_token: token }),
  });
  if (response.ok) {
    return await parseTokenResponseAsync(response);
  } else {
    throw await parseCourtAuthErrorAsync(response);
  }
}

async function parseTokenResponseAsync(response: Response) {
  const { token_type, expires_in, access_token, id_token, refresh_token, scope } = await response.json();
  const tokenReponse = {
    tokenType: token_type,
    accessToken: access_token,
    expiresIn: expires_in,
    idToken: id_token,
    refreshToken: refresh_token,
    scope,
  } as TokenResponse;
  return tokenReponse;
}

async function parseCourtAuthErrorAsync(response: Response) {
  const { error, error_description } = await response.json();
  const code = `oauth2::${error}` as CourtAuthErrorCode;
  return new CourtAuthError(code, error_description);
}
