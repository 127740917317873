import { Toast, toastState } from "src/recoil/toast/atom";
import { useRecoilState } from "recoil";

const getRandomID = () => String(new Date().getTime());

/**
 * 토스트 hook
 */
export function useToast() {
  const [toasts, setToasts] = useRecoilState(toastState);

  const removeToast = (toastID: Toast["id"]) => setToasts((prev) => prev.filter((toast) => toast.id !== toastID));

  const openToast = (toast: Toast) => {
    setToasts((prev) => [...prev, { ...toast, id: getRandomID() }]);
    setTimeout(() => removeToast(toast.id), 600 + (toast.duration ?? 1000));
  };

  return { toasts, openToast };
}
