import { useEffect, useState } from "react";

type Props = {
  mm: number;
  ss: number;
  closeTimer: () => void;
};

const Timer = ({ mm, ss, closeTimer }: Props) => {
  const [minutes, setMinutes] = useState<number>(mm);
  const [seconds, setSeconds] = useState<number>(ss);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          closeTimer();
          clearInterval(countdown);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  return (
    <div className="validation-wrap__left">
      <p className={"validation-text"}>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </p>
    </div>
  );
};

export default Timer;
