import Header from "src/components/layout/Header";
type Props = {
  guideInfo: any;
  onClose: Function;
};
const ServiceTypeGuidelines = ({ guideInfo, onClose }: Props) => {
  
  return (
    <>
    {guideInfo && guideInfo.initValue !==undefined && guideInfo.initValue !=='' &&
      <>
      <Header
        headerType="CLOSE"
        title="유의사항"
        onClickCloseButton={() => {
          onClose()!;
        }}
      />
      <section className="base-section-layout w-100">
        <div className="base-section-layout__container">
          <div className="base-section-layout__wrap">
            <div className="base-section-layout__title-wrap">
              <div>
              {guideInfo.initValue.split("\n").map((line: any, idx: number) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })}
            </div>
          </div>
          </div>
        </div>
      </section>
      </>
    }
    </>
  );
};

export default ServiceTypeGuidelines;
