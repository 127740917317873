import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface Props {
  isOpen: boolean;
  title?: string;
  indexTitle?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  type?: string;
  className?: string;
}
/*
  BaseBottomSheet
  하단에 붙어 위로 올라오는 모달
*/
const BaseBottomSheet = (props: Props) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef<HTMLElement | null>(null);
  const dimRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current = document.getElementById("modal");
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const onClickDim = (e: React.MouseEvent<HTMLElement>) => {
    const dim = dimRef.current;
    if (e?.target === dim) {
      props.onClose?.();
    }
  };

  if (!props.isOpen) return null;

  return mounted
    ? createPortal(
        <>
          <div className="dim bottom" onClick={(e) => onClickDim(e)} ref={dimRef}>
            <div className={`base-bottom-sheet ${props.className ? props.className : ""}`}>
              {props.title && (
                <div className={`base-bottom-sheet__title ${props.indexTitle ? "with-index-title" : ""}`}>
                  {props.indexTitle && <p>{props.indexTitle}</p>}
                  <h2>{props.title}</h2>
                </div>
              )}
              <div className="base-bottom-sheet__contents">{props.children && <>{props.children}</>}</div>
            </div>
          </div>
        </>,
        ref.current!,
      )
    : null;
};

export default BaseBottomSheet;
