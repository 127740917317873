import PKCE from "js-pkce";
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import log from "src/utils/log";
import CourtAuthContext, {
  AuthorizeOptions,
  CourtAuthError,
  CourtAuthIdTokenPayload,
  CourtAuthOptions,
  CourtAuthReturnTo,
  CourtAuthReturnToState,
  CourtAuthState,
  CourtSecurity,
  CourtUser,
  initializeCourtAuthValue,
  TokenResponse,
} from "../contexts/CourtAuthContext";
import useFetchTokenAsync from "../hooks/client/fetch-token";
import useLoadTokenAsync from "../hooks/client/load-token";
import courtAuthStateRepository from "../utils/court-auth-state-repository";
import { sessionStorage } from "../utils/persistent-storage";
import verifyJwt from "../utils/verify-jwt";
import { useLocation, useNavigate } from "react-router-dom";

type Props = CourtAuthOptions & {};

/**
 * @param redirectUri 리다이렉트 URI
 * @returns 현재 페이지의 브라우저 URL 이 authorization_code flow 중 리다이렉트 되었는지 확인
 */
function isAuthorizationCodeRedirect(redirectUri: string) {
  log("isAuthorizationCodeRedirect redirectUri", redirectUri);

  const { origin, pathname, searchParams } = new URL(window.location.href);
  if (`${origin}${pathname}` === redirectUri) {
    const result = (searchParams.has("code") || searchParams.has("error")) && searchParams.has("state");
    log("isAuthorizationCodeRedirect result", result);
    return result;
  } else {
    log("isAuthorizationCodeRedirect false");
    return false;
  }
}

/**
 * @param error
 * @returns 보안 객체
 */
function parseError(error: any): CourtSecurity {
  if ("code" in error) {
    const authError = error as CourtAuthError;
    if (authError.code === "storage::no_token_response") {
      return { status: "unauthenticated" };
    }
  }
  return { status: "error", error };
}

// 기본 에러 핸들링
const defaultOnError = (error: any) => {
  throw error;
};

// 인가 요청 상태 값
// const state = generateState();

export default function CourtAuthProvider({ children, ...otherProps }: PropsWithChildren<Props>) {
  const { issuer, clientId, endpoints, redirectUri, scope, onRedirect, pending = null, onError = defaultOnError } = otherProps;
  const pkce = useMemo(
    () =>
      new PKCE({
        client_id: clientId,
        redirect_uri: redirectUri,
        authorization_endpoint: endpoints.authorize,
        token_endpoint: endpoints.token,
        requested_scopes: scope,
        storage: sessionStorage,
      }),
    [clientId, endpoints, redirectUri, scope],
  );

  const navigate = useNavigate();

  // 초기화 여부
  const initialized = useRef(false);
  // 보안 객체
  const [security, setSecurity] = useState(initializeCourtAuthValue.security);
  // 인증 flow 중 토큰 조회
  const fetchTokenAsync = useFetchTokenAsync(pkce);
  // 스토리지에서 토큰 조회 및 갱신 토큰 조회
  const loadTokenAsync = useLoadTokenAsync(issuer, clientId, endpoints.token);

  // 인가 요청(인증 서버 로그인 페이지로 이동)
  const authorize = useCallback(
    async (params: AuthorizeOptions = {}) => {
      console.log("인가 요청(인증 서버 로그인 페이지로 이동)");
      const authorizeUrl = await pkce.authorizeUrl({ ...params });
      if (params.replace) {
        window.location.replace(authorizeUrl);
      } else {
        window.location.href = authorizeUrl;
      }
    },
    [pkce],
  );

  // 인증 성공 핸들러
  const handleSuccess = useCallback(
    (token: TokenResponse) => {
      if (token.refreshToken === security.refreshToken) {
        return;
      }
      const idToken = verifyJwt<CourtAuthIdTokenPayload>(token.idToken, { issuer });
      const { payload } = idToken;
      const user = {
        memberNo: payload.sub,
        email: { value: payload.email, isVerified: payload.email_verified },
        phoneNumber: { value: payload.phone_number, isVerified: payload.phone_number_verified },
        roles: payload.roles,
        states: payload.states,
      } as CourtUser;
      setSecurity({ status: "authenticated", user, idToken, refreshToken: token.refreshToken });
    },
    [issuer, security],
  );

  // 인증 에러 핸들러
  const handleError = useCallback(
    (error: any) => {
      log("CourtAuthProvider", "handleError", error);
      const parsed = parseError(error);
      setSecurity(parsed);
      onError(error);
    },
    [onError],
  );

  // 인증 갱신 처리
  const refresh = useCallback(
    (params: { token?: TokenResponse; error?: any }) => {
      if (params.error) {
        handleError(params.error);
        return;
      }
      if (params.token) {
        handleSuccess(params.token);
        return;
      }
      handleError(new Error("illegal state error"));
    },
    [handleError, handleSuccess],
  );

  // 인증 취소 처리
  const revoke = useCallback(() => {
    setSecurity({ status: "none" });
    initialized.current = false;
  }, []);

  // 인증 문맥
  const contextValue = useMemo(
    () => ({ authorize, refresh, revoke, options: { ...otherProps }, security }),
    [authorize, refresh, revoke, otherProps, security],
  );

  /**
   * 인증 문맥 생성
   */
  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    setSecurity({ status: "pending" });

    const { searchParams } = new URL(window.location.href);
    const idToken = searchParams.get("idToken");
    if (idToken) {
      // query parameter 에 Taap 에서 웹뷰로 전달한 idToken 이 있는 경우, 인가 요청해서 로그인 처리

      log("CourtAuthProvider", "idToken :: ", idToken);

      // log("CourtAuthProvider security :: ", security);
      // const currentMemberNo = security?.idToken?.payload.sub;
      // const memberNo = jwtDecode<CourtAuthAccessTokenPayload>(idToken, { header: false }).sub;
      // log("CourtAuthProvider currentMemberNo :: ", currentMemberNo);
      // log("CourtAuthProvider memberNo :: ", memberNo);

      // 인가 요청
      authorize({ replace: false, prompt: "none", id_token_hint: idToken })
        .then(() => {
          log("CourtAuthProvider", "idToken 으로 authorize 성공");

          // 인증(사용자 로그인) 후 리다이렉트 되기 위한 state 저장
          const orgState = courtAuthStateRepository.load() ?? ({} as CourtAuthState);
          const orgHistory = orgState.returnTo?.history ?? [];
          
          // idToken 제거
          searchParams.delete('idToken');
          const queryString = searchParams.toString();
          let path = window.location.pathname;
          if (queryString) {
            path += '?' + queryString;
          }
          log('replaced path', path);
          
          const latest = {
            uri: path,
            time: Math.floor(new Date().getTime() / 1000),
          } as CourtAuthReturnTo;
          const returnToState = { latest, history: [latest, ...orgHistory] } as CourtAuthReturnToState;
          const newState = { returnTo: returnToState };
          log("CourtAuthProvider", "newState", JSON.stringify(newState));

          courtAuthStateRepository.save(newState);
        })
        .catch(handleError);
    } else if (isAuthorizationCodeRedirect(redirectUri)) {
      fetchTokenAsync()
        .then((token) => {
          handleSuccess(token);
          onRedirect(courtAuthStateRepository.load() ?? {});
        })
        .catch(handleError);
    } else {
      loadTokenAsync().then(handleSuccess).catch(handleError);
    }
  }, [redirectUri, fetchTokenAsync, loadTokenAsync, onRedirect, handleSuccess, handleError, authorize, navigate]);

  /**
   * 보안 정보 로깅
   */
  useEffect(() => {
    log("CourtAuthProvider", security);
  }, [security]);

  if (!initialized.current || security.status === "pending") {
    return <>{pending}</>;
  }

  return <CourtAuthContext.Provider value={contextValue}>{children}</CourtAuthContext.Provider>;
}
