import { Visitor } from "src/api/visitor/visitor-types";

type Props = {
  visitor?: Visitor;
};

/**
 * 방문자 초대 상세 웹뷰 화면 > 셀프 등록 방문자인 경우만 해당 > 이용안내
 */
const UseGuide = ({ visitor }: Props) => {
  return (
    <>
      <section className={"base-accordion no-icon pb40"}>
        <div className="base-accordion__title-area">
          <span>이용안내</span>
        </div>
        <div className="base-accordion__contents">
          <div className="pt10">
            <ul>
              <li>기본 출입 유효 시간은 1시간입니다.</li>
              <li>출입 시간 연장이 필요한 경우 셀프 방문객 정보 등록을 추가로 진행해주세요.</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
export default UseGuide;
