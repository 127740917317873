import { uniqueId } from "lodash";
import { useSetRecoilState } from "recoil";
import { ErrorModalState, errorModalState } from "./atom";

export type OpenErrorModal = {
  errorCode?: string;
  errorMessage?: string;
  errorData?: any;
  statusCode?: number;
};

export const useErrorModal = () => {
  const setErrorModals = useSetRecoilState<ErrorModalState[]>(errorModalState);

  // 에러모달 열기
  const openErrorModal = (openErrorModal: OpenErrorModal) => {
    const newErrorModal = {
      id: uniqueId("error_modal_unique_id_"),
      isOpen: true,
      errorCode: openErrorModal.errorCode,
      errorMessage: openErrorModal.errorMessage,
      errorData: openErrorModal.errorData,
      statusCode: openErrorModal.statusCode,
    };
    setErrorModals((errorModals: ErrorModalState[]) => {
      return [...errorModals, newErrorModal];
    });

    return newErrorModal;
  };

  // 에러모달 닫기
  const closeErrorModal = (errorModalId: string) => {
    setErrorModals((errorModals: ErrorModalState[]) => {
      const newErrorModals: ErrorModalState[] = [];
      for (let i = 0; i < errorModals.length; i++) {
        const errorModal = errorModals[i];
        if (errorModal.id === errorModalId) {
          newErrorModals.push({ ...errorModal, ...{ isOpen: false } });
        } else {
          newErrorModals.push(errorModal);
        }
      }
      return newErrorModals;
    });
  };

  // 에러모달 모두 닫기
  const closeAllErrorModal = () => {
    setErrorModals(() => []);
  };

  return { openErrorModal, closeErrorModal, closeAllErrorModal };
};
