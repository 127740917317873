import React from "react";
import { BaseButton } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
const InvitationComplete = () => {
  const { goBack } = useNavigateGoBack();

  return (
    <>
      <MetaTag title="방문자 초대 완료" />
      <div className="visitor-complete">
        <section className="visitor-complete__logo">
          <div className="dears_pangyo"></div>
        </section>
        <section className="visitor-complete__intro">
          <h1>QR 코드 발송이 완료되었습니다.</h1>
          <p>
            <b className="text-primary1">Taap 매니저</b> 알림톡에서 출입 QR 코드를 확인해 주세요.
          </p>
        </section>
        <section className="visitor-complete__contents">
          <p className="desc">
            ⚠️ <span className="ml6">방문 유효 시간은 1시간입니다.</span>
          </p>
          <div className="alert-talk-img"></div>
        </section>
        <section className="visitor-complete__info">
          <p>✔︎ 알림톡을 받지 못했다면?</p>
          <ul>
            <li>입력하신 휴대폰 번호로 카카오톡에 가입되어있는지 확인해 주세요.</li>
            <li>카카오톡 오류인 경우 문자 메시지로 QR 코드 링크가 발송됩니다. 문자 메시지함을 확인해 주세요.</li>
            <li>일시적인 오류이거나 잘못된 휴대폰 번호를 입력한 경우 등록 페이지에서 다시 시도해 주세요.</li>
          </ul>
        </section>
        <section className="px24 pt20 pb50">
          <BaseButton className="base-btn color-white" title="돌아가기" onClick={() => goBack()} />
        </section>
      </div>
    </>
  );
};

export default InvitationComplete;
