type Props = {
  id: string;
  name?: string;
  label?: string;
  underlineLabel?: string;
  checked?: boolean;
  value?: string | ReadonlyArray<string> | number | undefined;
  onChange?: (checked: boolean) => void;
  textDecorationNone?: boolean;
  url?: string;
};

/**
 * 약관동의 체크박스 컴포넌트
 */
const AgreeCheckbox = ({ id, name, label, underlineLabel, checked, value, onChange, textDecorationNone, url }: Props) => {
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target?.checked);
    }
  };

  return (
    <>
      <input className="checkbox" type="checkbox" id={id} name={name} checked={checked} onChange={_onChange} value={value} />
      {(label || underlineLabel) && (
        <label className="font14 flex-center">
          <div
            className="base-checkbox"
            onClick={() => {
              if (onChange) {
                onChange(!checked);
              }
            }}
          ></div>
          <p className="ml10">
            {/* TODO: 약관 상세 화면 띄우기 */}
            {label}{" "}
            {underlineLabel && (
              <a href={url} target="_blank" rel="noreferrer">
                <span className={`${textDecorationNone ? "text-decoration-none" : "text-underline"}`}>{underlineLabel}</span>
              </a>
            )}
          </p>
        </label>
      )}
    </>
  );
};
export default AgreeCheckbox;
