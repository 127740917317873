import { isEmpty } from "lodash";
import { useCallback } from "react";
import { CourtAuthError, TokenResponse } from "../../contexts/CourtAuthContext";
import refreshTokenAsync from "../../utils/refresh-token";
import SingleAsyncCall from "../../utils/SingleAsyncCall";
import tokenResponseRepository from "../../utils/token-response-repository";
import verifyJwt, { VerifyJwtOptions } from "../../utils/verify-jwt";

const singleAsyncCall = new SingleAsyncCall<TokenResponse>();

export default function useLoadTokenAsync(issuer: string, clientId: string, tokenEndpoint: string) {
  /**
   * 갱신 토큰 요청
   */
  const refreshToken = useCallback(
    async (refreshToken: string) => {
      const token = await singleAsyncCall.execute(() => refreshTokenAsync(tokenEndpoint, clientId, refreshToken));
      tokenResponseRepository.save(token);
      return token;
    },
    [clientId, tokenEndpoint],
  );

  /**
   * 토큰 스토리지와 토큰 갱신을 통한 토큰 로드
   */
  return useCallback<() => Promise<TokenResponse>>(async () => {
    let token = tokenResponseRepository.load();
    if (token === null) {
      throw new CourtAuthError("storage::no_token_response", "cannot found token response");
    }
    try {
      validateTokenResponse(token, { issuer });
      return token;
    } catch (error: any) {
      if ("code" in error) {
        const authError = error as CourtAuthError;
        if (authError.code === "jwt::expired") {
          return refreshToken(token.refreshToken);
        }
      }
      throw error;
    }
  }, [issuer, refreshToken]);
}

function validateTokenResponse(token: TokenResponse, options: VerifyJwtOptions) {
  if (isEmpty(token.accessToken)) {
    throw new Error("no access_token");
  }
  if (isEmpty(token.idToken)) {
    throw new Error("no id_token");
  }
  if (isEmpty(token.refreshToken)) {
    throw new Error("no refresh_token");
  }
  verifyJwt(token.accessToken, options);
  verifyJwt(token.idToken, options);
}
