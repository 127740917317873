import { atom } from "recoil";

export interface Toast {
  id?: string;
  content: string;
  duration?: number;
  bottom?: number;
}

/**
 * 토스트
 */
export const toastState = atom<Toast[]>({
  key: "toastState",
  default: [],
});
