import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import { Visitor, VisitorPatchResponseData, VisitorQrResponseData, VisitorUpdateResponseData } from "./visitor-types";
import qs from "qs";

/**
 * (무인증) TAC11. 방문자 QR 조회
 */
export async function getExternalVisitorQrAsync(
  axios: AxiosInstance,
  params?: {
    visitorKey: string;
  },
): Promise<AxiosResponse<ApiResponse<VisitorQrResponseData>>> {
  if (!params?.visitorKey) {
    throw Error("visitorKey is not found.");
  }
  return await axios.get(`/api/court/ct/external/visitor/${params.visitorKey}/qr`);
}

/**
 * TCT58. (무인증)방문 초대내역 정보 조회
 */
export async function getExternalVisitorAsync(
  axios: AxiosInstance,
  params?: {
    visitorKey?: string; // visitorKey : 방문신청 번호(visitApplyNumber) + 계약방문자 id(contractVisitorId) => 방문자 초대내역 정보 조회
    invitationKey?: string; // invitationKey : 초대자회원번호(memberNo) + 방문신청 번호(visitApplyNumber) => 방문초대자 초대내역 정보 조회
  },
): Promise<AxiosResponse<ApiResponse<Visitor>>> {
  let queryParams = {};
  // visitorKey 또는 invitationKey 으로 조회
  if (!params?.visitorKey && !params?.invitationKey) {
    throw Error("visitorKey or invitationKey is not found.");
  }
  if (params?.visitorKey) {
    queryParams = { ...queryParams, ...{ visitorKey: params.visitorKey } };
  } else if (params?.invitationKey) {
    queryParams = { ...queryParams, ...{ invitationKey: params.invitationKey } };
  }
  return await axios.get(`/api/court/ct/external/visitor/`, {
    params: { ...queryParams, revision: 1 },
  });
}

/**
 * TCT59. (무인증)방문자 등록차량 정보 수정
 */
export async function updateExternalVisitorAsync(
  axios: AxiosInstance,
  params?: {
    visitorKey: string;
    visitorCarNumber: string;
  },
): Promise<AxiosResponse<ApiResponse<VisitorUpdateResponseData>>> {
  if (!params?.visitorKey) {
    throw Error("visitorKey is not found.");
  }
  if (!params?.visitorCarNumber) {
    throw Error("visitorCarNumber is not found.");
  }
  return await axios.patch(`/api/court/ct/external/visitor/${params.visitorKey}/update`, {
    contractVisitor: {
      visitorCarNumber: params.visitorCarNumber,
    },
  });
}

/**
 * TCT57. (무인증)방문자용 개인정보 이용 약관 동의 저장
 */
export async function patchConsentAgreeAsync(
  axios: AxiosInstance,
  params?: {
    visitorKey: string;
    inputNumber: string;
  },
): Promise<AxiosResponse<ApiResponse<{ data:VisitorPatchResponseData}>>> {
  if (!params?.visitorKey) {
    throw Error("visitorKey is not found.");
  }
  return await axios.patch(`/api/court/ct/external/visitor/${params.visitorKey}/consent-agree`, {
    inputNumber: params.inputNumber,
    form: {
      revision: 1,
    },
  });
}

/**
 * TCT54. (무인증) 방문자 초대
 */
export async function postVisitorInvitationAsync(
  axios: AxiosInstance,
  params?: {
    encryptedContractManageId: string;
    encryptedBuildingId: string;
    visitorMobileNumber: string;
    visitPurpose: string;
    token: string;
  },
): Promise<AxiosResponse<ApiResponse<Visitor>>> {
  if (!params?.encryptedContractManageId) {
    throw Error("params.encryptedContractManageId is not found.");
  }
  if (!params?.encryptedBuildingId) {
    throw Error("params.encryptedBuildingId is not found.");
  }
  return await axios.post(
    `/api/court/ct/external/visitor/contract-manage/${params?.encryptedContractManageId}/building/${params?.encryptedBuildingId}/invite`,
    {
      contractVisitorList: [{ visitorMobileNumber: params?.visitorMobileNumber, visitPurpose: params?.visitPurpose }],
    },
    {
      headers: {
        "X-Recaptcha-Token": params!.token,
      },
    },
  );
}
