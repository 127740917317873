import { Status } from "../public-types";

export type SiteType = "SITE_UNRECOGNIZED" | "SITE_TAAP" | "SITE_EXTERNAL" | "SITE_ETC" | "SITE_TAAP_SPACE" | "SITE_HOMEPAGE";

export const SiteTypeEnum = {
  SITE_UNRECOGNIZED: "SITE_UNRECOGNIZED",
  SITE_TAAP: "SITE_TAAP",
  SITE_EXTERNAL: "SITE_EXTERNAL",
  SITE_ETC: "SITE_ETC",
  SITE_TAAP_SPACE: "SITE_TAAP_SPACE",
  SITE_HOMEPAGE: "SITE_HOMEPAGE",
} as const;
/*
csTypeId에 매칭되는 값
1:1문의 : 9
견적문의 : 10
상담신청 : 11
투어신청 : 12
예약문의 : 13
*/
export const changeVoCType = (csTypeId: string) => {
  switch (csTypeId) {
    case "9":
      return "1:1문의";
    case "10":
      return "견적문의";
    case "11":
      return "상담신청";
    case "12":
      return "투어신청";
    case "13":
      return "예약문의";
    default:
      return "-";
  }
};
export interface QuestionParams {
  partnerId?: string;
  csTypeId?: string; //VoC유형 ID
  contentType?: "text/plain" | "text/html"; //문의내용 MIME Type
  csCategoryType?: string;
  reporterName?: string;
  reporterPhone?: string;
  reporterEmail?: string;
  description?: string; // 문의내용
  isSendAlarm?: boolean; // 알림톡 발송여부
  site?: SiteType;
}

export interface QuestionModel {
  id?: string; // 상담문의 ID
  partnerId?: string;
  //   questionCategory?: any; // 삭제된 카테고리로 보류
  site?: SiteType; // 유입경로
  csTypeId?: string; // VoC 유형 ID
  csTypeName?: string; // VoC유형명
  csItemId?: string; // VoC항목 ID
  csCategoryType?: string; // 구분
  buildingId?: string;
  summary?: string; // 제목
  memberNo?: string; //회원번호
  reporterName?: string; // 접수자 이름
  reporterPhone?: string; // 접수자 연락처
  reporterEmail?: string; // 접수자 이메일
  description?: string; //문의내용
  cost?: number; //금액
  status?: Status; //상태
  isReopened?: boolean; //재처리요청여부
  isSendAlarm?: boolean; //알림톡발송여부
  assigneeName?: string; //담당자명
  assigneeEmail?: string; //담당자 이메일
  isDeleted?: boolean; //삭제여부
  createdDate?: string; //생성일
  createdBy?: string; // 등록ID
  modifiedDate?: string; //수정일
  modifiedBy?: string; //수정ID
}
