import jwtDecode from "jwt-decode";
import moment from "moment";
import { OAuth2Jwt, CourtAuthAccessTokenPayload, CourtAuthIdTokenPayload, CourtAuthError, CourtAuthJwtHeader } from "../contexts/CourtAuthContext";

export type VerifieableJwtPayload = {
  // 인증 발행자
  iss: string;
  // 토큰 만료 시간
  exp: number;
};

export type VerifyJwtOptions = {
  // 인증 발행자
  issuer?: string;
  // 용인 되는 시간 차(seconds)
  clockTolerance?: number;
};

export default function verifyJwt<T extends CourtAuthAccessTokenPayload | CourtAuthIdTokenPayload>(jwt: string, options: VerifyJwtOptions) {
  const header = jwtDecode<CourtAuthJwtHeader>(jwt, { header: true });
  const payload = jwtDecode<T>(jwt, { header: false });
  verifyJwtPayload(payload, options);
  return { header, payload } as OAuth2Jwt<T>;
}

function verifyJwtPayload(payload: VerifieableJwtPayload, options: VerifyJwtOptions) {
  if (options.issuer) {
    if (payload.iss !== options.issuer) {
      throw new CourtAuthError("jwt::invalid_issuer", "invalid issuer");
    }
  }
  if (isExpired(payload, options)) {
    throw new CourtAuthError("jwt::expired", "jwt expired");
  }
}

function isExpired(payload: VerifieableJwtPayload, options: VerifyJwtOptions) {
  const { clockTolerance = 5 } = options;
  const now = moment();
  const expiredAt = moment.unix(payload.exp - clockTolerance);
  return expiredAt.isBefore(now);
}
