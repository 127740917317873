import { atom } from "recoil";
import { MbMember } from "src/api/member/member-types";

export type MemberInfoState = {
  member: MbMember | null;
};

/**
 * 로그인한 사용자 정보
 */
export const memberInfoState = atom<MemberInfoState>({
  key: "memberInfoState",
  default: {
    member: null,
  },
});
