interface Props {
  accessLogs: {
    accessDate: string;
    phoneNumber: string;
    deviceName: string;
    eventName: string;
  }[];
}

function AccessLogList({ accessLogs }: Props) {
  if (accessLogs.length === 0)
    return (
      <div className="empty-container">
        <div className="empty-text">출입 내역이 없어요.</div>
      </div>
    );

  return (
    <ul className="list-container">
      {accessLogs.map((accessLog) => {
        return (
          <li key={JSON.stringify(accessLog)}>
            <div className="date">{accessLog.accessDate}</div>
            <div className="phoneNumber">{accessLog.phoneNumber}</div>
            <div className="title">{accessLog.deviceName}</div>
            <div className="isAccess">{accessLog.eventName}</div>
          </li>
        );
      })}
    </ul>
  );
}
export default AccessLogList;
