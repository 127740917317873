import { ConfirmModal } from "src/components";

interface propState {
  isOpen: boolean;
  current: number;
  total: number;
}

function InviteLoadingModal(props: propState) {
  return (
    <ConfirmModal isOpen={props.isOpen}>
      <div>
        <section className="contents-indicator">
          <div className="--loading"></div>
        </section>
        <p className="font18 font-weight-600 mb20">초대 메시지 발송중...</p>
        <p>{`${props.current} / ${props.total}`}</p>
      </div>
    </ConfirmModal>
  );
}

export default InviteLoadingModal;
