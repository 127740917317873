
// 기본 회사로고
const defaultLogo = process.env.REACT_APP_S3_CONTENT_BASEURL + "taap/images/logo_120x40.png";


/**
 * 회사로고
 */
const CompanyLogo = () => {

  return (
    <img
      className="visitor__logo"
      src={defaultLogo}
      alt="회사로고"
      onError={(e) => {
        e.currentTarget.src = defaultLogo;
      }}
    />
  );
};
export default CompanyLogo;
