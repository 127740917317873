import { Link } from "react-router-dom";
import { ProductModel } from "src/api/product/product-types";
import { sortedByBuildingName, sortedPrimaryFirstBuilding } from "../../product-types";

type Props = {
  product: ProductModel | null;
};

const BuildingsInfoArea = (props: Props) => {
  return (
    <section className="product-info__building-info bottom-divider">
      <h2>건물 상세 정보</h2>

      {props.product?.buildingList &&
        sortedPrimaryFirstBuilding(sortedByBuildingName(props.product?.buildingList))?.map((building) => (
          <Link to={`/court/building/${building.id}`} key={building.id}>
            <div className="top-line-container mt20">
              <div className="flex-start w-100">
                <div className="top-line-container__info">
                  <h3>{building.buildingName}</h3>
                </div>
              </div>
              <button></button>
            </div>
          </Link>
        ))}
    </section>
  );
};

export default BuildingsInfoArea;
