import { atom } from "recoil";

export type ErrorModalState = {
  id: string;
  isOpen: boolean;
  errorCode?: string;
  errorMessage?: string;
  errorData?: any;
  statusCode?: number;
};

/**
 * API 에서 에러발생시 띄우는 에러 모달
 */
export const errorModalState = atom<ErrorModalState[]>({
  key: "errorModalState",
  default: [],
});
