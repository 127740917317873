import { ComponentType, useContext, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAnonymousPathPatterns } from "src/pages/hooks/anonymous-path-patterns";
import log from "src/utils/log";
import CourtAuthContext, { CourtAuthReturnTo, CourtAuthReturnToState, CourtAuthState } from "../contexts/CourtAuthContext";
import courtAuthStateRepository from "../utils/court-auth-state-repository";

export type WithAuthRequiredOptions = {
  // 인증 후 찾아갈 경로
  returnTo?: string | (() => string);
  // 인증 필요 시 출력 컴포넌트
  Unauthenticated?: () => JSX.Element;
};

const defaultReturnTo = () => `${window.location.pathname}${window.location.search}`;

export default function withAuthRequired<P>(Component: ComponentType<P>, options: WithAuthRequiredOptions = {}) {
  return (props: P) => {
    const { returnTo = defaultReturnTo, Unauthenticated = () => <></> } = options;
    const { security } = useContext(CourtAuthContext);

    // 무인증 페이지 여부
    const { matchAnonymous } = useAnonymousPathPatterns();
    const location = useLocation();

    const [isReady, setIsReady] = useState(false);

    // 회원가입 필요 여부
    const isRequireJoin = useMemo(() => {
      // 인증 페이지 (회원가입 페이지 제외) 접근시 회원가입 해야되는 회원인지 확인 (휴대폰 인증 후 회원 가입 완료 전 상태)
      return (
        !matchAnonymous &&
        location.pathname !== "/court/join/email" &&
        security.status === "authenticated" &&
        security.user?.roles.includes("ROLE_STARTER")
      );
    }, [matchAnonymous, location, security]);

    // 인증 필요 상태 여부
    const isRequireAuth = useMemo(() => {
      return !matchAnonymous && !isRequireJoin && !["none", "pending", "authenticated"].includes(security.status);
    }, [matchAnonymous, isRequireJoin, security]);

    useEffect(() => {
      if (isRequireAuth) {
        // 인증 페이지 인데 인증상태 아닐 경우
        const orgState = courtAuthStateRepository.load() ?? ({} as CourtAuthState);
        const orgHistory = orgState.returnTo?.history ?? [];
        const latest = {
          uri: typeof returnTo === "function" ? returnTo() : returnTo,
          time: Math.floor(new Date().getTime() / 1000),
        } as CourtAuthReturnTo;
        const returnToState = { latest, history: [latest, ...orgHistory] } as CourtAuthReturnToState;
        const newState = { returnTo: returnToState };
        log("withAuthRequired :: isRequireAuth :: newState", JSON.stringify(newState, null, 2));
        courtAuthStateRepository.save(newState);
        // // 인증 필요
        // setRequireAuth(true);
      }

      if (!isReady) {
        setIsReady(true);
      }
    }, [isRequireAuth, returnTo]);

    log("withAuthRequired :: isRequireAuth", isRequireAuth);
    log("withAuthRequired :: isRequireJoin", isRequireJoin);

    if (!isReady) {
      return <></>;
    }

    if (isRequireJoin) {
      return <Navigate replace to="/court/join/email" />;
    }

    if (isRequireAuth) {
      // 인증 페이지 인데 인증 필요할 경우
      return <Unauthenticated />;
    }

    if (security.status !== "authenticated") {
      // 인증 페이지인데 인증 상태가 아닐 경우
      return <></>;
    }

    // 무인증 페이지 또는 인증페이지 인증 완료된 경우
    return <Component {...props} />;
  };
}
