import { useCallback, useContext, useMemo } from "react";
import CourtAuthContext, { TokenResponse } from "../contexts/CourtAuthContext";
import courtAuthStateRepository from "../utils/court-auth-state-repository";
import SingleAsyncCall from "../utils/SingleAsyncCall";
import tokenResponseRepository from "../utils/token-response-repository";
import useLoadTokenAsync from "./client/load-token";
import refreshTokenInternalAsync from "../utils/refresh-token";

const singleAsyncCall = new SingleAsyncCall<TokenResponse>();

export default function useCourtAuth() {
  const { authorize, refresh, revoke: revokeSecurity, options, security } = useContext(CourtAuthContext);
  const loadTokenAsync = useLoadTokenAsync(options.issuer, options.clientId, options.endpoints.token);

  // id_token 조회
  const getIdTokenAsync = useCallback(async () => {
    try {
      const token = await loadTokenAsync();
      refresh({ token });
      return token.idToken;
    } catch (error: any) {
      refresh({ error });
      throw error;
    }
  }, [loadTokenAsync, refresh]);

  // 갱신 토큰 처리
  const refreshTokenAsync = useCallback(async () => {
    const {
      clientId,
      endpoints: { token: tokenEndpoint },
    } = options;
    const { status, refreshToken } = security;
    if (refreshToken) {
      const token = await singleAsyncCall.execute(() => refreshTokenInternalAsync(tokenEndpoint, clientId, refreshToken));
      tokenResponseRepository.save(token);
      refresh({ token });
      return token;
    } else if (status !== "authenticated") {
      throw new Error("인증이 되지 않아 refresh_token 이 존재 하지 않습니다.");
    } else {
      throw new Error("알 수 없는 에러 발생");
    }
  }, [options, security]);

  // 로그 아웃 처리
  const revoke = useCallback(async () => {
    const token = tokenResponseRepository.load();
    if (token) {
      await fetch(options.endpoints.revoke, {
        method: "post",
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: new URLSearchParams({ grant_type: "refresh_token", client_id: options.clientId, token: token.refreshToken }),
      });
      tokenResponseRepository.remove();
      courtAuthStateRepository.remove();
    }
    revokeSecurity();
  }, [options, revokeSecurity]);

  const contextValue = useMemo(() => {
    return { authorize, getIdTokenAsync, revoke, security, refreshTokenAsync };
  }, [authorize, getIdTokenAsync, revoke, security, refreshTokenAsync]);

  return contextValue;
}
