interface Props {
  totalPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

function Pagination({ totalPage, currentPage, onPageChange }: Props) {
  if (totalPage === 0) return null;

  const pages = Array.from({ length: totalPage }, (_, i) => i + 1);

  const dividedPages = pages.reduce((acc, page) => {
    const last = acc[acc.length - 1];
    if (!last || last.length === 5) {
      acc.push([page]);
    } else {
      last.push(page);
    }
    return acc;
  }, [] as number[][]);

  const currentPages = dividedPages.find((pages) => pages.includes(currentPage));

  const isLastPage = currentPages?.includes(totalPage);
  const isFirstPage = currentPages?.includes(1);
  const isCurrentPage = (page: number) => page === currentPage;
  const isOutOfRange = (page: number) => page < 1 || page > totalPage;
  const isDisabled = (page: number) => isOutOfRange(page) || isCurrentPage(page);

  const handlePageChange = (page: number) => {
    if (isDisabled(page)) return;

    onPageChange(page);
  };

  return (
    <ul className="pagination">
      <li onClick={() => handlePageChange(currentPage - 1)} className={isDisabled(currentPage - 1) ? "disabled" : ""}>{`<`}</li>

      {currentPages?.map((page) => (
        <li key={page} onClick={() => handlePageChange(page)} className={page === currentPage ? "active" : ""}>
          {page}
        </li>
      ))}

      <li onClick={() => handlePageChange(currentPage + 1)} className={isDisabled(currentPage + 1) ? "disabled" : ""}>{`>`}</li>
    </ul>
  );
}
export default Pagination;
