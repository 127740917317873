import moment from "moment";
import { useMemo } from "react";
import { ClosedDay } from "src/api/reservation/reservation-type";
import { Dates } from "../SwiperCalendar";
// import { RenderCalendar } from "./RenderCalendar";
// import { data } from "./data";

type Props = {
  closedDays: ClosedDay[];
  month: moment.Moment;
  selectedDay: string;
  handleClickDay: (e: React.MouseEvent<HTMLButtonElement>, day: Dates) => void;
  createCalendarDate: (date: moment.Moment) => { date: string }[]; //공용공간 > 캘린더 날짜 영역을 그려주는 함수
};

const calendarDateFormat = "YYYY/MM/DD";

const SlideItem = ({ month, selectedDay, handleClickDay, closedDays, createCalendarDate }: Props) => {
  //일단 1일부터 3n 일까지 반복..
  // TODO : Taap 캘린더 룰과 동일해야함
  // 1) 매년 1월 1일부터 12개월을 노출한다 (확인필요)
  // 2) 각 슬라이더의 마지막 주는 다음 슬라이드의 첫번째 주에서 반복한다 **
  const firstDate = useMemo(() => moment(month).startOf("months").startOf("weeks"), [month]);

  const days = useMemo(() => {
    const _days = createCalendarDate(firstDate);
    return _days;
  }, [firstDate]);

  // const findClass = (day: Dates) => {
  //   let colIndex = new Date(day.date).getDay();
  //   return `col-start-${colIndex + 1}`;
  // };

  const dateButtonDisabled = (date: string) => {
    const dateIsBefor = moment(date).isBefore(moment().subtract(1, "day"));
    const isClosedDate = closedDays.some((day) => moment(day.date).format(calendarDateFormat) === date);

    return dateIsBefor || isClosedDate;
  };

  return (
    <div className="swiper-slide">
      <div className="days-container">
        {days?.map((day, dayIdx) => (
          <div key={day.date.toString()} className={`day`}>
            {moment(day.date).format("D") === "1" && selectedDay !== day.date && (
              <div className={`absolute-month ${moment(day.date).isBefore(moment().subtract(1, "day")) ? "disabled" : ""}`}>
                <span>{moment(day.date).format("M")} /</span>
              </div>
            )}
            <button
              type="button"
              disabled={dateButtonDisabled(day.date)}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickDay(e, day)}
              className={day.date === selectedDay ? "--active" : ""}
            >
              {moment(day.date).format("D")}
            </button>
            {selectedDay !== day.date && day.date === moment().format("YYYY/MM/DD") && <div className="absolute-today">오늘</div>}
            {/* {closedDays.some((closed) => moment(closed.date).format("YYYY/MM/DD") === day.date) && (
              <div className="absolute-today text-gray">휴무</div>
            )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlideItem;
