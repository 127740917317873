import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import moment from "moment";
import numeral from "numeral";

export function getUserDevice() {
  const varUA = navigator.userAgent.toLowerCase(); // userAgent 값 얻기
  if (varUA.indexOf("android") > -1) {
    // 안드로이드
    return "android";
  } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1 || varUA.indexOf("ios") > -1) {
    // IOS
    return "ios";
  } else {
    // IOS, 안드로이드 외 (PC)
    return "other";
  }
}

/* 
  number 를 받아서 천단위 콤마를 찍어서 string 으로 리턴
  ex) numberToStringWithComma(1000) => "1,000"
  ex) numberToStringWithComma(1000.34) => "1,000"
  ex) numberToStringWithComma(1000.34, "0,0.00") => "1,000.34"
  ex) numberToStringWithComma(1000.34, "0,0.0") => "1,000.3"
*/
export function numberToStringWithComma(num?: number, format: string = "0,0"): string {
  if (!num) return "0";
  if (Math.floor(num) === num) {
    // 정수
    return numeral(num).format(format);
  }
  // 소수까지
  const [intVal, decimalVal] = String(num).split(".");
  return numeral(intVal).format("0,0") + "." + decimalVal;
}

/* 
  소수점 두자리까지 반올림
  ex) roundToTwo(45.666) => 45.67
*/
export function roundToTwo(num?: number): number {
  const value = num || 0;
  const m = Number((Math.abs(value) * 100).toPrecision(15));
  const round = (Math.round(m) / 100) * Math.sign(value);
  return round;
}

/* 
  소수점 두자리까지 버림
  ex) floorToTwo(45.666) => 45.66
*/
export function floorToTwo(num?: number): number {
  const value = num || 0;
  const m = Number((Math.abs(value) * 100).toPrecision(15));
  const floor = (Math.floor(m) / 100) * Math.sign(value);
  return floor;
}

/* 
  평수 계산 (m2 x 0.3025, 소수점 2자리 반올림). 천단위 콤마 처리
  ex) calculatePyeong(1000000) => "302,500.00"
  ex) calculatePyeong(1000000, "0,0") => "302,500"
 */
export function calculatePyeong(num?: number, format: string = "0,0.00"): string {
  const pyeong = (num || 0) * 0.3025;
  return numeral(pyeong).format(format);
}

/* 
  평수 계산 (m2 x 0.3025, 소수점 2자리 반올림)
  ex) calculatePyeongNum(444444) => 134444.31
 */
export function calculatePyeongNum(num?: number): number {
  const pyeong = (num || 0) * 0.3025;
  return roundToTwo(pyeong);
}

// 전용률 구하기 - 전용면적 / 계약면적 * 100
export function calculateRateOfUse(
  leasableAreaNet: string | number | undefined,
  leasableArea: string | number | undefined,
  format: string = "0,0.00",
): string {
  const value = (Number(leasableAreaNet || 0) / Number(leasableArea || 0)) * 100;
  const floorValue = floorToTwo(value);
  const result = numeral(floorValue).format(format);
  return result;
}

// 비용 단위 천 / 억 으로 변환
export const getKoreanCost = (cost?: number | string) => {
  // if (!cost) return ""; // 주석처리한이유:: 0도 유의미한값이므로 출력해야함
  const koreanUnits = ["원", "만원", "억", "조"];
  let answer = "";
  let unit = 10000;
  let index = 0;
  let division = Math.pow(unit, index);

  const costNumber = Number(cost || 0);

  while (Math.floor(costNumber / division) > 0) {
    const mod = Math.floor((costNumber % (division * unit)) / division);
    if (mod) {
      const modToString = mod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      answer = `${modToString}${koreanUnits[index]} ` + answer;
    }
    // division = Math.pow(unit, ++index);
    division = Math.pow(unit, ++index);
  }
  return `${answer || 0}`;
};

// 1억 5500만원 => cost, unit 으로 분리
type CostUnit = {
  cost?: string; // 1억 5500
  unit?: "원" | "만원" | undefined; // 만원
};
export function getKoreanCostUnits(cost?: number | string): CostUnit {
  const defaultCostUnit: CostUnit = { cost: undefined, unit: "원" };
  if (cost === null || undefined) return defaultCostUnit;
  const costNumber = Number(cost || 0);
  const koreanCode = getKoreanCost(costNumber);

  if (koreanCode.lastIndexOf("만원") > -1) {
    return {
      cost: koreanCode.substring(0, koreanCode.lastIndexOf("만원")),
      unit: "만원",
    };
  } else if (koreanCode.lastIndexOf("원") > -1) {
    return {
      cost: koreanCode.substring(0, koreanCode.lastIndexOf("원")),
      unit: "원",
    };
  } else {
    return {
      cost: koreanCode,
      unit: "원",
    };
  }
}

/**
 * 원본 이미지 url -> resized 이미지 url 로 변환
 */
export function getResizedImageUrl(url?: string): string {
  if (!url) return "";
  const extIndex = url.lastIndexOf(".");
  const ext = url.slice(extIndex); // 파일 확장자
  const resizedImageUrl = url.slice(0, extIndex) + "_resized" + ext;
  return resizedImageUrl;
}

/**
 * 시작시간 ~ 종료시간 차이
 * @param startDate
 * @param endDate
 * @returns "1시간 30분", "1시간", "30분", ""
 */
export function getTimeDiff(startDate?: string, endDate?: string): string {
  if (!startDate || !endDate) return "";
  const diff = moment(startDate).diff(moment(endDate));
  const duration = moment.duration(diff);
  const diffHours = Math.floor(Math.abs(duration.asHours())); // asHours 24시간 이상도 커버하는 시간차
  const diffMinutes = Math.floor(Math.abs(duration.minutes())); // minutes 분 차이

  if (diffHours === 0 && diffMinutes === 0) return "";

  let result = "";
  if (diffHours > 0) {
    result += diffHours + "시간";
  }
  if (diffMinutes > 0) {
    result += (diffHours > 0 ? " " : "") + diffMinutes + "분";
  }
  return result;
}

/**
 * 이용시작시간 ~ 이용종료시간 차이 괄호 포함 eg."(1시간 30분)"
 * @param useStartDate
 * @param useEndDate
 * @returns "(1시간 30분)", ""
 */
export function getUseTimeDiff(useStartDate?: string, useEndDate?: string): string {
  if (!useStartDate || !useEndDate) return "";

  const diff = getTimeDiff(useStartDate, useEndDate);
  return diff ? `(${diff})` : "";
}

/**
 * 휴대폰 번호 validation ("+821012345678" || "01012345678")
 * @param phoneNumber 입력한 휴대폰 번호
 * @param requiredMessage 필수입력 에러 메세지 (없으면 "필수입력항목입니다")
 * @param formatMessage 휴대폰 번호 포맷 에러 메세지 (없으면 "올바르지 않은 휴대폰 번호입니다")
 * @returns
 */
export function validatePhoneNumber(phoneNumber?: string, requiredMessage?: string, formatMessage?: string): boolean | string {
  if (!phoneNumber) return requiredMessage || "휴대폰 번호를 입력해주세요";
  const patterns = [
    /^010\d{8}$/, // 01012345678
    /^010-\d{4}-\d{4}$/, // 010-1234-5678
    /^[+]8210\d{8}$/, // +821012345678
    /^[+]82\s10\d{8}$/, // +82 1012345678
    /^[+]82010\d{8}$/, // +8201012345678
    /^[+]82\s010\d{8}$/, // +82 01012345678
    /^[+]82-10-\d{4}-\d{4}$/, // +82-10-1234-5678
    /^[+]82\s10-\d{4}-\d{4}$/, // +82 10-1234-5678
    /^[+]82-010-\d{4}-\d{4}$/, // +82-010-1234-5678
    /^[+]82\s010-\d{4}-\d{4}$/, // +82 010-1234-5678
  ];
  return patterns.some((pattern) => pattern.test(phoneNumber)) || formatMessage || "올바르지 않은 휴대폰 번호입니다";
}

export function validatePhoneNumberBoolean(phoneNumber?: string): boolean {
  if (!phoneNumber) return false;
  const patterns = [
    /^010\d{8}$/, // 01012345678
    /^010\d{4}\d{4}$/, // 010-1234-5678
    /^[+]8210\d{8}$/, // +821012345678
    /^[+]82\s10\d{8}$/, // +82 1012345678
    /^[+]82010\d{8}$/, // +8201012345678
    /^[+]82\s010\d{8}$/, // +82 01012345678
    /^[+]8210\d{4}\d{4}$/, // +82-10-1234-5678
    /^[+]82\s10\d{4}\d{4}$/, // +82 10-1234-5678
    /^[+]82010\d{4}\d{4}$/, // +82-010-1234-5678
    /^[+]82\s010\d{4}\d{4}$/, // +82 010-1234-5678
  ];
  return patterns.some((pattern) => pattern.test(phoneNumber));
}
/**
 * international 휴대폰번호 형식을 일반적인 한국 표기 방식으로 변환
 * @param phoneNumber "+821012345678", "01012345678"
 * @returns "010-1234-5678"
 */
export function convertKoreanPhoneNumber(phoneNumber?: string): string {
  if (!phoneNumber) return "";
  let result = phoneNumber.replace(/ /g, ""); // 공백제거
  result = result.split("-").join(""); // 데시제거

  if (result.startsWith("+820")) {
    result = result.replace("+820", "0");
  } else if (result.startsWith("+82")) {
    result = result.replace("+82", "0");
  }
  if (result.length >= 11) {
    result = [result.substring(0, 3), result.substring(3, 7), result.substring(7, 11)].join("-");
  } else {
    result = [result.substring(0, 3), result.substring(3, 6), result.substring(6)].join("-");
  }
  return result;
}

/**
 * +, -, 숫자, 공백 포함한 숫자 제외하고는 replace
 * @param str
 * @returns
 */
export function replaceNumberWithPlusMinus(str: string) {
  return str.replace(/[^\d+-\s]+/g, "");
}

// webview에서 페이지 이동을 할 때 사용
export function navigateWebviewPage(path: string) {
  const a = document.createElement("a");
  a.href = path;
  a.dispatchEvent(new MouseEvent("click", { bubbles: true }));
}

// 숫자만 입력
export function onlyNumber(value: string) {
  return value.replace(/[^0-9]/g, "");
}

// 콤마 삭제
export const deleteCommas = (str: any) => {
  if (str) {
    if (typeof str === "number") {
      return;
    } else {
      if (str !== "") {
        return str.replace(/,/g, "");
      } else {
        return;
      }
    }
  } else {
    return;
  }
};

/** 분 number 입력시 "x시간" or "x시간x분" 으로 리턴해주는 함수 */
export const makeTimeString = (value: number) => {
  const hour = Math.floor(value / 60);
  const minute = Math.floor(value % 60);

  if (value < 60) {
    return `${value}분`;
  }

  if (minute === 0) {
    return `${hour}시간`;
  }

  return `${hour}시간 ${minute}분`;
};

export const onChangeKoreanDays = (date?: string) => {
  if (date) {
    const day = moment(date).format("dddd");
    if (day) {
      let koreanDay = "";
      switch (day) {
        case "월요일":
          koreanDay = "월";
          break;
        case "화요일":
          koreanDay = "화";
          break;
        case "수요일":
          koreanDay = "수";
          break;
        case "목요일":
          koreanDay = "목";
          break;
        case "금요일":
          koreanDay = "금";
          break;
        case "토요일":
          koreanDay = "토";
          break;
        case "일요일":
          koreanDay = "일";
          break;
      }
      return koreanDay;
    }
  }
};
export const makeCommonSpaceList = (product: any) => {
  const tableData: any = [];

  if (product.isMeetingRoom) {
    product?.buildingList?.forEach((data: any) => {
      data.buildingCommonFacility?.meetingRoomList?.forEach((space: any) => {
        let returnDt = { ...space, buildingName: data.buildingName, isSelected: false, isSelectedId: "isSelected_" + space.id };
        tableData.push(returnDt);
      });
    });
  }

  if (product.isRefreshRoom) {
    product?.buildingList?.forEach((data: any) => {
      data.buildingCommonFacility?.refreshRoomList?.forEach((space: any) => {
        let returnDt = { ...space, buildingName: data.buildingName, isSelected: false, isSelectedId: "isSelected_" + space.id };
        tableData.push(returnDt);
      });
    });
  }

  if (product.isDesk) {
    product?.buildingList?.forEach((data: any) => {
      data.buildingCommonFacility?.deskSpace?.deskGroupList?.forEach((deskGroup: any) =>
        deskGroup.deskList?.forEach((desk: any) => {
          let returnDt = {
            ...desk,
            groupName: deskGroup.groupName,
            buildingName: data.buildingName,
            isSelected: false,
            isSelectedId: "isSelected_" + desk.id,
          };
          tableData.push(returnDt);
        }),
      );
    });
  }

  return tableData;
};

// api용으로 사용합니다.
export const YmdFormat = {
  YYYY: "YYYY",
  MM: "MM",
  YYYY_MM: "YYYY-MM",
  YYYY_MM_DD: "YYYY-MM-DD",
  TIME: "HH:mm:ss",
  YYYY_MM_DD_HH_MM: "YYYY-MM-DD HH:mm",
  FULL: "YYYY-MM-DD HH:mm:ss",
  FULL_KO: "YYYY년 MM월 DD일 HH시 mm분",
  FULL_KO1: "YYYY/MM/DD HH:mm",
  WITH_TIME_ZONE: "YYYY-MM-DDTHH:mm:ss.SSSZ",
} as const;

// 이메일 @ 앞에만 남기기 id만
export const getEmailNickname = (email: string) => {
  // 정규식을 사용하여 이메일 주소에서 사용자 이름을 추출합니다.
  const pattern = /^[^@]+/;
  const match = email.match(pattern);
  console.log("match", match);
  if (match) {
    return match[0];
  } else {
    return null;
  }
};

// 핸드폰번호 +82 E.164 포맷으로 변경
export const parsedPhoneNumber = (phone: string) => {
  let phoneNumber;
  if (phone) {
    if (isValidPhoneNumber(phone, "KR") === false) {
      return phone;
    }
    phoneNumber = parsePhoneNumber(phone, "KR").number;
  }
  return phoneNumber;
};

// E.164 포맷 -> 일반 핸드폰 포맷으로 변경 ex)010-1111-2222
export const formatPhoneNumber = (phone: string) => {
  let phoneNumber;
  if (phone) {
    if (isValidPhoneNumber(phone, "KR") === false) {
      return phone;
    }
    phoneNumber = parsePhoneNumber(phone, "KR").formatNational();
  }
  return phoneNumber;
};

export const linePhoneFormat = (phone: string) => {
  let phoneNumber;
  const format = /^(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))(\d{3,4})(\d{4})$/;
  if (phone) {
    phoneNumber = format.test(phone);
  }
  return phoneNumber;
};

export const emailFormat = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // 이메일 주소 내에 한글이 포함되지 않았는지 확인
  return emailRegex.test(email);
};
