import { ScheduleListModel } from "src/api/contract/contract-types";

type Props = {
  scheduleInfo: ScheduleListModel;
  handleClickPayment: () => void;
  disabledPaymentChange: boolean;
};

const PaymentMethodInfo = ({ scheduleInfo, handleClickPayment, disabledPaymentChange }: Props) => {
  return (
    <section className="base-section-layout top-divider">
      <div className="base-section-layout__container">
        <div className="base-section-layout__wrap mb30 pb0">
          <div className="base-section-layout__title-wrap">
            <h2>결제 수단</h2>
          </div>

          <div className="base-section-layout-index d-flex">
            <div className="flex-center-start">
              <div className="mr15">{scheduleInfo.payInfo}</div>
              <div>{`(${scheduleInfo.cardInfo})`}</div>
            </div>

            {/* 이용완료 / 해지접수 상태가 아닐 때만 버튼 노출 */}
            {!disabledPaymentChange && (
              <div className="flex-center-end w-100">
                <button className="base-btn color-white size-small minmax100" onClick={handleClickPayment}>
                  변경
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentMethodInfo;
