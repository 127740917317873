import React, { useEffect } from "react";
import { Link, useMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import { scrollState } from "src/recoil/scroll/atom";
/**
 * Layout > header 컴포넌트
 */

type Props = {
  headerType?: "BACK" | "BACK_OPACITY" | "CLOSE" | "CLOSE_OPACITY" | "SELECT";
  title?: string;
  opacityCount?: number;
  onClickBackButton?: () => void;
  onClickCloseButton?: () => void;
  onClickCenterButton?: () => void;
  onClickRightButton?: () => void;
  rightArea?: React.ReactNode;
};

export default function Header({
  headerType,
  title,
  opacityCount,
  onClickBackButton,
  onClickCloseButton,
  onClickCenterButton,
  onClickRightButton,
  rightArea,
}: Props) {
  // 공간상품 목록
  const matchProduct = useMatch("product/*");
  // 마이페이지
  const matchMypage = useMatch("mypage/*");
  const scroll = useRecoilValue(scrollState);
  const { goBack } = useNavigateGoBack();
  return (
    <>
      {headerType === "BACK" ? (
        <header className={`backward-header ${scroll && Number(scroll) > 1 ? "drop-shadow" : ""}`}>
          <div className="backward-header__wrap">
            <button type="button" className="back-btn" onClick={onClickBackButton ? onClickBackButton : () => goBack()}></button>
            <p>{title ? title : ""}</p>
          </div>
          {rightArea && <div className="right">{rightArea}</div>}
        </header>
      ) : headerType === "BACK_OPACITY" ? (
        <>
          <header className={`backward-header ${opacityCount && opacityCount > 0.1 ? "drop-shadow" : ""}`}>
            <button type="button" className="back-btn" onClick={onClickBackButton}></button>
            <p style={{ opacity: `${opacityCount}` }}>{title ? title : ""}</p>
          </header>
        </>
      ) : headerType === "CLOSE" ? (
        <header className={`close-header ${scroll && Number(scroll) > 1 ? "drop-shadow" : ""}`}>
          <button type="button" className="close-btn" onClick={onClickCloseButton}></button>
          <p>{title ? title : ""}</p>
          <div className="bin">{rightArea}</div>
        </header>
      ) : headerType === "CLOSE_OPACITY" ? (
        <header className={`close-header left-text ${opacityCount && opacityCount > 0.1 ? "drop-shadow" : ""}`}>
          <button type="button" className="close-btn" onClick={onClickCloseButton}></button>
          <p style={{ opacity: `${opacityCount}` }}>{title ? title : ""}</p>
          <div className="bin">{rightArea}</div>
        </header>
      ) : headerType === "SELECT" ? (
        <header className={`select-header-wrap ${scroll && Number(scroll) > 1 ? "drop-shadow" : ""}`}>
          <div className="select-header">
            <div className="select-header__left">
              <button type="button" className="back-btn" onClick={onClickBackButton}></button>
              <p></p>
            </div>
            <button type="button" className="chevron-down-btn ellipsis" onClick={onClickCenterButton}>
              {title ? title : ""}
            </button>
            <div className="select-header__right">
              <button type="button" className="header-text-btn" disabled onClick={onClickRightButton}>
                선택
              </button>
            </div>
          </div>
        </header>
      ) : (
        <header className="header">
          {/* 왼쪽 court 로고 */}
          <div className="left">
            <Link to="/court/" className="logo"></Link>
          </div>
          {/* 오른쪽 영역 */}
          <div className="right">
            {/* 검색 버튼 */}
            {/* <Link to="/court/product">
              <div className={`search ${matchProduct ? "active" : ""}`}></div>
            </Link> */}
            {/* 마이페이지 버튼 */}
            <Link to="/court/mypage/main" className={`my ${matchMypage ? "active" : ""}`}></Link>
          </div>
        </header>
      )}
    </>
  );
}
