import { ScheduleRecentBill } from "src/api/contract/contract-types";
import Header from "src/components/layout/Header";
import RefundPolicy from "../../serviceTypePayment/guide/RefundPolicy";

type Props = {
  refundInfo: any;
  recentBill: ScheduleRecentBill;
  onClose: any;
  onClick: any;
};
const ContractCancel = ({ recentBill, onClose, onClick, refundInfo }: Props) => {
  // 확인 취소 버튼 있는 확인 모달
  console.log("ContractCancel refundInfo", refundInfo);
  return (
    <>
      <Header
        headerType="CLOSE"
        title="취소요청"
        onClickCloseButton={() => {
          if (onClose!) {
            onClose();
          }
        }}
      />
      <div className="contract-page">
        <article className="with-backward-floating">
          {/* <RefundPolicy refundInfo={refundInfo} /> */}

          <section className={`base-section-layout`}>
            <div className="mt20 w-100">
              <p className="pre-formatted flex-center-center">{`결제 및 취소 정책을 확인했으며 예약 취소에 동의합니다.`}</p>
              <p className="pre-formatted flex-center-center">{`예약 취소를 요청하시겠어요?`}</p>
            </div>
            <div className="base-floting-btn-wrap px0">
              <div className="cancel-modal">
                <div className="cancel-modal__contents">
                  <div className="cancel-modal__btn-wrap">
                    <button onClick={onClose} className="base-btn color-white mr10">
                      <p>취소</p>
                    </button>

                    <button onClick={() => onClick()} className="base-btn">
                      <p>확인</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </article>
      </div>
    </>
  );
};
export default ContractCancel;
