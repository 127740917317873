import { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import { InviteResult } from "../user-types";

function getErrorMessage(errorCode?: string) {
  switch (errorCode) {
    case "eCT101":
      return "정보를 찾을 수 없거나 삭제되었습니다.";
    case "eCT104":
    case "eCT602":
      return "유효하지 않은 전화번호";
    case "eCT204":
      return "최대 이용자 수 초과";
    case "eCT303":
      return "이미 초대되어 있거나, 이용중인 회원입니다.";
    case "eCT501":
      return "정보를 찾을 수 없거나 삭제되었습니다.";
    case "eCT502":
      return "필수 매개변수가 전달되지 않았습니다.";
    case "eCT601":
      return "현재 이용자 초대를 할 수 없는 상태입니다. 계약진행 상태를 확인해 주세요.";
    default:
      return "유효하지 않은 전화번호";
  }
}

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 이용자 목록 > 이용자 초대 > 실패
 */
const UserInviteFail = () => {
  const location = useLocation();
  const { goBack } = useNavigateGoBack();

  // path variable 계약 id
  const { contractId } = useParams();

  const inviteFailResults: InviteResult[] = useMemo(() => {
    if (!location.state) return [];
    return ((location.state as { inviteResults: InviteResult[] })?.inviteResults || []).filter(
      (inviteResult: InviteResult) => inviteResult.type === "FAIL",
    );
  }, [location]);

  return (
    <>
      <MetaTag title="마이페이지" />
      <Header headerType="CLOSE" onClickCloseButton={() => goBack()} />
      <div className="user-invite-page">
        <h1 className="base-title">이용자 초대</h1>
        <article className="user-invite-fail">
          <div className="title-wrap">
            <h1>
              아래의 사유로 인해
              <br />
              초대에 실패했습니다.
            </h1>
          </div>
          <section>
            <div className="index-wrap">
              <h2>실패</h2> <span>{inviteFailResults.length}건</span>
            </div>
            {inviteFailResults.map((inviteFailResult: InviteResult, index: number) => (
              <div key={index} className="list-line" style={inviteFailResults.length - 1 === index ? { borderBottom: "0px" } : {}}>
                <p>{inviteFailResult.phoneNumber}</p>
                <p>{getErrorMessage(inviteFailResult?.failErrorCode)}</p>
              </div>
            ))}
          </section>
        </article>
      </div>
    </>
  );
};
export default UserInviteFail;
