import React from "react";
import { MediaFile } from "src/api/file/file-types";
import { Swiper, SwiperSlide } from "swiper/react";

type Props = {
  medias: MediaFile[];
};

const SwiperViewer = ({ medias }: Props) => {
  return (
    <section className={`slider list ${!medias || medias?.length === 0 ? "noImage" : ""}`}>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        parallax={true}
        className="detail-product-swiper"
      >
        {medias?.map((media: MediaFile) => {
          return (
            <SwiperSlide key={media.id}>
              <div
                className="slider-bg"
                style={{
                  backgroundImage: `url(${media.url})`,
                }}
                data-swiper-parallax={"23%"}
              ></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default SwiperViewer;
