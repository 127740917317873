import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getBuildingsDetailsAsync } from "src/api/building/building-api";
import { useApiOperation } from "src/api/hooks";
import { getNoticesAsync, getPublicNoticeListAsync } from "src/api/notice/notice-api";
import { Notice, NoticeListParams, noticeCategoryToText } from "src/api/notice/notice-types";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import usePostMessage from "src/pages/hooks/post-message";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import { useMemberInfoState } from "src/recoil/member/hook";
import qs from "qs";

/**
 * 공지사항 목록 Taap 웹뷰 화면
 */
type QueryParams = {
  page?: string;
};
const NoticeList = () => {
  const navigate = useNavigate();
  const { member } = useMemberInfoState();
  const { goBack } = useNavigateGoBack();
  // 공지사항 목록 조회 api
  const { executeAsync: getNotices } = useApiOperation(getNoticesAsync);
  const { executeAsync: getPublicNotices } = useApiOperation(getPublicNoticeListAsync, { noAuthenticationRequired: true });
  const { executeAsync: getBuildingsDetails } = useApiOperation(getBuildingsDetailsAsync, { noAuthenticationRequired: true });
  const { postMessageGoBackNavigation } = usePostMessage();

  // 공지사항 목록
  const [notices, setNotices] = useState<Array<Notice & { buildingName?: string }>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [delay, setDelay] = useState(false);
  // queryParam
  const location = useLocation();
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    return _queryParams;
  }, [location.search]);

  useEffect(() => {
    // member 상태가 변경되면 300ms 후에 QuotationDetail을 보이도록 설정
    const timeoutId = setTimeout(() => {
      setDelay(true);
    }, 500);

    return () => clearTimeout(timeoutId); // 컴포넌트 언마운트 시 clearTimeout을 통해 타이머 정리
  }, [member]);

  useEffect(() => {
    const now = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const noticeListParams: NoticeListParams = {
      isDisplayed: true, // 노출 여부
      size: 9999,
      lessThanEqualsDisplayStartDate: now,
      greaterThanDisplayEndDate: now,
      sort: {
        orders: [
          {
            property: "displayStartDate",
            direction: "DESC",
          },
          {
            property: "createdDate",
            direction: "DESC",
          },
        ],
      },
    };

    const fetchApi = async () => {
      setIsLoading(true);
      let originNotice;
      // 로그인 상태이지만 메인에서 퍼블릭 공지사항을 클릭했을 경우(쿼리파람 물고들어옴) => 퍼블릭 api
      if (member && queryParams.page === "public") {
        const { data, status } = await getPublicNotices(noticeListParams);
        originNotice = data?.data?.rows;
      } else if (member && !queryParams.page) {
        // 로그인 상태이지만 마이페이지에서 진입할 경우(쿼리파람 없음) => 인증 api
        const { data, status } = await getNotices(noticeListParams);
        originNotice = data?.data?.rows;
      } else {
        // 로그인 상태가 아닌경우 => 퍼블릭 api
        const { data, status } = await getPublicNotices(noticeListParams);
        originNotice = data?.data?.rows;
      }

      if (originNotice) {
        let buildingsIdList: number[] = [];
        originNotice.forEach((notice) => {
          if (notice?.buildings?.length > 0) {
            buildingsIdList.push(Number(notice.buildings[0].buildingId));
          }
        });
        if (buildingsIdList.length > 0) {
          const { data: buildingData } = await getBuildingsDetails({
            buildingIds: [...new Set(buildingsIdList)],
          });
          const buildings = buildingData?.data?.content;
          const newNotice = originNotice.map((notice) => {
            if (notice.buildings[0]) {
              const filteredBuilding = buildings?.filter((building) => String(building.building.id) === String(notice.buildings[0].buildingId));
              let buildingName = filteredBuilding[0].building.buildingName;
              return { ...notice, buildingName };
            } else return notice;
          });
          setNotices(newNotice || []);
        } else {
          setNotices(originNotice || []);
        }
      }

      setIsLoading(false);
    };
    fetchApi();
  }, [getNotices, getBuildingsDetails, member, getPublicNotices, delay, queryParams]);

  return (
    <div>
      <MetaTag title="공지사항 | TaapSpace" imgsrc={`${process.env.REACT_APP_S3_CONTENT_BASEURL}court/taapspace_logo.png`} />
      <Header
        headerType="BACK"
        title="공지사항"
        onClickBackButton={() => ((window as any).ReactNativeWebView ? postMessageGoBackNavigation() : goBack())}
      />
      {delay && (
        <>
          {notices.length > 0 && (
            <div className="page-notice-list">
              <section className="list-section">
                {notices?.map((notice: Notice & { buildingName?: string }, index: number) => {
                  return (
                    <div
                      key={index}
                      className="list-section__item"
                      onClick={() =>
                        queryParams.page === "public" ? navigate(`/court/notices/${notice.id}?page=public`) : navigate(`/court/notices/${notice.id}`)
                      }
                    >
                      <div className="title">
                        <p className="notice-title">{notice.title}</p>
                        {queryParams.page !== "public" && member && notice.readDate === null ? <div className="red-dot" /> : ""}
                      </div>
                      <p className="mt2 font13 text-primary3 ellipsis no-wrap lh-20">
                        <span className="text-primary3">{moment(notice?.createdDate).format("YYYY.MM.DD")}</span>
                        {/* 종류 */}
                        {notice?.noticeCategory && notice?.noticeCategory !== "NOTICE_CATEGORY_NONE" ? (
                          <>
                            <span className="mx8 text-primary5">|</span>
                            <span className="text-primary2">{noticeCategoryToText(notice?.noticeCategory)}</span>
                          </>
                        ) : (
                          ""
                        )}
                        {/* 건물명*/}
                        {notice?.buildingName ? (
                          <>
                            <span className="mx8 text-primary5">|</span>
                            <span className="text-primary2">{notice?.buildingName}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  );
                })}
              </section>
            </div>
          )}
          {!isLoading && notices.length <= 0 && (
            <div className="error-page with-header notice-page mt20 mx24 border-top">
              <section>
                {/* ic-question, ic-wifi, ic-setting, ic-none */}
                <div className="error-icon ic-none"></div>
                <div className="error-message-wrap">
                  <p>등록된 공지사항이 없습니다</p>
                </div>
              </section>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default NoticeList;
