import { useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import log from "src/utils/log";
import usePostMessage from "./post-message";

/**
 * 뒤로가기, 닫기 버튼 클릭시 네비게이션 뒤로가기 또는 웹뷰 화면일 경우 웹뷰를 닫기를 처리하는 hook
 * 최초에 접근한 location key (default) 화면으로 돌아왔을 경우, 뒤로가기시 웹뷰를 닫음
 */
export function useNavigateGoBack() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { postMessageGoBackNavigation } = usePostMessage();

  const goBack = useCallback(() => {
    log("goBack", location.key, searchParams.has("idToken"), window.history.length);

    const isReactNativeWebView = !!(window as any).ReactNativeWebView;

    if (
      isReactNativeWebView &&
      (location.key === "default" || searchParams.has("idToken") || window.history.length === 1 || window.history.state?.idx === 0)
    ) {
      postMessageGoBackNavigation();
    } else {
      // 히스토리 없을 시 > taapspace 메인으로 이동
      if (window.history.length === 2) {
        navigate("/court/main");
      } else {
        navigate(-1);
      }
    }
  }, [location]);

  return { goBack };
}
