import { useCallback } from "react";
import log from "src/utils/log";

type SelectBuildingData = {
  selectedContractManageId: string;
  selectedProductId: string;
  selectedBuildingId: string;
  selectedBuildingLabel: string;
};

type SelectedDeskData = {
  deskGroupId: string;
  deskId: string;
};

// postMessage type
export type PostMessageType =
  | "initializedSetProductBuildingList"
  | "initializedSetAvailableDeskGroups"
  | "initializedSetHancomAuthorizedQueryParams"
  | "hancomLogin"
  | "navigation"
  // | "callExternalBrowser"
  | "selectBuilding"
  | "selectedDesk"
  | "file"
  | "openInAppBrowser";

/**
 * ReactNativeWebView postMessage 를 관리하는 hook
 */
export default function usePostMessage() {
  // ReactNativeWebView 에 postMessage 전달
  const postMessage = useCallback((type: PostMessageType, data?: any) => {
    log("postMessage", type, data);
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type, data }));
    }
  }, []);

  /**
   * setProductBuildingList global function 이 (window.court.webViewFunction.setProductBuildingList)
   * 초기화 (재할당) 되었음을 알리는 post message
   */
  const postMessageInitializedSetProductBuildingList = useCallback(() => {
    postMessage("initializedSetProductBuildingList");
  }, [postMessage]);

  /**
   * setAvailableDeskGroups global function 이 (window.court.webViewFunction.setAvailableDeskGroups)
   * 초기화 (재할당) 되었음을 알리는 post message
   */
  const postMessageInitializedSetAvailableDeskGroups = useCallback(() => {
    postMessage("initializedSetAvailableDeskGroups");
  }, [postMessage]);

  /**
   * setHancomAuthorizedQueryParams global function 이 (window.court.webViewFunction.setHancomAuthorizedQueryParams)
   * 초기화 (재할당) 되었음을 알리는 post message
   */
  const postMessageInitializedSetHancomAuthorizedQueryParams = useCallback(() => {
    postMessage("initializedSetHancomAuthorizedQueryParams");
  }, [postMessage]);

  /**
   * “한컴 로그인” 버튼 클릭시 RN 으로 전달하는 post message
   */
  const postMessageHancomLogin = useCallback(
    (hancomClientId: string) => {
      postMessage("hancomLogin", {
        hancomClientId,
      });
    },
    [postMessage],
  );

  /**
   * 웹뷰 뒤로가기 버튼클릭시 RN 에 전달하는 post message
   */
  const postMessageGoBackNavigation = useCallback(() => {
    if ((window as any).ReactNativeWebView) {
      postMessage("navigation", {
        event: "goBack",
      });
    } else {
      window.close();
    }
  }, [postMessage]);

  /**
   * 웹뷰 공지사항 목록으로 이동하는 post message
   */
  const postMessageGoNoticeListScreen = useCallback(() => {
    if ((window as any).ReactNativeWebView) {
      postMessage("navigation", {
        event: "goNoticeListScreen",
      });
    } else {
      window.close();
    }
  }, [postMessage]);

  /**
   * RN의 홈으로 이동하는 post message
   */
  const postMessageGoHomeOrMy = useCallback(() => {
    if ((window as any).ReactNativeWebView) {
      postMessage("navigation", {
        event: "goHomeOrMy",
      });
    } else {
      window.close();
    }
  }, [postMessage]);

  /**
   * RN 의 인앱브라우저를 실행하는 함수를 호출하는 post message
   */
  // const postMessageCallExternalBrowser = useCallback(
  //   (href: string) => {
  //     postMessage("callExternalBrowser", { event: href });
  //   },
  //   [postMessage],
  // );

  /**
   * 공간이용안내 웹뷰화면에서 건물 변경시 RN 에 전달하는 post message
   */
  const postMessageSelectBuilding = useCallback(
    (selectBuildingData: SelectBuildingData) => {
      postMessage("selectBuilding", selectBuildingData);
    },
    [postMessage],
  );

  /**
   * 좌석배치도 웹뷰화면에서 선택버튼으로 좌석 선택시 RN 에 전달하는 post message
   */
  const postMessageSelectedDesk = useCallback(
    (selectedDeskData: SelectedDeskData) => {
      postMessage("selectedDesk", selectedDeskData);
    },
    [postMessage],
  );

  /**
   * 파일 다운로드 post message
   */
  const postMessageFileDownload = useCallback(
    (fileUrl: string, fileName: string) => {
      postMessage("file", {
        event: "download",
        url: fileUrl,
        fileName: fileName,
      });
    },
    [postMessage],
  );

  /**
   * 파일 다운로드 post message
   */
    const postMessageBase64FileDownload = useCallback(
      (base64Text: string, fileName: string) => {
        postMessage("file", {
          event: "base64Download",
          base64Text: base64Text,
          fileName: fileName,
        });
      },
      [postMessage],
    );

  /**
   * 신청 계약 상세에서 결제 인앱 브라우저 처리
   */
  const postWebViewPaymentMessage = useCallback(
    (url: string) => {
      postMessage("openInAppBrowser", {
        event: url,
      });
    },
    [postMessage],
  );

  return {
    postMessageInitializedSetProductBuildingList,
    postMessageInitializedSetAvailableDeskGroups,
    postMessageInitializedSetHancomAuthorizedQueryParams,
    postMessageHancomLogin,
    postMessageGoBackNavigation,
    postMessageSelectBuilding,
    postMessageSelectedDesk,
    postMessageFileDownload,
    postMessageBase64FileDownload,
    postMessageGoNoticeListScreen,
    postMessageGoHomeOrMy,
    postWebViewPaymentMessage,
  };
}
