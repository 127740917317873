import React from "react";
import { BaseButton } from "src/components";
import BaseAbstractModal from "src/components/BaseAbstractModal";

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

function TermsOfUseModal(props: Props) {
  return (
    <BaseAbstractModal isOpen={props.isOpen}>
      <div className="terms-of-use-modal">
        <section className="terms-of-use-modal__title">
          <p>개인정보 이용약관</p>
        </section>
        <section className="terms-of-use-modal__contents">
          <p className="pre-line keep-all">
            {`개인정보처리방침

‘핀포인트(주)’는(이하 ‘회사’) 「개인정보 보호법」 제30조에 따라 정보주체(이하 ‘이용자’)의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.

○ 이 개인정보처리방침은 2023년 5월 1일부터 적용됩니다.


제1조(개인정보의 처리 목적)

회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

1. 회원가입 및 관리
회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.

2. 재화 또는 서비스 제공
물품 배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령 인증, 요금 결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.

3. 마케팅 및 광고에의 활용
신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여 기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.

4. 서비스 이용 및 광고 관련 문의
회사가 제공하는 서비스 관련 문의, 회사가 운영하는 사이트에 공간상품 게시 문의 대응을 목적으로 개인정보를 처리합니다.

5. 개인영상정보
범죄의 예방 및 수사, 시설안전 및 화재예방, 교통단속, 교통정보의 수집·분석 및 제공 등을 목적으로 개인정보를 처리합니다.



제2조(개인정보의 처리 및 보유 기간)

① 회사는 법령에 따른 개인정보 보유·이용 기간 또는 이용자로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용 기간 내에서 개인정보를 처리·보유합니다.

② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.

1. 회원가입 및 관리 : 서비스 탈퇴 시 즉시 파기 또는 법령에 따른 보존 기간까지
다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
1) 관계 법령 위반에 따른 수사∙조사 등이 진행 중인 경우, 해당 수사∙조사 종료 시까지
2) 서비스 이용에 따른 채권∙채무관계 잔존 시, 해당 채권∙채무관계 정산 시까지
3) 회원이 1년간 서비스 이용 기록이 없는 경우, 개인정보 보호법 제39조의 6(개인정보의 파기에 대한 특례)에 따라 회원에게 사전 통지하고 즉시 파기합니다.

2. 재화 또는 서비스 제공 : 서비스 공급 완료 및 요금 결제∙정산 완료 시
다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
1) 「전자상거래 등에서의 소비자보호에 관한 법률」에 따른 표시∙광고, 계약 내용 및 이행 등 거래에 관한 기록
표시 및 광고에 관한 기록 : 6개월
계약 또는 청약철회 등에 관한 기록 : 5년
대금 결제 및 재화 등의 공급에 관한 기록 : 5년
소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
2) 「신용 정보의 이용 및 보호에 관한 법률」에 따른 신용 정보에 관한 기록
신용 정보의 수집∙처리 및 이용 등에 관한 기록 : 3년

3. 마케팅 및 광고에의 활용 : 이벤트 종료 후 3개월간 보관
다만, 이용자가 철회 요청하는 경우 지체 없이 해당 개인정보를 파기함

4. 서비스 이용 및 광고 관련 문의 : 문의 후 3개월간 보관
다만, 이용자가 철회 요청하는 경우 지체 없이 해당 개인정보를 파기함


제3조(처리하는 개인정보의 항목)

① 회사는 다음의 개인정보 항목을 처리하고 있습니다.

- 구분 : 회원가입
- 개인정보 항목 : [필수] 휴대전화 번호, 이메일 주소
- 수집 및 이용 목적 : 이용자 식별, 고지사항 전달

- 구분 : 방문자 등록
- 개인정보 항목 : [필수] 휴대전화 번호
- 수집 및 이용 목적 : 이용자 식별, 출입 QR 코드 전송

- 구분 : 상품 등록/이용신청/계약/해지
- 개인정보 항목 : [필수] 계약자 : 성명, 이메일 주소, 휴대전화 번호 / 법인사업자 : 대표자 성명, 연락처, 마스킹 된 증빙서류(대표이사 신분증 사본) / 법인사업자 대리인 : 마스킹 된 증빙서류(대리인 위임장, 대리인 신분증 사본) / 개인사업자 : 대표자 성명, 연락처, 마스킹 된 증빙서류(신분증 사본) / 개인사업자 대리인 : 대표자 성명, 마스킹 된 증빙서류(위임장, 인감증명서, 대리인 신분증 사본)
- 수집 및 이용 목적 : 등록/신청/계약/해지 고객의 확인

- 구분 : 결제
- 개인정보 항목 : [필수] 신용카드 결제 시 : 카드번호(일부), 카드사명 등 / 휴대전화 번호 결제 시 : 휴대전화 번호, 결제승인번호 등 / 계좌이체, 계좌조회, 계좌점유 인증 시 : 예금주명, 계좌번호, 계좌은행 등 / 세금계산서 발행 시 : 이메일, 사업자등록증 등
- 수집 및 이용 목적 : 결제 진행, 수납 확인

② 대금 결제 시 수집하는 개인정보는 결제대행업체(PG사)에서 수집 및 저장하고 있으며, 회사는 결제대행업체에서 제공하는 거래내역만을 제공받고 있습니다.
③ 만 14세 미만 아동의 개인정보를 수집할 때 법정 대리인의 동의를 얻어 최소한의 개인정보를 수집하고 있습니다. 아동에게 법정대리인의 성명, 연락처와 같이 최소한의 정보를 요구할 수 있으며, 아래 방법으로 법정대리인의 동의를 확인합니다.
1. 법정대리인의 휴대전화 본인인증을 통해 본인여부를 확인하는 방법
2. 법정대리인에게 동의내용이 적힌 서면을 제공하여 서명날인 후 제출하게 하는 방법
3. 그 밖에 위와 준하는 방법으로 법정대리인에게 동의내용을 알리고 동의의 의사표시를 확인하는 방법


제4조(개인정보의 제3자 제공에 관한 사항)

① 회사는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 이용자의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.

② 회사는 다음의 경우에는 개인정보를 제3자에게 제공할 수 있습니다.
1. 서비스 제공에 따른 요금 정산을 위하여 필요한 경우
2. 회사가 제공하는 서비스를 통하여 거래가 성사된 경우, 거래 당사자 간의 원활한 의사소통을 위하여 관련된 정보를 필요한 범위 내에서 거래 당사자에게 제공하는 경우
3. 통계 작성, 학술연구나 시장조사를 위해 특정 개인을 식별할 수 없는 형태로 가공하여 제공하는 경우
4. 이용자들이 사전에 동의한 경우
5. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우


제5조(개인정보처리의 위탁에 관한 사항)

① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리 업무를 위탁하고 있습니다.

- 수탁업체 : Amazon Web Service Inc.
- 위탁업무 내용 : 데이터 보관

- 수탁업체 : Google Cloud Korea LLC
- 위탁업무 내용 : 데이터 보관

- 수탁업체 : 페이레터 주식회사
- 위탁업무 내용 : 서비스 결제

- 수탁업체 : ADT 캡스
- 위탁업무 내용 : CCTV 설치 및 보수, 영상 모니터링, 화재 및 방법 관리

② 회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행 목적 외 개인정보 처리 금지, 기술적․관리적 보호 조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.

③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.


제6조(개인정보의 국외 이전에 관한 사항)

① 개인정보보호법 제39조의 12(국외 이전 개인정보보호) 제2항에 따라 그룹은 효율적인 업무 처리를 위해 수집된 개인정보의 일부를 국외법인에서 보관 및 처리하도록 위탁할 수 있습니다. 이전되는 개인정보는 목적 외로 사용하거나 제공되지 않습니다.

- 개인정보를 이전 받는 자 (‘수탁업체’) : Amazon Web Service Inc.
- 관련 서비스 : ‘Ctrl. Room’, ‘Ctrl. Room Central’
- 위탁하는 개인정보 항목 : 성명, 이메일 주소, 프로필 사진
- 수탁업체의 위치 : Level 37, 2/26 Park St, Sydney NSW 2000 Australia
- 개인정보 이전 일시 및 이전 방법 : 온라인상으로 수시로 전송되어 수탁업체가 소재하는 국가의 지역 Server에 저장됨
- 정보관리 책임자 연락처 : aws-korea-privacy@amazon.com
- 개인정보 이용 목적 및 위탁업무 내용 : 효율적인 업무 수행을 위한 IT 인프라 통합
- 개인정보 보유∙이용 기간 : 본 개인정보처리방침 제2조에 규정된 보유∙이용 기간과 일치함

제7조(개인정보의 파기절차 및 파기방법)

① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 개인정보를 파기합니다.

② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)에 옮기거나 보관 장소를 달리하여 보존합니다.

③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1. 파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.

2. 파기방법 : 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.


제8조(미 이용자의 개인정보 파기 등에 관한 조치)

① 회사는 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리 보관된 개인정보는 1년간 보관 후 지체 없이 파기합니다.
② 회사는 휴면 전환 30일 전까지 휴면 예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.
③ 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스 로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.



제8조(미 이용자의 개인정보 파기 등에 관한 조치)

① 회사는 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리 보관된 개인정보는 1년간 보관 후 지체 없이 파기합니다.
② 회사는 휴면 전환 30일 전까지 휴면 예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.
③ 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스 로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.


제9조(이용자와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)

① 이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.

② 제1항에 따른 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.

③ 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.

④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 이용자의 권리가 제한될 수 있습니다.

⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.

⑥ 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.


제10조(개인정보의 안전성 확보 조치에 관한 사항)

회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.

1. 정기적인 자체 감사 실시
개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.

2. 개인정보 취급 직원의 최소화 및 교육
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.

3. 내부관리 계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리 계획을 수립하고 시행하고 있습니다.

4. 해킹 등에 대비한 기술적 대책
회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.

5. 개인정보의 암호화
이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.

6. 접속기록의 보관 및 위변조 방지
개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있습니다. 다만, 5만 명 이상의 이용자에 관하여 개인정보를 추가하거나, 고유식별 정보 또는 민감정보를 처리하는 경우에는 2년 이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안 기능을 사용하고 있습니다.

7. 개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스 시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.

8. 문서보안을 위한 잠금장치 사용
개인정보가 포함된 서류, 보조 저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.

9. 비인가자에 대한 출입 통제
개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.


제11조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)

① 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.
다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.


제12조 (개인정보 보호책임자에 관한 사항)

① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만 처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

▶ 개인정보 보호책임자
성명 : 최봉산
부서 : 핀포인트 기업부설연구소
연락처 : 02-3442-6610
이메일 : contact@ppoint.kr
※ 개인정보 보호 담당 부서로 연결됩니다.

② 이용자께서는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구 및 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만 처리, 피해 구제 등에 관한 사항을 개인정보 보호책임자 및 담당 부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해 드릴 것입니다.


제13조(이용자의 권익 침해에 대한 구제방법)

이용자는 개인정보 침해로 인한 구제를 받기 위하여 개인정보분쟁 조정위원회, 한국 인터넷진흥원 개인정보침해 신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.

1. 개인정보분쟁 조정위원회 : (국번 없이) 1833-6972 (www.kopico.go.kr)
2. 개인정보침해 신고센터 : (국번 없이) 118 (privacy.kisa.or.kr)
3. 대검찰청 : (국번 없이) 1301 (www.spo.go.kr)
4. 경찰청 : (국번 없이) 182 (ecrm.cyber.go.kr)

「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.

※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.

제14조(영상정보처리기기 운영 및 관리에 관한 사항)

① 회사는 아래와 같이 영상정보처리기기를 ADT캡스에 위탁하여 설치 및 운영하고 있습니다. 

1. 설치 근거 및 목적 : 개인정보보호법 제25조 및 제26조에 따라 시설 안전 및 화재예방, 이용자의 안전을 위한 범죄 예방

2. 설치 대수, 설치 위치 및 촬영 범위
- 설치 대수 : 15대
- 설치 위치 및 촬영 범위 : 공용공간, 출입문

3. 영상정보처리기기 설치 및 관리 등의 위탁에 관한 사항
- 수탁업체 : ADT 캡스
- 담당자 : 운영 관리자
- 연락처 : 02) 3442-6610

4. 영상정보의 촬영시간, 보관기간, 보관장소 및 처리방법
- 촬영시간 : 24시간
- 보관기간 : 촬영일로부터 30일
- 보관장소 : 네트워크/서버 보관실
- 처리방법 : 개인영상정보의 목적 외 이용, 제3자 제공, 파기, 열람 등 요구에 관한 사항을 기록․관리하고, 보관기간 만료 시 복원이 불가능한 방법으로 영구 삭제(출력물의 경우 파쇄 또는 소각)합니다.

5. 개인영상정보의 확인 방법 및 장소에 관한 사항
수탁업체 담당자에게 연락하고 방문하시면 루피움 빌딩 네트워크/서버 보관실에서 확인 가능 합니다.

6. 정보주체의 영상정보 열람 등 요구에 대한 조치
개인영상정보 열람.존재확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명.신체.재산 이익을 위해 필요한 경우에 한해 열람을 허용합니다.

7. 영상정보의 안전성 확보조치
회사는 개인영상정보가 분실․도난․유출․변조 또는 훼손되지 아니하도록 수탁자인 ADT캡스를 교육하고, 수탁자인 ADT캡스가 개인영상정보를 안전하게 처리하는지를 감독합니다.

제15조(개인정보 처리방침 변경)

① 이 개인정보처리방침은 2023년 5월 1일부터 적용됩니다.

    `}
          </p>
        </section>
        <section className="terms-of-use-modal__btn-wrap">
          <div className="minmax100">
            <BaseButton className="base-btn size-small" title={"확인"} onClick={props.onClick} />
          </div>
        </section>
      </div>
    </BaseAbstractModal>
  );
}

export default TermsOfUseModal;
