import moment from "moment";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { patchMemberContractManageDeleteAsync } from "src/api/contract/contract-api";
import { MemberContractManageModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { ConfirmModal } from "src/components";
import { convertKoreanPhoneNumber } from "src/utils/common-util";

type Props = {
  contractManage: MemberContractManageModel;
  fetchContract: (contractId: string) => void;
  setSearchText: (searchText: string) => void;
};

type Modal = {
  isOpen: boolean;
  message?: string;
};

const UserItem = ({ contractManage, fetchContract, setSearchText }: Props) => {
  // path variable 계약 id
  const { contractId } = useParams();

  // 이용자 삭제 api
  const { executeAsync: patchMemberContractManageDelete } = useApiOperation(patchMemberContractManageDeleteAsync, { noHandleError: true });

  // 확인 버튼만 있는 알림 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 확인 취소 버튼 있는 확인 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 이용자 삭제
  const deleteContractManage = useCallback(async () => {
    try {
      if (!contractId) throw Error("contractId is not found");

      const { data } = await patchMemberContractManageDelete({
        contractManageId: Number(contractManage.contractManageId),
        contractMemberId: Number(contractManage.contractMemberId),
        isDeleted: true,
      });

      if (data?.data?.contractMemberId) {
        // 검색어 초기화
        setSearchText("");
        setAlertModal({ isOpen: true, message: "삭제되었습니다." });
      }
    } catch (error: any) {
      console.error("deleteContractManage error", error);

      let errorMessage = "삭제를 실패했습니다.";
      if (error?.response?.data?.meta?.errorCode === "eCT607") {
        // 계약자 삭제
        errorMessage = "계약자는 삭제가 불가능합니다.";
      }

      setAlertModal({ isOpen: true, message: errorMessage });
    }
  }, [patchMemberContractManageDelete, contractId, contractManage]);

  // 확인 모달 확인 버튼 클릭
  const clickModalConfirm = useCallback(() => {
    setConfirmModal({ isOpen: false });

    deleteContractManage();
  }, [deleteContractManage]);

  // 알림 모달 확인 버튼 클릭
  const clickAlertModalConfirm = useCallback(async () => {
    if (!contractId) throw Error("contractId is not found");

    await fetchContract(contractId);
    setAlertModal({ isOpen: false });
  }, [contractId]);

  return (
    <div className="contents-card">
      <div className="contents-card__main-area">
        <div className="left-section">
          <p>{convertKoreanPhoneNumber(contractManage.inviteMobileNumber)}</p>
          <p>{contractManage.email || (contractManage.memberStatus === "MEMBER_INVITE" ? "초대완료 (미가입)" : "미가입 (만료)")}</p>
        </div>
        <div className="right-section">
          <div>
            <p>{`${moment(contractManage.inviteDate).format("YYYY-MM-DD H:mm")}`}</p>
          </div>
          {contractManage.memberStatus !== "MEMBER_DELETE" && contractManage.memberStatus !== "MEMBER_CLOSE" && (
            <div>
              {contractManage.isAdmin || contractManage.isContractor ? (
                <div className="base-chip">
                  <span>마스터</span>
                </div>
              ) : (
                <div>
                  {contractManage.memberStatus === "MEMBER_EXPIRE" && (
                    <button
                      type="button"
                      data-invite-mobile-number={contractManage.inviteMobileNumber}
                      data-contract-member-id={contractManage.contractMemberId}
                      className="only-text-btn pr10 btn-reinvite"
                      style={{ borderRight: "1px solid #999999" }}
                    >
                      재초대
                    </button>
                  )}
                  <button
                    type="button"
                    className="only-text-btn pl10 btn-delete"
                    onClick={() => {
                      setConfirmModal({ isOpen: true });
                    }}
                  >
                    삭제
                  </button>
                  <ConfirmModal
                    isOpen={confirmModal.isOpen}
                    btnLeftTitle={"취소"}
                    btnRightTitle={"확인"}
                    onClose={() => setConfirmModal({ isOpen: false })}
                    onClick={() => clickModalConfirm()}
                  >
                    <div>
                      <p className="font18 font-weight-600">삭제하시겠어요?</p>
                      <p className="font15 font-weight-400 mt20">삭제 후 즉시 해당 이용자는</p>
                      <p className="font15 font-weight-400 mt5">더 이상 공간에 출입이 불가합니다.</p>
                    </div>
                  </ConfirmModal>
                  <ConfirmModal isOpen={alertModal.isOpen} btnRightTitle={"확인"} onClick={() => clickAlertModalConfirm()}>
                    <div>
                      <p className="font18 font-weight-600">{alertModal?.message}</p>
                    </div>
                  </ConfirmModal>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {(contractManage.memberStatus === "MEMBER_CLOSE" || contractManage.memberStatus === "MEMBER_DELETE") && (
        <div className="contents-card__info-area">
          <p>
            관리자에 의해 권한이 일시 정지되었습니다.
            <br />
            자세한 사항은 CS 센터로 문의 바랍니다.
          </p>
          <button type="button" className="icon-right-btn">
            문의하기
          </button>
        </div>
      )}
    </div>
  );
};
export default UserItem;
