import React from "react";
import { CommonFacilityModel } from "src/api/building/building-types";
import { ScheduleData } from "src/api/reservation/reservation-type";
import BaseShareButton from "src/components/BaseShareButton";
import { numberToStringWithComma } from "src/utils/common-util";

type Props = {
  minimumPrice: ScheduleData;
  facility: CommonFacilityModel | undefined;
};

const FacilityUsageInfo = ({ minimumPrice, facility }: Props) => {
  return (
    <section className="facility-info__main bottom-divider">
      <div className="main-wrap">
        <h2 className="main-title">{facility?.facilityName}</h2>
        <BaseShareButton />
      </div>
      <div className="sub-wrap">
        <div className="price-info">
          <p className="price">
            <span>{(minimumPrice && numberToStringWithComma(minimumPrice.price)) ?? "-"}</span> 원~
          </p>
          <p className="info"> /30분 (부가세 별도)</p>
        </div>
        <p className="description">시간과 날짜에 따라 금액 상이</p>
      </div>
      <div className="additional-info">
        <div className="info-wrap">
          <div className="info-wrap__index">
            <p>정원</p>
          </div>
          <div className="info-wrap__value">
            <p>{facility?.commonFacilityType === "DESK" ? "1" : facility?.facilityMaxPeopleNums} 명</p>
          </div>
        </div>
        <div className="info-wrap">
          <div className="info-wrap__index">
            <p>층정보</p>
          </div>
          <div className="info-wrap__value">
            <p>{`${!facility?.isGround ? "B" : ""}${facility?.floorNum} 층`}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FacilityUsageInfo;
