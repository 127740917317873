import { BuildingModel } from "src/api/building/building-types";
import { MediaFile } from "src/api/file/file-types";
import SwiperViewer from "src/pages/components/SwiperViewer";

type Props = {
  building: BuildingModel & { medias?: MediaFile[] };
  fullInfo?: boolean;
};

const BuildingPostCard = ({ building }: Props) => {
  return (
    <section className="facility-info__facility-info top-divider">
      <h2>건물 정보</h2>
      <SwiperViewer medias={building?.medias || []} />

      <div className="info-line ">
        <div>
          <h2 className="mb4">{building?.buildingName}</h2>
          <p className="font13 text-primary3">{building.feature}</p>
        </div>
      </div>
    </section>
  );
};

export default BuildingPostCard;
