import { Address, HeatingType, MetaData } from "../public-types";
import { MediaFile } from "src/api/file/file-types";

// 건물 공개여부
export type BuildingStatus = "ENABLED" | "DISABLED";

// 냉난방장비타입
export type HeatingFacilityType =
  | "HEATINGFACILITY_UNRECOGNIZED" // 정의되지 않은 타입
  | "CEILING_AIR_CONDITIONER" // 냉난방기(천장형)
  | "STAND_UP_AIR_CONDITIONER" // 냉난방기(거치형)
  | "DIRECT_FACILITY_AIR_CONDITIONER"; // 직접입력

// 건물 프로퍼티 삭제 유형
export type DeleteType =
  | "DELETE_BUILDING_FLOOR" // 건물 층 삭제
  | "DELETE_BUILDING_ROOM"; // 건물 호실 삭제

// 공용시설타입
export type CommonFacilityType =
  | "COMMONFACILITY_UNRECOGNIZED" // 정의되지 않은 타입
  | "MEETING_ROOM" // 회의실
  | "DESK" // 좌석
  | "REFRESH_ROOM"; // 휴게공간

// 공간유형
export type SpaceStyle = "SPACE_STYLE_UNRECOGNIZED" | "ROOM" | "CONTAINER";

/* 
  건물
*/
export interface BuildingModel {
  id?: number;
  buildingName?: string; // 건물이름
  locationCode?: string; // Location Code
  description?: string; // 설명
  building?: BuildingModel;
  feature?: string; // 지리적특징
  floorNums?: number; // 지상층수
  undergroundFloorNums?: number; // 지하층수
  completionYmd?: string; // 사용승인일
  remodelingYmd?: string; // 리모델링 년도
  buildingStructureTypeList?: Array<MetaData>; // 건축물 용도
  entryStartTime?: string; // 출입시작시간
  entryEndTime?: string; // 출입종료시간
  isHoliday?: boolean; // 공휴일여부
  leasableArea?: number; // 임대면적 (m2)
  leasableAreaNet?: number; // 전용면적 (m2)
  exitDirection?: string; // 주출입구방향
  buildingToiletList?: Array<MetaData>; // 화장실
  passengerElev?: number; // 승객엘리베이터
  freightElev?: number; // 화물엘리베이터
  heatingType?: HeatingType; // 냉난방타입
  heatingTypeDesc?: string; // 냉난방타입설명
  isHeating?: boolean; // 냉난방장비타입
  heatingFacilityType?: HeatingFacilityType; // 냉난방장비타입
  heatingFacilityTypeDesc?: string; // 냉난방장비타입설명
  heatingFacilityTypeAddDesc?: string; // 냉난방방식추가설명
  addressList?: Array<Address>; // 다음 지도에서 돌려받은 주소정보
  status?: BuildingStatus;
  isDeleted?: boolean;
  createdBy?: string;
  createdDate?: string;
  modifiedBy?: string;
  modifiedDate?: string;
  buildingCommonFacility?: BuildingCommonFacility; // 건물 공용공간 주차장/회의실/좌석/휴게공간
  buildingFloorList?: Array<BuildingFloorModel>;
}

// 건물 & 이미지 & 주차장 정보 포함된 타입
export type TaapSpaceBuildingModel = BuildingModel & { medias?: MediaFile[]; parkingLot?: BuildingParkingLotModel };

/*
  건물 프로퍼티 층
 */
export interface BuildingFloorModel {
  id?: number; // 건물_층_ID
  floorNum?: number; // 층
  floorName?: string; // 층 명칭
  buildingRoomList?: Array<BuildingRoomModel>; // 호실
}

/* 
  건물 프로퍼티 층의 호실
*/
export interface BuildingRoomModel {
  id?: number; // 건물_층_호실_ID
  roomNum?: number; // 호실
  roomName?: string; // 호실 명칭
  leasableAreaNet?: string; // 전용면적 ( ㎡ 기준 )
  leasableArea?: string; // 임대면적
  rent?: number; // 임대료
  isPrimary?: boolean;
}

/*
  건물 주차장
 */
export interface BuildingParkingLotModel {
  id?: number; // 건물 주차장 ID
  buildingId?: number; // 건물_ID
  selfPropelledParkingLotNums?: number; // 자주식주차장수
  selfPropelledParkingLotTotalNums?: number; // 자주식주차장대수
  mechanicalParkingLotNums?: number; // 기계식주차장수
  mechanicalParkingLotTotalNums?: number; // 기계식주차장대수
  description?: string; // 설명
}

/* 
  건물 공용공간 주차장/회의실/좌석/휴게공간
*/
export interface BuildingCommonFacility {
  parkingLotList?: Array<BuildingParkingLotModel>; // 건물 공용공간 주차장
  meetingRoomList?: Array<CommonFacilityModel>; // 건물 공용공간 회의실
  deskSpace?: {
    deskGroupList?: Array<BuildingCommonFacilityDeskGroupModel>; // 건물 공용공간 좌석
    mediaList?: Array<MediaFile>; // 좌석 배치도 이미지
  };
  refreshRoomList?: Array<CommonFacilityModel>; // 건물 공용공간 휴게공간
}

/*
  건물 회의실/좌석/휴게공간
 */
export interface CommonFacilityModel {
  id?: number; // 건물_공용시설_ID
  buildingId?: number; // 건물_ID
  commonFacilityType?: CommonFacilityType; // 공용시설타입
  isGround?: boolean; // 지상여부
  floorNum?: number; // 층
  facilityName?: string; // 시설명
  facilityList?: Array<string>; // 시설 목록 ( 회의실, 휴게공간 )
  facilityMaxPeopleNums?: number; // 시설정원(수)
  eslAddress?: string; // ESL 매핑주소 (ESL 라벨 아이디)
  locationCode?: string; // Location Code
  description?: string; // 설명
  spaceStyle?: SpaceStyle; // 공간유형
  mediaList?: Array<MediaFile>; // 시설 이미지
  centerX?: string; // 좌석배치도 X 값
  centerY?: string; // 좌석배치도 Y 값
}

/* 
  건물 공용공간 좌석 그룹
*/
export interface BuildingCommonFacilityDeskGroupModel {
  id?: number; // 그룹 ID
  groupName?: string; // 그룹명
  isGround?: boolean; // 지상여부
  floorNum?: number; // 층
  description?: string; // 설명
  deskChartDescription?: string; // 좌석배치도 설명
  buildingId?: number; // 건물 ID
  deskList?: Array<CommonFacilityModel>; // 좌석 목록 ( 좌석 )
  mediaList?: Array<MediaFile>;
}

/**
 * 건물 공용공간 상세 response data
 */
export interface BuildingCommonFacilityDetailResponseData {
  content?: CommonFacilityModel;
}

/* 
  건물 공용공간 좌석 그룹
*/
export interface DeskGroupModel {
  id: string; // 좌석 그룹 ID
  groupName?: string; // 좌석 그룹명
  isGround?: boolean; // 지상여부
  floorNum?: number; // 층
  description?: string; // 좌석 그룹 설명
  deskChartDescription?: string; // 좌석배치도 설명
  deskList?: Array<CommonFacilityModel>; // 좌석 목록
  mediaList: Array<MediaFile>; // 좌석배치도 이미지
}

// select box 공통 option
export type SelectOption = {
  value: string;
  label: string;
  index?: number;
  subOptions?: Array<SelectOption>;
};

// 냉난방 방식 장비
export const heatingFacilityTypeOptions: Array<SelectOption> = [
  { value: "CEILING_AIR_CONDITIONER", label: "냉난방기(천장형)" },
  { value: "STAND_UP_AIR_CONDITIONER", label: "냉난방기(거치형)" },
  { value: "DIRECT_FACILITY_AIR_CONDITIONER", label: "직접입력" },
];

// 냉난방 방식 타입
export const heatingTypeOptions: Array<SelectOption> = [
  { value: "CENTRAL_AIR_CONDITIONER", label: "중앙냉난방" },
  { value: "INDIVIDUAL_AIR_CONDITIONER", label: "개별냉난방" },
  { value: "DIRECT_HEATING_AIR_CONDITIONER", label: "직접입력" },
];

// 주 출입구 방향
export const exitDirectionOptions: Array<SelectOption> = [
  { value: "PRODUCT_DIRECTION_E", label: "동" },
  { value: "PRODUCT_DIRECTION_W", label: "서" },
  { value: "PRODUCT_DIRECTION_S", label: "남" },
  { value: "PRODUCT_DIRECTION_N", label: "북" },
  { value: "PRODUCT_DIRECTION_SE", label: "남동" },
  { value: "PRODUCT_DIRECTION_SW", label: "남서" },
  { value: "PRODUCT_DIRECTION_NE", label: "북동" },
  { value: "PRODUCT_DIRECTION_NW", label: "북서" },
];
