const SystemCheck = () => {
  return (
    <div className="error-page">
      <section>
        <div className="error-icon ic-setting"></div>
        <div className="error-message-wrap">
          <h2>시스템 점검중</h2>
          <p>
            불편을 드려서 죄송해요
            <br />
            점심 시간 후 접속해 주시겠어요?
          </p>
        </div>
        <div className="error-message-wrap">
          <p>
            <b>점검시간</b>
          </p>
          <p>4월 30일 15:00 ~ 4월 30일 18:00</p>
        </div>
      </section>
    </div>
  );
};

export default SystemCheck;
