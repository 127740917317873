import { AxiosInstance, AxiosResponse } from "axios";
import { QuestionModel, QuestionParams } from "./question-type";
import { ApiResponse } from "../public-types";
import qs from "qs";

// TEQ02. (인증) 상담관리 등록
export async function postQuestionAsync(
  axios: AxiosInstance,
  params?: QuestionParams, //
): Promise<AxiosResponse<ApiResponse<{ content: QuestionModel }>>> {
  const path = `/api/court/et/question`;
  return await axios.post(path, {
    question: params,
    paramsSerializer: (params: QuestionParams) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

// TEQ03. (무인증) 상담관리 등록
export async function postPublicQuestionAsync(
  axios: AxiosInstance,
  params?: QuestionParams & { token: string }, //
): Promise<AxiosResponse<ApiResponse<{ content: QuestionModel }>>> {
  if (!params?.token) {
    throw Error("token is not found");
  }
  const { token, ...payload } = params;
  const path = `/api/court/et/public/question`;
  return await axios.post(
    path,
    {
      question: payload,
    },
    { headers: { "X-Recaptcha-Token": params!.token } },
  );
}
