import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBuildingsDetailsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { getGuidePublicListAsync } from "src/api/guide/guide-api";
import { ServiceUserGuide } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useHeaderOpacity } from "src/pages/hooks/header-opacity";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import EtcGuide from "./components/EtcGuide";
import NetworkGuide from "./components/NetworkGuide";

/**
 * (무인증) 상품 이용안내 상세 > 상세 화면 (ctrl.room > 상품 > 이용안내 > taapSpace 상세)
 */
type QueryParams = {
  buildingId?: string;
};
const PublicProductGuideDetail = () => {
  const initializedProductGuide = useRef(false);
  const initializedBuilding = useRef(false);
  const location = useLocation();

  const { goBack } = useNavigateGoBack();

  // path variable 공간상품 id
  const { productId, guideId } = useParams();
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    return _queryParams;
  }, [location.search]);

  const { executeAsync: getGuideList } = useApiOperation(getGuidePublicListAsync, { noAuthenticationRequired: true, noHandleError: true });
  const { executeAsync: getBuildingsDetails } = useApiOperation(getBuildingsDetailsAsync, { noAuthenticationRequired: true, noHandleError: true });

  // 이용안내 상세
  const [guide, setGuide] = useState<ServiceUserGuide>({});

  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  const pageContainer = useRef<HTMLDivElement>(null);
  const startPoint = useRef<HTMLDivElement>(null);

  // 헤더 오퍼시티 적용
  const { opacityCount } = useHeaderOpacity({ pageContainer, startPoint });

  // 이용안내 상세조회
  const fetchPublicProductGuide = useCallback(
    async (productId: string, guideId: string) => {
      const { data } = await getGuideList({ serviceId: productId, serviceType: "SERVICE_PRODUCT", userGuideId: guideId });
      // setProductGuide(data?.data?.content);
      setGuide(data.data.content[0]);
      if (!initializedProductGuide.current) {
        initializedProductGuide.current = true;
      }
    },
    [getGuideList],
  );

  // 건물 상세정보 조회
  const fetchBuildingsDetail = useCallback(
    async (buildingId: string | number) => {
      const { data } = await getBuildingsDetails({
        buildingIds: [buildingId],
      });
      if (data?.data?.content && (data?.data?.content || []).length > 0) {
        setBuilding((data?.data?.content || [])[0]?.building);
      }
      if (!initializedBuilding.current) {
        initializedBuilding.current = true;
      }
    },
    [getBuildingsDetails],
  );

  useEffect(() => {
    if (productId && guideId) {
      // 공간상품 사용안내 상세조회
      fetchPublicProductGuide(productId, guideId);
    }
  }, [productId, guideId, fetchPublicProductGuide]);

  useEffect(() => {
    if (queryParams.buildingId) {
      // 건물 상세정보 조회
      fetchBuildingsDetail(queryParams.buildingId);
    }
  }, [fetchBuildingsDetail, queryParams.buildingId]);

  return (
    <div>
      <MetaTag title="이용안내" />
      {initializedProductGuide.current && initializedBuilding.current && (
        <>
          <Header headerType="BACK" title={guide?.userGuide?.subject} opacityCount={opacityCount} onClickCloseButton={() => goBack()} />
          <div className="space-info detail" ref={pageContainer}>
            <div className={`title-wrap`} ref={startPoint}>
              <h2>{guide?.userGuide?.subject}</h2>
              <p>{building?.buildingName}</p>
            </div>
            <section className="contents__section">
              {guide.userGuide?.type === "USER_GUIDE_WIFI" ? <NetworkGuide userGuide={guide.userGuide} /> : <EtcGuide userGuide={guide.userGuide} />}
            </section>
          </div>
        </>
      )}
    </div>
  );
};
export default PublicProductGuideDetail;
