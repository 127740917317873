import moment from "moment";
import { BillModel, BillPayStatus } from "src/api/contract/contract-types";
import { numberToStringWithComma } from "src/utils/common-util";

// 회차별 청구 목록 아이템
export interface BillItemByOrder {
  billOrder: number; // 회차
  bill: BillModel; // 원청구
  overdueBill?: BillModel; // 연체청구
}

// 청구목록 필터링 (계약상세, 청구목록 등에서 사용)
export function filterBillList(billList?: BillModel[]) {
  const filteredBillList = (billList || []).filter((_bill) => {
    if (_bill?.originBill.isOverdueBill) {
      // 연체청구서는 모두
      return true;
    } else {
      // 원청구서
      if (_bill?.billPayStatus === "CONTRACT_CANCEL") {
        // 계약해지 제외 (중지상태)
        return false;
      }
      // 원청구는 청구일자가 오늘 이전인 것들 (오늘 포함)
      return moment(_bill.originBill.billIssueDate).isSameOrBefore(moment());
    }
  });
  return filteredBillList;
}

// 납부 여부 (결제성공, 결제무료만 납부)
export function isBillPaySuccess(billPayStatus?: BillPayStatus) {
  return ["PAYMENT_SUCCESS", "PAYMENT_FREE"].includes(billPayStatus || "");
}

// 토탈 청구금액
export function totalAmount(billItem: BillModel): string {
  let _totalAmount = Number(billItem.originBill.totalAmount || 0); // 원청구의 청구금액
  if (billItem?.overdueBill) {
    // 연체청구서
    _totalAmount += Number(billItem.overdueBill.totalAmount || 0); // 연체청구의 청구금액
  } else {
    // 실시간 연체료
    _totalAmount += Number(billItem.overdueAmount || 0); // 원청구의 연체금액
  }
  return numberToStringWithComma(_totalAmount);
}
