import { ReactChild, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
interface Props {
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  btnLeftTitle?: string;
  btnRightTitle?: string;
  type?: string;
  btnRightType?: "button" | "submit" | "reset" | undefined;
  className?: string;
}
/*
  ConfirmModal  
  취소 혹은 확인 버튼이 하단에 있는 모달
*/
const ConfirmModal = ({ isOpen, onClose, onClick, children, btnLeftTitle, btnRightTitle, type, btnRightType, className }: Props) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef<HTMLElement | null>(null);
  useEffect(() => {
    ref.current = document.getElementById("modal");
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!isOpen) return null;

  return mounted
    ? createPortal(
        <>
          <div className="dim">
            <div className={`confirm-modal ${className ? className : ""}`}>
              <div className="confirm-modal__contents">{children && <>{children}</>}</div>
              <div className="confirm-modal__btn-wrap">
                {btnLeftTitle && (
                  <button onClick={onClose} className="base-btn color-white mr10">
                    {btnLeftTitle}
                  </button>
                )}
                {btnRightTitle && (
                  <button onClick={onClick} className="base-btn">
                    {btnRightTitle}
                  </button>
                )}
              </div>
            </div>
          </div>
        </>,
        ref.current!,
      )
    : null;
};

export default ConfirmModal;
