import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { billingAutoPayAsync, getBillingContractBillsAsync, getContractAsync, getProviderAccountsAsync } from "src/api/contract/contract-api";
import { BillPayStatus, BillingContractBillModel, ContractModel, CtProviderAccount, ScheduleRecentBill } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, ConfirmModal } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import { useToast } from "src/pages/hooks/toast";
import { Modal } from "src/pages/visitor/visitor-types";
import { getUserDevice, numberToStringWithComma } from "src/utils/common-util";
import { BILL_SUPPLY_TYPE_MAP } from "src/utils/type-map";
import { isBillPaySuccess } from "../contract-bill-types";
import { useErrorModal } from "src/recoil/errorModal/hook";
import usePostMessage from "src/pages/hooks/post-message";

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 청구 내역 상세 화면
 */
const ContractBillDetail = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const device = getUserDevice();

  const { goBack } = useNavigateGoBack();

  const { openErrorModal } = useErrorModal();

  // 토스트
  const { openToast } = useToast();

  // path variable 계약 id, 청구회차
  const { contractId, billOrder } = useParams();

  // 신청/계약 상세 조회 api
  const { executeAsync: getContract } = useApiOperation(getContractAsync);

  // 청구정보 결제현황 조회 api
  const { executeAsync: getBillingContractBills } = useApiOperation(getBillingContractBillsAsync);

  // 계약 프로바이더 입금계좌 조회 api
  const { executeAsync: getProviderAccounts } = useApiOperation(getProviderAccountsAsync);

  // 이용료 결제 api
  const { executeAsync: billingAutoPay } = useApiOperation(
    billingAutoPayAsync, //
    {
      noHandleError: true,
    },
  );

  // 웹뷰 out
  const { postMessageGoBackNavigation } = usePostMessage();

  // 신청/계약 상세
  const [contract, setContract] = useState<ContractModel | null>(null);

  // 청구정보 결제현황
  const [billingContractBill, setBillingContractBill] = useState<BillingContractBillModel>();

  // 계약 프로바이더 입금계좌 목록
  const [providerAccounts, setProviderAccounts] = useState<CtProviderAccount[]>([]);

  // 경고 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 스케줄 리스트에 rental item이 있는지 확인
  const isBillKey = useMemo(() => {
    let _isBillKey = false;
    if (contract && contract.scheduleList.length > 0) {
      const rentalSchedule = contract?.scheduleList.find((item) => item.supplyType === "RENTAL");
      if (rentalSchedule?.isBillkey) {
        _isBillKey = rentalSchedule.isBillkey;
      }

      return _isBillKey;
    }
  }, [contract]);
  const pgcode = useMemo(() => {
    let _isPgcode = "";
    const rentalSchedule = contract?.scheduleList.find((item) => item.supplyType === "RENTAL");
    if (rentalSchedule?.recentBill) {
      _isPgcode = rentalSchedule?.recentBill?.pgcode;
    }
    return _isPgcode;
  }, [contract]);

  // 임대료 계좌 목록
  const rentAccounts = useMemo(() => {
    if (!providerAccounts || providerAccounts.length === 0) return [];
    return providerAccounts.filter((account) => account.accountType === "RENT_ACCOUNT");
  }, [providerAccounts]);

  const billItem = billingContractBill?.billList?.find((bill) => bill.billOrder === Number(billOrder));
  // 신청/계약 상세 조회
  const fetchContract = useCallback(
    async (contractId: string) => {
      const { data } = await getContract({ contractId });
      const contract = data?.data || null;
      setContract(contract);
    },
    [getContract],
  );

  // 청구정보 결제현황 조회
  const fetchBillingContractBills = useCallback(
    async (contractId: number | string) => {
      const { data } = await getBillingContractBills({ contractId });
      setBillingContractBill(data?.data);
    },
    [getBillingContractBills],
  );

  // 계약 프로바이더 입금계좌 조회
  const fetchProviderAccounts = useCallback(
    async (contractApplyNumber: string) => {
      const { data } = await getProviderAccounts({
        contractApplyNumber,
        accountType: "RENT_ACCOUNT", // 임대료 계좌
      });
      setProviderAccounts(data?.data?.content || []);
    },
    [getProviderAccounts],
  );

  useEffect(() => {
    if (contractId) {
      // 청구정보 결제현황 조회
      fetchBillingContractBills(contractId);
    }
  }, [contractId, fetchBillingContractBills]);

  useEffect(() => {
    if (contractId) {
      // 신청/계약 상세 조회
      fetchContract(contractId);
    }
  }, [contractId, fetchContract]);

  useEffect(() => {
    if (contract?.contractApplyNumber) {
      // 계약 프로바이더 입금계좌 조회
      fetchProviderAccounts(contract.contractApplyNumber);
    }
  }, [contract, fetchProviderAccounts]);

  // const titleBillDate = moment(billItem?.originBill.billStartTime).format("YYYY년 M월");

  const getPaymentStatus = (billPayStatus: BillPayStatus, paymentsDate: string, receiptTotalAmount: number) => {
    // TODO - paymentsDate 가 없으면 미납?
    if (!paymentsDate) return;

    if (isBillPaySuccess(billPayStatus)) {
      return `${moment(paymentsDate).format("YYYY.MM.DD")} 납부`;
    }

    if (receiptTotalAmount > 0) {
      return `${numberToStringWithComma(receiptTotalAmount)} 수납`;
    }
    return "미납";
  };

  // 당월 이용료 / 연체료 결제 api
  const billingAutoPayApi = async (id: string) => {
    if (!isBillKey) {
      setAlertModal({ isOpen: true });
      return;
    }

    // api-operation 에서 eCT801 에러에 대한 팝업 노출 작업 함

    try {
      const response = await billingAutoPay({ id });
      if (response.status >= 200 && response.status <= 299) {
        navigate(`/court/payment/result?contractId=${contractId}&response=PAYMENT_SUCCESS&billOrder=${billOrder}`);
      }
    } catch (error: any) {
      console.log(`errors`, { ...error });
      const _error = { ...error };

      // 그외 에러
      let errorCode = "";
      let errorMessage = "";
      let errorData = "";
      let statusCode = 0;
      if (_error?.response?.data?.meta?.errorCode) {
        errorCode = _error?.response?.data?.meta?.errorCode;
      }
      if (_error?.response?.data?.meta?.errorMessage) {
        errorMessage = (errorCode === "eCT801" && "지금은 시스템에서 \n 정기결제를 진행 중입니다.") || "";
      }
      if (!errorMessage) {
        errorMessage = _error?.message || "에러가 발생했습니다.";
      }
      if (_error?.response?.data?.meta?.errorData) {
        errorData = _error?.response?.data?.meta?.errorData;
      }
      if (_error?.response?.status) {
        statusCode = Number(_error?.response?.status);
      }
      // 에러 모달
      if (errorCode && errorCode === "eCT801") {
        openErrorModal({ errorCode, errorMessage, errorData, statusCode });
      }

      if (errorCode && errorCode !== "eCT801") {
        navigate(`/court/payment/result?contractId=${contractId}&response=PAYMENT_FAILED&billOrder=${billOrder}`);
      }
    }

    // const response = await billingAutoPay({ id });
    // if (response.status >= 200 && response.status <= 299) {
    //   navigate(`/court/payment/result?contractId=${contractId}&response=PAYMENT_SUCCESS&billOrder=${billOrder}`);
    // } else {
    //   // 에러 모달
    //   navigate(`/court/payment/result?contractId=${contractId}&response=PAYMENT_FAILED&billOrder=${billOrder}`);
    // }
  };

  const getBillingDetailList = ({
    billTotalAmount,
    overdueAmount,
    prevSumUnpaidAmount,
    prevSumOverdueAmount,
    billPayStatus,
    overduePayStatus,
    originBillLastReceiptDate,
    overdueLastReceiptDate,
    billSupplyType,
    originReceiptTotalAmount,
    overdueReceiptTotalAmount,
    originBillId,
    overDueId,
  }: {
    billTotalAmount: number;
    overdueAmount: number;
    prevSumUnpaidAmount: number;
    prevSumOverdueAmount: number;
    billPayStatus: BillPayStatus;
    overduePayStatus: BillPayStatus;
    originBillLastReceiptDate: string;
    overdueLastReceiptDate: string;
    billSupplyType: string;
    originReceiptTotalAmount: number;
    overdueReceiptTotalAmount: number;
    originBillId: string;
    overDueId: string;
  }) => {
    return [
      {
        title: "당월 청구",
        amount: billTotalAmount,
        paymentStatus: getPaymentStatus(billPayStatus, originBillLastReceiptDate, originReceiptTotalAmount),
        color: isBillPaySuccess(billPayStatus) ? "" : "text-red",
        isVisible: true,
        prevSumOverdueAmount,
        originBillId, // 당월 이용료
      },
      {
        title: "당월 연체료",
        amount: overdueAmount,
        paymentStatus: getPaymentStatus(overduePayStatus, overdueLastReceiptDate, overdueReceiptTotalAmount),
        color: isBillPaySuccess(overduePayStatus) ? "" : "text-red",
        isVisible: overdueAmount > 0,
        prevSumOverdueAmount,
        billPayStatus,
        overDueId,
      },
      {
        title: "이월 미납",
        amount: prevSumUnpaidAmount,
        paymentStatus: "미납",
        color: "text-red",
        isVisible: prevSumUnpaidAmount > 0,
        prevSumOverdueAmount,
      },
      {
        title: "이월 연체",
        amount: prevSumOverdueAmount,
        paymentStatus: "미납",
        color: "text-red",
        isVisible: prevSumOverdueAmount > 0,
        prevSumOverdueAmount,
      },
    ]
      .filter((v) => v.isVisible)
      .map((v) => {
        return {
          ...v,
          amount: numberToStringWithComma(v.amount),
        };
      });
  };

  //
  const billingDetailList = useMemo(() => {
    // console.log("billItem", billItem);
    return getBillingDetailList({
      billPayStatus: billItem?.billPayStatus!,
      overduePayStatus: billItem?.overduePayStatus!,
      billTotalAmount: billItem?.billTotalAmount!,
      overdueAmount: billItem?.overdueAmount!,
      prevSumUnpaidAmount: billItem?.prevSumUnpaidAmount!,
      prevSumOverdueAmount: billItem?.prevSumOverdueAmount!,
      originBillLastReceiptDate: billItem?.originBill?.receiptBundle?.lastTrDateTime!,
      overdueLastReceiptDate: billItem?.overdueBill?.receiptBundle?.lastTrDateTime!,
      billSupplyType: BILL_SUPPLY_TYPE_MAP[billItem?.billSupplyType!],
      originReceiptTotalAmount: billItem?.originBill?.receiptBundle?.receiptTotalAmount!,
      overdueReceiptTotalAmount: billItem?.overdueBill?.receiptBundle?.receiptTotalAmount!,
      originBillId: String(billItem?.originBill?.billId),
      overDueId: String(billItem?.overdueBill?.billId),
    });
  }, [billItem]);

  if (!billItem) return null;
  return (
    <>
      <MetaTag title="마이페이지" />
      <Header
        headerType="CLOSE"
        title={billItem.originBill.billTitle}
        onClickCloseButton={() => {
          if (location.state && location.state === "mypage") {
            navigate(`/court/mypage/contracts/${contractId}`, { state: location.state });
          } else {
            goBack();
          }
          // goBack();
        }}
      />

      <div className="contract-page">
        <h1 className="base-title">월별 청구내역</h1>
        <article className="contract-history-montly">
          <section className="contract-main base-section-layout">
            <div className="gray-card">
              <div className="base-section-layout-line">
                <p className="base-section-layout-index">
                  <b className="font18 font-roboto">납부하실 총 금액</b>
                </p>
                <p className="base-section-layout-value">
                  <b className="font18 font-roboto">{numberToStringWithComma(billItem.requestSumAmount)}</b> 원
                </p>
              </div>
              <div className="base-section-layout-line">
                <p className="base-section-layout-index">납부 기한</p>
                <p className="base-section-layout-value">{moment(billItem.originBill.billDueDate).format("YYYY.MM.DD")} 까지</p>
              </div>
            </div>
            <ul className="pt20 pb30">
              <li>
                납부기한 내 <b>납부하실 총금액</b>을 확인 후 납부해 주세요
              </li>
              <li>납부기한이 경과되면 연체료가 발생합니다</li>
            </ul>
          </section>

          <section className="base-section-layout top-divider">
            <div className="base-section-layout__container">
              {/* 원청구 */}
              {billingDetailList.map((item, index) => {
                return (
                  <div className="base-section-layout__wrap" key={item.title}>
                    {index === 0 && (
                      <div className="base-section-layout__title-wrap">
                        <h2>청구 상세</h2>
                      </div>
                    )}
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">{item.title}</p>
                      <div className={`base-section-layout-value ${item.color}`}>
                        <p>
                          <b>{item.amount}</b> 원
                        </p>
                        <p>{item.paymentStatus}</p>
                      </div>
                    </div>
                    {/* 결제방식이 creditcard 일 때만 노출 (신용카드 결제)*/}
                    {!item.paymentStatus && (contract?.isBillkey || pgcode === "creditcard") && (
                      <div className="mt40">
                        <BaseButton
                          title="결제하기"
                          className="base-btn size-small color-white"
                          onClick={() => {
                            let billId = "";

                            if (item.originBillId) {
                              billId = item.originBillId;
                            }

                            if (item.overDueId) {
                              billId = item.overDueId;
                            }

                            setConfirmModal({ isOpen: true, message: "결제하시겠습니까?", payload: String(billId) || "" });
                          }}
                          disabled={item.prevSumOverdueAmount > 0 || (item.billPayStatus && item.billPayStatus !== "PAYMENT_SUCCESS") || false}
                        />
                      </div>
                    )}
                  </div>
                );
              })}

              <div className="base-section-layout__wrap">
                <ul>
                  <li>미납/연체 금액이 있으면 일 단위로 반영됩니다</li>
                  <li>연체료는 납부일 기준이며 실 결제일(영업일) 금액과 다를 수 있습니다</li>
                  <li>상세 정보는 해당월 청구내역에서 확인하실 수 있습니다</li>
                </ul>
              </div>
            </div>
          </section>

          <section
            className={`base-section-layout ${
              (!billItem?.overdueBill && Number(billItem?.overdueAmount || 0) > 0) || billItem?.overdueBill ? "top-divider" : ""
            }`}
          >
            <div className="base-section-layout__container">
              {rentAccounts.map((account: CtProviderAccount, index: number) => (
                <div key={index} className="base-section-layout__wrap">
                  <div className="base-section-layout__title-wrap">
                    <h2>계좌 정보</h2>
                  </div>
                  <div className="base-section-layout-line">
                    <p className="base-section-layout-index text-primary3">계좌번호</p>
                    <CopyToClipboard text={`${account.bankName} ${account.accountNumber}`}>
                      <p
                        className="base-section-layout-value text-underline cursor-pointer"
                        onClick={() => {
                          openToast({ content: "계좌정보가 복사되었습니다" });
                        }}
                      >
                        {account.bankName} {account.accountNumber}
                      </p>
                    </CopyToClipboard>
                  </div>
                  <div className="base-section-layout-line">
                    <p className="base-section-layout-index text-primary3">예금주</p>
                    <p className="base-section-layout-value">{account.accountHolder}</p>
                  </div>

                  {rentAccounts.length === index + 1 && (
                    <div className="pt20 pb10 d-flex flex-center">
                      {billItem.billSupplyType === "MNT" && (
                        <button
                          type="button"
                          className="base-btn color-white size-small mr20"
                          onClick={() => navigate(`/court/mypage/contracts/${contractId}`)}
                        >
                          고지서 상세
                        </button>
                      )}
                      <button
                        type="button"
                        className="base-btn color-white size-small"
                        onClick={() => navigate(`/court/mypage/contracts/${contractId}`, { state: location.state })}
                      >
                        신청/계약 상세
                      </button>
                    </div>
                  )}
                </div>
              ))}

              {rentAccounts.length === 0 && (
                <div className="pt20 pb10 d-flex flex-center">
                  {billItem.billSupplyType === "MNT" && (
                    <button
                      type="button"
                      className="base-btn color-white size-small mr20"
                      onClick={() => navigate(`/court/mypage/contracts/${contractId}`)}
                    >
                      고지서 상세
                    </button>
                  )}
                  <button type="button" className="base-btn color-white size-small" onClick={() => navigate(`/court/mypage/contracts/${contractId}`)}>
                    신청/계약 상세
                  </button>
                </div>
              )}
            </div>
          </section>
        </article>
      </div>
      {alertModal.isOpen && (
        <ConfirmModal isOpen={alertModal.isOpen} btnRightTitle={"확인"} onClick={() => setAlertModal({ isOpen: false })}>
          <div>
            <h2 className=" mb20">결제수단을 등록해주세요.</h2>
            <p className="text-primary3">
              신청/계약 상세 화면의 [신용카드 등록하기] 를 터치하시어 <br /> 결제하실 신용카드를 등록해 주세요.
            </p>
          </div>
        </ConfirmModal>
      )}

      {confirmModal.isOpen && (
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          btnRightTitle={"확인"}
          btnLeftTitle="취소"
          onClick={() => {
            billingAutoPayApi(String(confirmModal.payload));
            setConfirmModal({ isOpen: false });
          }}
          onClose={() => setConfirmModal({ isOpen: false })}
        >
          <div>
            <p className="font16">{confirmModal.message}</p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default ContractBillDetail;
