import { replaceNumberWithPlusMinus } from "src/utils/common-util";

type Props = {
  type?: "text" | "phone";
  name?: string;
  value?: string;
  isInvalid?: boolean; // 유효하지 않을 경우 true (invalidMessage 노출)
  invalidMessage?: string;
  isLoading?: boolean; // 로딩중
  actionButtonTitle?: string;
  disabledActionButton?: boolean;
  onClickActionButton?: () => void;
  placeholder?: string;
  inputRef?: any;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  wrapClassName?: string;
  inputClassName?: string;
  validationTextClassName?: string; // 없으면 undefined
  onChange?: (value?: string, e?: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUpEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

/**
 * input text 오른쪽 텍스트 버튼있는 공통 컴포넌트
 */
const BaseTextInputWithButton = ({
  type = "text",
  name,
  value,
  isInvalid,
  invalidMessage,
  isLoading,
  actionButtonTitle,
  disabledActionButton,
  onClickActionButton,
  placeholder,
  inputRef,
  readOnly,
  disabled,
  maxLength,
  wrapClassName,
  inputClassName,
  validationTextClassName,
  onChange,
  onKeyUp,
  onKeyUpEnter,
}: Props) => {
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onChange) {
      // onChange(e.target?.value, e);
      const targetValue = e.target.value;
      const replacedValue = replaceNumberWithPlusMinus(targetValue);
      onChange(replacedValue, e);
    }
  };

  const _onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onKeyUp) {
      onKeyUp(e);
    }
    if (e.key === "Enter") {
      if (onKeyUpEnter) {
        onKeyUpEnter(e);
      }
    }
  };

  return (
    <div className={`${wrapClassName ? wrapClassName : "base-input-wrap"}`}>
      {type === "phone" ? (
        <input
          ref={inputRef}
          // type="number"
          // inputMode="numeric"
          // pattern="\d*"
          type="tel"
          inputMode="tel"
          pattern="[0-9\-+]+"
          className={`${inputClassName ? inputClassName : "base-input"} ${isInvalid ? "border-bottom-red" : ""}`}
          name={name}
          value={value || ""}
          onChange={_onChange}
          onBlur={(e) => {
            onChange && onChange(e.target.value.trim(), e);
          }}
          onKeyUp={_onKeyUp}
          placeholder={placeholder}
          readOnly={readOnly ? true : false}
          disabled={disabled ? true : false}
          maxLength={maxLength}
        />
      ) : (
        <input
          ref={inputRef}
          type="text"
          className={`${inputClassName ? inputClassName : "base-input"} ${isInvalid ? "border-bottom-red" : ""}`}
          name={name}
          value={value || ""}
          onChange={_onChange}
          onBlur={(e) => {
            onChange && onChange(e.target.value.trim(), e);
          }}
          onKeyUp={_onKeyUp}
          placeholder={placeholder}
          readOnly={readOnly ? true : false}
          disabled={disabled ? true : false}
          maxLength={maxLength}
        />
      )}

      <button type="button" className={`only-text ${isLoading ? "--loading" : ""}`} onClick={onClickActionButton} disabled={disabledActionButton}>
        {actionButtonTitle || "버튼"}
      </button>

      <div className="validation-wrap position-absolute">
        {invalidMessage && (
          <div className="validation-wrap__left">
            <p className={validationTextClassName || "validation-text"}>{invalidMessage}</p>
          </div>
        )}
        <div className="validation-wrap__right"></div>
      </div>
    </div>
  );
};

export default BaseTextInputWithButton;
