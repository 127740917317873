import { AxiosInstance, AxiosResponse } from "axios";
import { ApiPath } from "..";
import { PriceModel, PriceParams } from "../product/product-types";
import { ApiResponse } from "../public-types";
import {
  AcknowledgeReservation,
  AvailableReservations,
  AvailableReserveTimes,
  ClosedDay,
  ClosedParams,
  ClosedPeriodModel,
  ClosedPeriodParams,
  QuotationModel,
  QuotationParams,
  QuotationRequest,
  ReservationPeriodParams,
  ReservationQueryParams,
  ReservationTimesParams,
} from "./reservation-type";

// ============== v.1.19 새로운 api 수정중 ==============

/**TES01. (무인증)예약가능구간 설정 결과 목록 조회 > [시간 슬롯에 설정]*/
export const getReserveDurationAsyc = async (
  axios: AxiosInstance,
  params?: ReservationPeriodParams,
): //
Promise<AxiosResponse<ApiResponse<{ content: AvailableReservations[] }>>> => {
  return await axios.get(`/api/court/et/public/availableReservationTime/SERVICE_PRODUCT/${params?.serviceId}`, {
    params: {
      buildingCommonFacilityId: params?.buildingCommonFacilityId,
      commonFacilityType: params?.commonFacilityType,
    },
  });
};

/**TES02. (무인증)예약시간단위 설정 결과 목록 조회 > [최소 ~ 최대 몇시간 이상]*/
export const getMinMaxReservationTimeAsyc = async (
  axios: AxiosInstance,
  params?: ReservationTimesParams,
): //
Promise<AxiosResponse<ApiResponse<{ content: AvailableReserveTimes[] }>>> => {
  return await axios.get(`/api/court/et/public/unitReservationTime/SERVICE_PRODUCT/${params?.serviceId}`, {
    params,
  });
};

/**TES03. (무인증)휴무시간(기간) 조회*/
export const getClosedPeriodAsync = async (
  axios: AxiosInstance,
  params?: ClosedPeriodParams,
): //
Promise<AxiosResponse<ApiResponse<{ content: ClosedPeriodModel[] }>>> => {
  return await axios.get(`/api/court/et/public/closedPeriod/SERVICE_PRODUCT/${params?.serviceId}`, {
    params: {
      date: params?.date,
      commonFacilityType: params?.commonFacilityType,
      buildingCommonFacilityId: params?.buildingCommonFacilityId,
    },
  });
};
/**TES04. (무인증)휴무일 조회*/
export const getClosedDaysAsync = async (
  axios: AxiosInstance,
  params?: ClosedParams,
): //
Promise<AxiosResponse<ApiResponse<{ content: ClosedDay[] }>>> => {
  return await axios.get(`/api/court/et/public/closedPeriod/daily/SERVICE_PRODUCT/${params?.serviceId}`, {
    params: {
      buildingCommonFacilityId: params?.buildingCommonFacilityId,
      commonFacilityType: params?.commonFacilityType,
      startDate: params?.startDate,
      endDate: params?.endDate,
    },
  });
};

/**TIP01. (무인증) 요금 조회 > v.1.19 에 그대로 사용*/
export async function getPriceAsync(
  axios: AxiosInstance,
  params?: PriceParams, //
): Promise<AxiosResponse<ApiResponse<{ content: PriceModel[] }>>> {
  return await axios.get(ApiPath.prices.list, {
    params,
  });
}

// =================================================

/**TIQ02 - (무인증)견적서 등록*/
export async function postQuotationAsync(
  axios: AxiosInstance,
  params?: QuotationRequest, //
): Promise<AxiosResponse<ApiResponse<{ content: QuotationModel }>>> {
  const data = {
    quotationItemList: params!.quotationItemList,
  };

  return await axios.post(
    ApiPath.quotation.add,
    {
      quotation: data,
    },
    { headers: { "X-Recaptcha-Token": params!.token } },
  );
}

//TIQ01 - (무인증)견적서 목록(상세)
export async function getQuotationListPublicAsync(
  axios: AxiosInstance,
  params?: QuotationParams, //
): Promise<AxiosResponse<ApiResponse<{ content: QuotationModel[] }>>> {
  return await axios.get(ApiPath.quotation.list.public, {
    params: { quotationNo: params?.quotationNo },
    headers: { "X-Recaptcha-Token": params?.token || "" },
  });
}

//TIQ01 - (인증)견적서 목록(상세)
export async function getQuotationListAsync(
  axios: AxiosInstance,
  params?: QuotationParams, //
): Promise<AxiosResponse<ApiResponse<{ content: QuotationModel[] }>>> {
  return await axios.get(ApiPath.quotation.list.auth, {
    params: { quotationNo: params!.quotationNo },
  });
}

/**TRV10. (무인증) 공용 공간 예약 점유 현황 조회*/
export async function getReservedTimesAsync(
  axios: AxiosInstance,
  params?: ReservationQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content: AcknowledgeReservation[] }>>> {
  return await axios.get("/api/court/rv/public/acknowledged/reservations", {
    params,
  });
}
