import { orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getContractPaymentsAsync } from "src/api/contract/contract-api";
import { ContractPaymentModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { ActiveTab } from "../payment-types";
import PaymentItem from "../components/PaymentItem";

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 결제 내역 / 연체 내역 화면
 */
const ContractPaymentList = () => {
  const navigate = useNavigate();

  // path variable 계약 id
  const { contractId } = useParams();

  // query parameter
  const [searchParams] = useSearchParams();

  // 활성화 탭
  const activeTab: ActiveTab = useMemo(() => {
    if (!searchParams.get("tab")) return "PAYMENT";
    return searchParams.get("tab") as ActiveTab;
  }, [searchParams]);

  // 결제/취소 내역 조회 api
  const { executeAsync: getContractPayments } = useApiOperation(getContractPaymentsAsync);

  // 결제/취소 내역
  const [contractPayments, setContractPayments] = useState<Array<ContractPaymentModel>>([]);
  // 결제/취소 내역 조회
  const fetchContractPayments = useCallback(
    async (contractId: string, isOverdueBill: boolean) => {
      const { data } = await getContractPayments({ contractId, isOverdueBill });
      const list = data?.data?.content || [];
      // TODO: 정렬 필드 확인 필요
      const sortedList = orderBy(list, ["transactionDate"], ["desc"]);
      setContractPayments(sortedList);
    },
    [getContractPayments],
  );

  useEffect(() => {
    if (contractId) {
      if (activeTab === "PAYMENT") {
        // 결제/취소 내역 조회
        fetchContractPayments(contractId, false);
      } else if (activeTab === "OVERDUE") {
        // 결제/취소 내역 조회
        fetchContractPayments(contractId, true);
      }
    }
  }, [contractId, activeTab, fetchContractPayments]);

  return (
    <>
      <MetaTag title="마이페이지" />
      <Header
        headerType="BACK"
        title="결제 내역"
        onClickBackButton={() => {
          console.log("onClickBackButton");
          navigate(-1);
        }}
      />

      <div className="payment-page">
        <article className="payment-history">
          {/* <section className="custom-tab">
            <div
              className={`custom-tab__item ${activeTab === "PAYMENT" ? "--active" : ""}`}
              onClick={() => {
                navigate({ pathname: `/court/mypage/contracts/${contractId}/payments`, search: `tab=PAYMENT` });
              }}
            >
              <span>결제 내역</span>
            </div>
            <div
              className={`custom-tab__item ${activeTab === "OVERDUE" ? "--active" : ""}`}
              onClick={() => {
                navigate({ pathname: `/court/mypage/contracts/${contractId}/payments`, search: `tab=OVERDUE` });
              }}
            >
              <span>연체 내역</span>
            </div>
          </section> */}
          {contractPayments.length > 0 ? (
            <section className="contents-area">
              {contractPayments.map((payment: ContractPaymentModel, index: number) => (
                <PaymentItem key={index} activeTab={activeTab} payment={payment} />
              ))}
              <ul>
                <li>결제 내역은 지난 1년까지만 표시됩니다.</li>
              </ul>
              {/* <p className="font14 text-primary3">결제 내역은 지난 1년까지만 표시됩니다.</p> */}
            </section>
          ) : (
            <section className="contents-empty">
              <p>{activeTab === "PAYMENT" ? "결제" : "연체"} 이력이 없습니다</p>
            </section>
          )}
        </article>
      </div>
    </>
  );
};

export default ContractPaymentList;
