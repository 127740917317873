import React, { useEffect, useState } from "react";

/**
 * 스크롤 위치가 titleWrapRef 높이를 넘어가면 헤더명 안보이게하기
 * 태그는 div 여야 함
 */
type Props = {
  pageContainer: React.RefObject<HTMLDivElement>;
  startPoint: React.RefObject<HTMLDivElement>;
};
export function useHeaderOpacity({ pageContainer, startPoint }: Props) {
  const [opacityCount, setOpacityCount] = useState(0);
  const countScrollOpacityHeader = () => {
    pageContainer?.current?.addEventListener("scroll", (e) => {
      const nowScrollY = pageContainer?.current?.scrollTop;
      const startPointHeight = startPoint?.current?.offsetHeight;
      const countPerOpacity = (1 / Number(startPointHeight)) * Number(nowScrollY);
      if (1 < countPerOpacity) {
        setOpacityCount(1);
      } else setOpacityCount(countPerOpacity);
    });
    pageContainer.current?.removeEventListener("scroll", () => {});
  };

  useEffect(() => {
    countScrollOpacityHeader();
  });
  return { opacityCount };
}
