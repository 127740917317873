export const Soon = () => {
  return (
    <article className="page-soon">
      <div className="icon-soon"></div>
      <h1>
        <strong>'Taap SPACE'</strong> 서비스가 곧 찾아갑니다.
      </h1>
      <p>
        조금만 기다려 주세요!
        <br />더 편리하고 즐거운 서비스로 보답해 드릴게요.
      </p>
    </article>
  );
};

export default Soon;
