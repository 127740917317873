import { useEffect, useState } from "react";
import { Toast } from "src/recoil/toast/atom";

/**
 * 토스트 아이템
 */
function ToastItem({ content, duration, bottom }: Toast) {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const setExistTimeout = setTimeout(() => {
      setIsClosing(true);
      clearTimeout(setExistTimeout);
    }, duration ?? 3000);
  }, []);

  if (isClosing) return null;

  return (
    <div className="base-toast" style={bottom ? { bottom: `${bottom}px` } : {}}>
      <p>{content}</p>
    </div>
  );
}

export default ToastItem;
