import React from "react";
import excelFormExampleImage from "src/assets/images/excel_form_example.png";

interface Props {
  isVisible: boolean;
}

function FileUploadButtonPopup({ isVisible }: Props) {
  return (
    <div className={`confirm-modal border-1px-solid popup ${isVisible ? "on" : ""}`}>
      <div className="confirm-modal__contents">
        <p className="font18 font-weight-600">대량의 이용자 초대가 필요하세요?</p>
        <p className="font15 font-weight-400 mt20">초대할 이용자들의 휴대폰 번호를</p>
        <p className="font15 font-weight-400">엑셀 파일로 업로드하세요.</p>
        <p className="font15 font-weight-400 mt20">둘째행부터 휴대폰번호를 연속하여 기입해 주세요.</p>
        <p className="font15 font-weight-400">*.xls, xlsx 형태의 엑셀 파일만 지원합니다.</p>

        <img src={excelFormExampleImage} alt="엑셀 파일 양식" className="mt20" />
      </div>
    </div>
  );
}

export default FileUploadButtonPopup;
