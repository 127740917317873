const PageNotFound = () => {
  return (
    <div className="error-page">
      <section>
        <div className="error-icon ic-question"></div>
        <div className="error-message-wrap">
          <p>
            이 페이지가 맞나요?
            <br />
            정확한 주소인지 다시 한 번 확인해주세요
          </p>
        </div>
      </section>
    </div>
  );
};
export default PageNotFound;
