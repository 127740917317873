import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBillingContractBillsAsync, getMemberAssignContractsAsync } from "src/api/contract/contract-api";
import { BillingContractBillModel, BillModel, MemberContractModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { BaseBottomSheet } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useMediaQueries } from "src/pages/hooks/media-query";
import { BillItemByOrder, filterBillList } from "../contract-bill-types";
import BillItem from "./components/BillItem";
import _ from "lodash";

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 청구 내역 목록 화면
 */
const ContractBillList = () => {
  const { isMobile } = useMediaQueries();

  const initializedRef = useRef(false);

  const navigate = useNavigate();

  // path variable 계약 id
  const { contractId } = useParams();

  // 로그인 사용자 계약 목록 조회 api
  const { executeAsync: getMemberAssignContracts } = useApiOperation(getMemberAssignContractsAsync);

  // 청구정보 결제현황 조회 api
  const { executeAsync: getBillingContractBills } = useApiOperation(getBillingContractBillsAsync);

  // 로그인 사용자 계약 목록 (셀렉트 박스 목록)
  const [contracts, setContracts] = useState<MemberContractModel[]>([]);

  // 선택되어있는 계약
  const [selectedContract, setSelectedContract] = useState<MemberContractModel>();

  // 청구정보 결제현황
  const [billingContractBill, setBillingContractBill] = useState<BillingContractBillModel>();

  // 계약 선택 바텀시트 오픈여부
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState<boolean>(false);
  // 계약 선택 셀렉트박스 오픈여부
  const [isOpenDrop, setIsOpenDrop] = useState<boolean>(false);

  // 아직 청구일자가 도래하지 않은 청구내역 목록
  const billList = billingContractBill?.billList?.filter((bill) => moment(bill.originBill.billStartTime).isBefore(moment(new Date())));

  // 로그인 사용자 계약 목록 조회
  const fetchMemberAssignContracts = useCallback(async () => {
    const { data } = await getMemberAssignContracts({ isContractor: true });
    const _contracts = (data?.data?.content || [])
      // 마이페이지 메인 > 신청/계약 내역 > 상세 화면 > 나의 납부 상태 노출 조건과 동일
      // F/O 타입 이고, 계약체결 / 이용승인 / 이용중 / 이용완료 / 해지접수 / 해지완료
      .filter(
        (_contract: MemberContractModel) =>
          _contract?.spaceProductType !== "TIME_COURT" &&
          ["CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "USE_COMPLETE", "TERMINATE_RECEIVED", "TERMINATE_COMPLETE"].includes(
            _contract?.contractStep || "",
          ),
      )
      .sort((a: MemberContractModel, b: MemberContractModel) => Number(b?.contractId || 0) - Number(a?.contractId || 0));

    setContracts(_contracts);

    if (!selectedContract) {
      const _selectedContract = _contracts.find((c) => c.contractId === contractId);
      if (_selectedContract) {
        setSelectedContract(_selectedContract);
      }
    }
  }, [getMemberAssignContracts]);

  // 청구정보 결제현황 조회
  const fetchBillingContractBills = useCallback(
    async (contractId: number | string) => {
      const { data } = await getBillingContractBills({ contractId });

      // const _temp = data?.data.billList!.filter((bm: BillModel) => bm.originBill.billPayStatus !== "TEMP_SAVE");
      const _temp = data?.data.billList!;
      data.data.billList = _.sortBy(_temp, ["originBill.billIssueDate"]).reverse();

      setBillingContractBill(data.data);

      if (!initializedRef.current) {
        initializedRef.current = true;
      }
    },
    [getBillingContractBills],
  );
  useEffect(() => {
    // 로그인 사용자 계약 목록 조회
    fetchMemberAssignContracts();
  }, [fetchMemberAssignContracts]);

  useEffect(() => {
    // 계약 선택을 바꿀때마다
    if (selectedContract && selectedContract?.contractId) {
      // 청구정보 결제현황 조회
      fetchBillingContractBills(selectedContract.contractId);
    }
  }, [selectedContract, fetchBillingContractBills]);

  return (
    <>
      <MetaTag title="마이페이지" />
      <Header
        headerType="BACK"
        title="청구 내역"
        onClickBackButton={() => {
          navigate(-1);
        }}
      />

      <div className="contract-page">
        <h1 className="base-title">청구 내역</h1>
        {isMobile ? (
          <div className="contract-select-building">
            {selectedContract && (
              <button
                className={`d-none-pc chevron-down-btn text-left ${isOpenBottomSheet ? "--active" : ""}`}
                onClick={() => {
                  setIsOpenBottomSheet(true);
                }}
              >
                <p className="ellipsis">
                  {selectedContract?.spaceProductName} ({selectedContract?.contractApplyNumber})
                </p>
              </button>
            )}
          </div>
        ) : (
          <>
            <div className="contract-select-building">
              {selectedContract && (
                <button
                  className={`d-none-mo chevron-down-btn text-left ${isOpenDrop ? "--active" : ""}`}
                  onClick={() => {
                    setIsOpenDrop((isOpenDrop) => !isOpenDrop);
                  }}
                >
                  <p className="ellipsis">
                    {selectedContract?.spaceProductName} ({selectedContract?.contractApplyNumber})
                  </p>
                </button>
              )}

              {isOpenDrop && (
                <div className="contract-select-building__dropdown">
                  <h2>상품/계약을 선택하세요</h2>
                  <ul className="building-list">
                    {contracts.map((contract: MemberContractModel, index: number) => (
                      <div
                        key={index}
                        className="building-list__item"
                        onClick={() => {
                          setSelectedContract(contract);
                          setIsOpenDrop(false);
                        }}
                      >
                        <div className="building-list__item-wrap">
                          <div className="building-list__item-info">
                            <p>
                              {contract?.spaceProductName} ({contract?.contractApplyNumber})
                            </p>
                          </div>
                          {selectedContract?.contractId === contract.contractId ? (
                            <div className="building-list__item-checked"></div>
                          ) : (
                            <div className="building-list__item-default"></div>
                          )}
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </>
        )}

        {initializedRef.current && (
          <article className="contract-history-confirmation">
            {billList?.length === 0 ? (
              <section className="contents-empty">
                <p>청구 내역이 없습니다.</p>
              </section>
            ) : (
              <section className="base-section-layout">
                <div className="base-section-layout__container gap20 border-bottom-gray">
                  {billList?.map((billItem, index: number) => (
                    <BillItem key={index} selectedContract={selectedContract} billItem={billItem} />
                  ))}
                </div>
              </section>
            )}
          </article>
        )}

        {isMobile && (
          <BaseBottomSheet
            isOpen={isOpenBottomSheet}
            onClose={() => setIsOpenBottomSheet(false)}
            title={"상품/계약을 선택하세요"}
            className="scrollable"
          >
            <div className="contract-select-sheet">
              <div className="building-list">
                {contracts.map((contract: MemberContractModel, index: number) => (
                  <div
                    key={index}
                    className="building-list__item"
                    onClick={() => {
                      setSelectedContract(contract);
                      setIsOpenBottomSheet(false);
                    }}
                  >
                    <div className="building-list__item-wrap">
                      <div className="building-list__item-info">
                        <p>
                          {contract?.spaceProductName} ({contract?.contractApplyNumber})
                        </p>
                      </div>
                      {selectedContract?.contractId === contract.contractId && <div className="building-list__item-checked"></div>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </BaseBottomSheet>
        )}
      </div>
    </>
  );
};

export default ContractBillList;
