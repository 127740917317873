import { useEffect, useRef, useState } from "react";
import BaseBottomSheet from "./BaseBottomSheet";
import { useToast } from "src/pages/hooks/toast";
import { useLocation } from "react-router-dom";

const BaseShareButton = () => {
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const { openToast } = useToast();
  const location = useLocation();
  const shareRef = useRef<HTMLDivElement>(null);
  const text = `${process.env.REACT_APP_PPOINT_BASEURL}${location.pathname}`;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (shareRef.current && !shareRef.current.contains(event.target as Node)) {
        setShareIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCopyClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      openToast({ content: "주소가 복사되었습니다" });
    } catch (err) {
      console.log(err);
      openToast({ content: "문제가 발생했습니다" });
    }
  };
  return (
    <>
      <button
        className="share-btn"
        onClick={() => {
          console.log("트루", true);
          setShareIsOpen(true);
        }}
      ></button>
      {shareIsOpen && (
        <BaseBottomSheet isOpen={true}>
          <div className="share-bottom-sheet" ref={shareRef}>
            <h2>공유하기</h2>
            <div className="copy-area">
              <p>{text}</p>
              <button className="only-text-btn text-underline font12 text-primary3" onClick={handleCopyClipBoard}>
                주소복사
              </button>
            </div>
          </div>
        </BaseBottomSheet>
      )}
    </>
  );
};
export default BaseShareButton;
