import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiResponse } from "../public-types";
import { Notice, NoticeListParams } from "./notice-types";

/**
 * TET03. 공지사항 목록 조회(최신)
 */
export async function getNoticesAsync(
  axios: AxiosInstance,
  params?: NoticeListParams,
): Promise<AxiosResponse<ApiResponse<{ rows?: Array<Notice> }>>> {
  return await axios.get(`/api/court/et/notices/latest`, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/**
 * TET02. 공지사항 상세
 */
export async function getNoticeAsync(
  axios: AxiosInstance,
  params?: {
    noticeId: string;
  },
): Promise<AxiosResponse<ApiResponse<{ notice?: Notice }>>> {
  if (!params?.noticeId) {
    throw Error("noticeId is not found.");
  }
  return await axios.get(`/api/court/et/notices/${params.noticeId}`);
}

/**
 * TET06. (무인증) 공지사항 목록
 */

export async function getPublicNoticeListAsync(
  axios: AxiosInstance,
  params?: NoticeListParams,
): Promise<AxiosResponse<ApiResponse<{ rows?: Notice[] }>>> {
  return await axios.get(`/api/court/et/public/notices`, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
/**
 * TET07. (무인증) 공지사항 상세
 */

export async function getPublicNoticeDetailAsync(
  axios: AxiosInstance,
  params?: {
    noticeId: string;
  },
): Promise<AxiosResponse<ApiResponse<{ notice?: Notice }>>> {
  if (!params?.noticeId) {
    throw Error("noticeId is not found.");
  }
  return await axios.get(`/api/court/et/public/notices/${params.noticeId}`, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
