import { AxiosInstance, AxiosResponse } from "axios";
import { ContractReservationResponse, ContractReservationTimeParams } from "./facility-type";
import { ApiResponse } from "../public-types";

export async function getFacilityReservationTime(
  axios: AxiosInstance,
  params?: ContractReservationTimeParams,
): Promise<AxiosResponse<ApiResponse<{ status: ContractReservationResponse }>>> {
  return await axios.get(`/api/court/rv/contracts/${params?.contractId}/statuses/${params?.unit}`, { params });
}
