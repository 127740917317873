function assertPositive(value: number, name: string) {
  if (value > 0) {
    return value;
  } else {
    throw new Error(`${name} must be positive`);
  }
}

export default class SingleAsyncCall<T> {
  private isPending = false;
  private value: null | T = null;
  private error: any = null;
  private timeout: number;
  private tick: number;

  constructor(timeout: number = 10000, tick: number = 50) {
    this.timeout = assertPositive(timeout, "timeout");
    this.tick = assertPositive(tick, "tick");
  }

  async execute(asyncFunction: () => Promise<T>) {
    if (this.isPending) {
      return this.wating();
    } else {
      this.isPending = true;
      this.error = null;
      this.value = null;
      try {
        this.value = await asyncFunction.call(undefined);
        return this.value;
      } catch (error: any) {
        this.error = error;
        throw error;
      } finally {
        this.isPending = false;
      }
    }
  }

  private wating() {
    return new Promise<T>((resolve, reject) => {
      let waitTime = 0;
      const interval = setInterval(() => {
        if (waitTime > this.timeout) {
          reject(new Error("timeout"));
        } else if (!this.isPending) {
          if (this.value) {
            resolve(this.value);
          } else if (this.error) {
            reject(this.error);
          } else {
            reject(new Error("unresolved"));
          }
          clearInterval(interval);
        } else {
          waitTime += this.tick;
        }
      }, this.tick);
    });
  }
}
