import numeral from "numeral";

const s3url = process.env.REACT_APP_S3_CONTENT_BASEURL;
export const images = [
  {
    id: 1,
    pic: s3url + "court/mainSlider/court_banner_01.png",
    title: `오피스 임대와 공간 예약\n탭스페이스로 간편하게 해결하세요`,
  },
  {
    pic: s3url + "court/mainSlider/court_banner_02.png",
    id: 2,
    title: `오피스 임대와 공간 예약\n탭스페이스로 간편하게 해결하세요`,
  },
  {
    pic: s3url + "court/mainSlider/court_banner_04.png",
    id: 3,
    title: `탭 스페이스 공간과\n연결되는 새로운 경험 - Taap`,
  },
];

export const defaultParams = {
  page: 0,
  size: 10,
  sort: {
    orders: [
      {
        property: "id",
        direction: "DESC",
      },
    ],
  },
};

export function numberToStringWithComma(num?: number, format: string = "0,0"): string {
  // return (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numeral(num).format(format);
}
