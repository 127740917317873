import React, { useState } from "react";
import { BaseButton } from "src/components";
import FileUploadButtonPopup from "./FileUploadButtonPopup";

interface Props extends React.ComponentProps<typeof BaseButton> {}

function FileUploadButton(props: Props) {
  const [isPopup, setIsPopup] = useState(false);

  const onMouseEnterHandler = () => {
    setIsPopup(true);
  };

  const onMouseLeaveHandler = () => {
    setIsPopup(false);
  };

  const onMouseOverHandler = () => {
    setIsPopup(true);
  };

  return (
    <>
      <div className="ml24 mr24 flex-center-between">
        <div className="center">
          <h1>이용자 초대</h1>
        </div>
        <div className="flex-center mb10">
          <div
            className="excel_upload_info"
            onMouseOver={onMouseOverHandler}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
          ></div>
          <div className="file-upload-button-container">
            <BaseButton title={props.title} onClick={props.onClick} className="ml10 base-btn color-white size-small file-upload-button" />
          </div>
        </div>
      </div>
      {isPopup && (
        <div
          className="tooltip-floating-btn-wrap"
          onMouseOver={onMouseOverHandler}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          <FileUploadButtonPopup isVisible={isPopup} />
        </div>
      )}
    </>
  );
}

export default FileUploadButton;
