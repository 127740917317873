import { Link } from "react-router-dom";
import { useMemberInfoState } from "src/recoil/member/hook";
// 자주묻는질문 링크
export const FREQUENTLY_ASKED_QUESTIONS = "https://courtguide.notion.site/Taap_FAQ-e34841a672d7478d918c2114cd4b99b5";
const BottomLinkList = () => {
  const { member } = useMemberInfoState();
  return (
    <article className="before-login-list">
      <ul>
        <li className="icon icon-notice">
          <Link to="/court/notices">공지사항</Link>
        </li>
        {/* <li className="icon icon-notice">
          <Link to="/court/mypage/main">마이페이지</Link>
        </li> */}
        {/* <li className="icon icon-product">
          <Link to="/court">상품 문의</Link>
        </li>*/}
        {/* <li className="icon icon-call">
          <Link
            to="#"
            onClick={(e: any) => {
              e.preventDefault();
              // window.open(FREQUENTLY_ASKED_QUESTIONS);
            }}
          >
            앱 사용문의
          </Link>
        </li>
        <li className="icon icon-call">
          <Link
            to="#"
            onClick={(e: any) => {
              e.preventDefault();
              window.open(FREQUENTLY_ASKED_QUESTIONS);
            }}
          >
            자주 묻는 질문
          </Link>
        </li>
        <li className="icon icon-call">
          <div className="flex-center-start">
            <p>회원번호</p>
          </div>
          <div className="flex-center-end ">
            <p>{member?.memberNo}</p>
          </div>
        </li> */}
      </ul>
    </article>
  );
};

export default BottomLinkList;
