const SystemError = () => {
  return (
    <div className="error-page">
      <section>
        <div className="error-icon ic-none"></div>
        <div className="error-message-wrap">
          <h2>문제가 발생했습니다</h2>
          <p>
            일시적인 시스템 오류로 인해 페이지를 표시할 수<br />
            없습니다. 잠시후 다시 시도해 주세요
          </p>
        </div>
      </section>
    </div>
  );
};

export default SystemError;
