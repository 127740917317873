type Props = React.ComponentProps<"button"> & {
  isLoading?: boolean;
};

/**
 * 버튼 공통 컴포넌트
 */
const BaseButton = ({ title, type = "button", isLoading, disabled, className, onClick }: Props) => {
  let _className = "login-btn"; // 기본 class
  if (className) {
    _className = className;
  }
  if (isLoading) {
    _className += " --loading";
  }

  return (
    <button type={type} className={_className} disabled={disabled || isLoading} onClick={onClick}>
      <span>{title}</span>
    </button>
  );
};
export default BaseButton;
