import { TaapSpaceBuildingModel } from "src/api/building/building-types";
import MapView from "src/pages/components/MapView";
import BuildingPostDetail from "./BuildingPostDetail";
import BuildingPostCard from "./BuildingPostCard";
import { BaseButton } from "src/components";
import { useState } from "react";

type Props = {
  building: TaapSpaceBuildingModel;
  isOverview?: boolean;
};

const TaapSpaceBuilding = ({ building, isOverview }: Props) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <section className="mb50">
      <BuildingPostCard building={building} />
      {(showDetail || !isOverview) && (
        <>
          <section className="facility-info__facility-info top-divider ">
            <BuildingPostDetail building={building} />
            <div className="horizontal-divider"></div>
            <MapView building={building} />
          </section>
        </>
      )}

      {isOverview && (
        <section className="px24 py0">
          <BaseButton
            title={`${showDetail ? "건물 정보 접기" : "건물 정보 더보기"}`}
            className="base-btn color-white"
            onClick={() => setShowDetail(!showDetail)}
          />
        </section>
      )}
    </section>
  );
};

export default TaapSpaceBuilding;
