import { useNavigate } from "react-router-dom";
import Header from "src/components/layout/Header";

const TermsOfUse = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header
        headerType="CLOSE"
        title="서비스 이용약관"
        onClickCloseButton={() => {
          navigate(-1);
        }}
      />
      <article className="contents-detail">
        <h1 className="base-title">서비스 이용약관</h1>
        <section className="contents-detail__contents">
          {`서비스 이용 약관

핀포인트(주) 서비스를 이용해 주셔서 감사합니다. 핀포인트(주)는 더 나은 고객 만족과 원활한 서비스를 제공하기 위해 아래와 같이 이용 약관을 수립하여 운영합니다. 회원 또는 비회원으로서 서비스를 이용하기 위해서는 약관 및 이용규칙에 대한 동의가 필요하므로 주의 깊게 확인해 주시기 바랍니다.


총칙


제1조. 목적

본 서비스이용약관(이하 “본 약관”)은 “핀포인트 주식회사”(이하 “회사”)가 운영하는 COURT 브랜드를 사용한 COURT App/Web과 Taap 브랜드를 사용한 Taap App/Web 그리고 COURT 오피스 공간을 통해 제공하는 일체의 서비스를 이용함에 있어 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.


제2조. 정의

본 약관에서 사용하는 용어의 정의는 다음 각호와 같습니다.

1. 서비스 : 회사가 COURT 브랜드를 사용한 COURT App/Web과 Taap 브랜드를 사용한 Taap App/Web 그리고 COURT 오피스 공간을 통해 제공하는 일체의 서비스를 모두 아울러 의미합니다.

2. COURT App/Web : 회사가 COURT 브랜드를 통해 서비스를 제공하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 서비스를 이용할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 모바일 애플리케이션 및 웹사이트를 운영하는 사업자의 의미로도 사용합니다.

3. Taap App/Web : 회사가 Taap 브랜드를 통해 서비스를 제공하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 서비스를 이용할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 모바일 애플리케이션 및 웹사이트를 운영하는 사업자의 의미로도 사용합니다.

4. 시간제 기반 오피스 이용 서비스 : 회원이 결제한 시간만큼 COURT 오피스 공간을 이용할 수 있는 서비스를 의미합니다. 

5 COURT 오피스 공간 : 회사가 COURT 브랜드를 사용하여 일체의 업무 환경 및 제반 서비스를 제공하는 오프라인 공간을 의미합니다.

6. 앱 : COURT 브랜드를 사용한 COURT App/Web과 Taap 브랜드를 사용한 Taap App/Web(이하 두가지를 통합하여 일컫는 용어로서 컴퓨터 등 정보통신설비를 이용한 가상의 영업장을 의미합니다. 

7. 비상주 오피스 서비스 : 회사가 회원에게 상주하지 않으며 사업자 등록에 필요한 주소지 발급 등의 제반 환경 등을 제공하는 서비스를 의미합니다. 

8. 회원 : 본 약관 8조에 정해진 절차에 따라 회원가입을 신청하고 회사가 이를 승인함으로써 정상적으로 서비스를 이용할 수 있는 권한을 부여 받은 고객을 의미합니다.

9. 비회원 : 회원등록 없이 서비스를 이용하는 자로서, 회사가 제공하는 일부 서비스 이용에 제한을 받을 수 있습니다.

10. 이용자 : 회사가 제공하는 각종 서비스를 이용하는 자로서 회원 및 비회원을 포함합니다.

11. 아이디(ID) : 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인한 회원 본인의 문자와 숫자의 조합을 의미하며 회원이 설정한 이메일 주소 또는 전화번호 등이 포함됩니다.


제3조. 약관 명시와 개정

1. 회사는 이 약관의 내용과 개인정보관리책임자 등을 회원이 쉽게 알 수 있도록 앱에 게시할 수 있습니다. 

2. 회사는 회원이 약관에 동의하기에 앞서 내용을 회원이 이해할 수 있도록 별도의 안내 화면 등을 제공하여 회원의 확인을 구하여야 합니다.

3. 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
​​
4. 회사가 약관을 변경할 경우에는 적용일자, 변경 사유를 명시하여 적용일자 7일 전부터 제5조에서 정한 방법으로 공지하거나, 회원에게 개별 통지합니다. 회원 혹은 이용자의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 개정 약관의 적용일자 30일 전에 공지하거나, 개별 통지합니다.

5. 회사가 제4항에 따라 개정 약관을 명확하게 공지 또는 통지하면서 회원에게 개정 약관 적용일자까지 거부 의사를 표시하지 아니할 경우 약관의 변경에 동의한 것으로 간주한다는 뜻을 명확하게 고지하였음에도 회원이 개정 약관의 적용일자로부터 7일 이내로 명시적으로 거부 의사를 표시하지 않은 경우 회원이 개정 약관에 동의한 것으로 간주합니다.

6. 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회사 및 회원은 이용계약을 해지할 수 있습니다.


제4조. 약관의 해석 및 약관 외 준칙

1. 회사는 필요한 경우 서비스에 적용될 사항을 위해 운영정책, 이용규칙 등 (이하 “이용 규칙” 등)을 정하여 운영할 수 있습니다. 해당 내용은 앱을 통해 공지, 게시됩니다.

2. 본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 이용 규칙 등에서 정한 바에 따릅니다.

3. 회원은 본 약관 및 이용 규칙 등의 내용에 변경이 있는지 여부를 확인하여야 합니다. 회사는 회원이 이를 확인하지 않아 발생한 손해에 대해서는 책임을 지지 않습니다.


제5조. 회원에 대한 통지

1. 회사는 회원이 서비스 가입 시 등록한 E-mail 주소, SMS, 알림톡 또는 앱을 통한 알림 등을 통해 회원에게 통지할 수 있습니다. 

2. 회사는 불특정 다수 회원에게 통지하고자 하는 경우, 1주일 이상 앱 내 별도 페이지에 공지함으로 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 합니다.


제6조. 개인정보보호

1. 회사는 회원의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.

2. 회사는 회원의 개인정보를 추가로 수집(이용)하는 때에는 해당 회원에게 그 목적을 고지하고 동의를 받습니다.

3. 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용 목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용 제공 단계에서 해당 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.

4. 회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 회원이 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 해당 개인정보를 이용하지 않습니다.

5. 회사는 개인정보 보호를 위하여 회원의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며, 그 기준은 회원에게 서비스를 제공할 수 있는 범위로 합니다. 

6. 회사는 개인정보의 수집 이용 제공에 관한 이용자의 동의 거절 시 제한되는 서비스를 구체적으로 명시하여 이용자의 동의를 구하여야 하며, 필수 수집항목 이외에 개인정보의 수집 이용 제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.

7. 개인정보처리에 관한 구체적인 사항은 회사 개인정보처리방침에 따릅니다.


제7조. 면책 사항

1. 회사는 회원의 서비스 이용 과정에서 발생한 회원, 방문객, 제3자 상호 간에 발생한 분쟁에 대해서는 회사의 과실이 없는 한 개입하지 않으며 책임을 부담하지 않습니다.

2. 회사는 회원, 방문객, 제3자 상호 간의 거래와 관련하여 진정성, 거래 대상의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한 비침해성, 정보의 진실성 또는 적법성 등 일체에 대하여 보증하지 않고, 그와 관련하여 어떠한 책임도 부담하지 않습니다.

3. 회사는 회원, 방문객, 제3자 상호 간의 거래 등의 행위로 손해가 발생하는 등, 회사가 예상할 수 없는 상황에 대하여 책임을 부담하지 않습니다.

4. 회사는 회원의 불법행위 또는 부적절한 방법으로 서비스를 이용하여 발생한 제반 결과(서비스 이용 불능, 각종 사고 등)에 대하여 책임을 부담하지 않습니다.

5. 회사는 다음 각 호로 인해 발생한 회원, 방문객의 손해에 대해서는 배상 책임을 지지 않습니다.

1) 천재지변, 전쟁 및 폭동, 테러 등 국가비상사태, 해킹, DDoS 공격, 전염성질병의 유행 등 기타 불가항력으로 인하여 발생한 손해
2) 계약상 약정된 서비스 이외에 회원의 특별한 요구에 의한 행위로 인하여 발생한 손해
3) 이용 규칙에 따른 공동 이용 구역에 방치된 물품의 임의 조치 또는 처리로 발생한 손해
4) 계약/약관 사항 등의 금지사항 또는 통지한 사항에 대해 회원의 불이행으로 인하여 발생한 손해
5) 누수/누전/균열 등 서비스공간이 위치한 건물/토지의 관리 미흡 등으로 인하여 발생한 손해
6) 회원 또는 방문객 또는 제3자에 의한 도난, 사기, 폭행 등 회사가 예측하기 어려운 상황으로 인해 발생한 회원의 손해
7) 기타 회원 혹은 방문객의 귀책사유로 발생한 손해


제8조. 회원 가입

1. 회원은 앱이 정한 가입 양식에 따라 정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원 가입을 신청합니다.

2. 회사는 다음 각 호에 해당하는 가입신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.

1) 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 단, 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함.
2) 허위의 정보를 기재하거나 회사가 제시하는 내용을 기재하지 않은 경우
3) 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
4) 가입 신청자가 만 14세 미만으로, 법정대리인 동의가 없는 경우 

3. 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 또는 기타 방법으로 회사에 그 변경사항을 알려야 합니다. 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

4. 회원가입은 반드시 본인의 진정한 정보를 통하여만 가입할 수 있으며 회사는 회원이 등록한 정보에 대하여 확인조치를 할 수 있습니다. 회사는 회원 정보의 사실 여부 등에 대한 확인이 완료될 때까지 서비스의 제공을 중단할 수 있고, 타인의 정보를 임의로 사용함으로써 발생하는 회사, 해당 정보의 실제 소유자 등의 손실과 손해에 대한 책임은 회원에게 있습니다.


제9조. 회원의 의무

1. 아이디와 비밀번호에 관한 관리 책임은 회원에게 있습니다.

2. 회원은 자신의 아이디 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.

3. 회원이 자신의 아이디 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내에 따라야 합니다. 회원의 계정 관리 소홀, 부정 사용 등에 의하여 발생하는 모든 책임과 불이익은 회원이 부담하여야 합니다.

4. 회원은 서비스를 이용하기 전에 서비스 이용약관을 확인하여야 합니다. 서비스 이용약관을 확인하지 않고 서비스를 이용하여 발생한 손실, 손해에 대한 책임은 이용자 본인이 부담하여야 합니다.

5. 회원은 회사가 서비스를 안전하게 제공할 수 있도록 협조하여야 하며, 회사가 이용자의 본 약관 위반 등 부적절한 행위를 발견하여 회원에게 해당 행위에 대하여 소명을 요청할 경우 이용자는 회사의 요청에 적극 응하여야 합니다.


제10조. 회원 탈퇴 및 자격 상실

1. 회원은 앱에 언제든지 탈퇴를 요청할 수 있으며 회사는 다음 각호에 따라 회원 탈퇴를 처리합니다. 

1) 회원 탈퇴 시 서비스에 등록돼 있는 회원의 정보는 삭제되며 복구가 불가능합니다. 다만, 결제 정보는 관련 법령에 의거하여 5년간 보관됩니다.
2) 탈퇴 요청 시점에 회원이 이용 중인 상품이 있거나 이용신청 중인 상품이 있는 경우 탈퇴가 불가능하며 회사는 회원과의 1:1 문의를 통해 탈퇴에 관해 안내합니다.

2. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.

1) 가입 시 허위 내용을 등록한 경우
2) 앱을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
3) 서비스 운영에 지장을 초래한다고 판단되는 경우
4) 기타 본 약관의 제18조를 위반했거나 이용 규칙으로 금지한 행위를 한 경우

3. 회사가 회원 자격을 제한, 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회원자격을 상실시킬 수 있습니다.

4. 앱에서 회원자격을 상실하는 경우 서비스 이용 자격 정지 후 30일간의 유예기간 후 회원 등록을 말소합니다. 회원은 유예기간 중 소명이 가능합니다.


제11조. 서비스의 제공 및 변경

1. 회사는 다음과 같은 서비스를 제공합니다.

1) COURT App/Web
① 오피스 매물 등에 관한 정보 제공 서비스
② 오피스 매물 임대 계약 서비스
③ 시간제 기반 오피스 공간 사용 신청 및 결제 서비스 
④ 비상주 오피스 정보 제공 및 신청 서비스 
2) Taap App/Web
① 입출입 제어 및 공간 이용 서비스 
② 시간제 기반 오피스 공간 사용 서비스

2. 회사가 제공하는 위의 서비스는 원칙적으로 상품 중도 변경, 일시 중지, 양도양수가 불가합니다. 단, 이용자의 귀책사유가 아님을 증명할 수 있을 경우 예외적으로 처리될 수 있습니다.

3. 기술적 사양변경 등의 이유로 회사가 제공하는 서비스의 내용을 변경할 경우에는 그 사유를 이용자에게 통지 하거나, 이용자가 알아볼 수 있도록 공지사항으로 게시합니다.


제12조. 서비스의 중단

1. 회사는 컴퓨터 등 정보 통신 설비의 보수 점검, 교체 및 고장, 통신의 두절, 천재지변 등의 사유가 발생한 경우에는 서비스의 제공을 제한하거나 일시적으로 중단할 수 있습니다.

2. 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 이용자에게 통지하거나 이용자가 알아볼 수 있도록 공지 사항으로 게시합니다.


제13조. 사용 요금

1. 회사는 회원의 서비스 이용 시 실수 또는 착오 없이 거래할 수 있도록 해당 서비스의 사용요금, 사용 방법 등의 이용 정보를 제공합니다.

2. 회원의 서비스 이용 시 지급하여야 할 사용요금, 보증금, 수수료, 사용요금 등의 구체적인 내용은 앱에 기재하거나 이용 규칙, 개별 계약서, 개별 서비스의 이용약관을 통해 자세히 확인할 수 있습니다.

3. 사용요금은 이용 가격 및 할인율 변경, 부가서비스 이용, 지연배상금 부과 등으로 계약 갱신 시 변경될 수 있습니다.

4. 회사는 미정산 및 손해배상 비용의 부족분에 대하여 계약 종료 이후에도 회원에게 별도 청구할 수 있습니다.


제14조. 계약 및 결제

1. 회원은 본 계약으로 사용 공간의 사용 권한(시간제 오피스 서비스 이용 시) 또는 비상주 오피스 제반 환경 사용 권한(비상주 오피스 서비스 이용 시) 외 어떠한 권리도 취득하지 않으며, 회원과 회사 양 당사자 간에 제휴, 대리, 보증 등의 관계가 성립하지 아니합니다.

2. 회사는 회원과의 계약이 자동으로 갱신되는 경우 회원이 계약 연장 의사가 있다고 간주하며, 회사의 귀책이 없는 한 환불하지 않습니다.

3. 서비스의 최소 이용 단위 및 이용 기간 산정은 운영 정책 및 개별 계약서 및 개별 서비스의 이용 약관에 따릅니다.


제15조. 계약해지

1. 회원은 언제든지 회사에 이용계약 해지 의사를 통지함으로써 이용계약을 해지할 수 있으며, 계약 해지 세부 조건 및 해지에 따른 환불 여부 및 환불 세부 조건은 이용 규칙, 개별 계약서, 개별 서비스 이용 약관에 따라 다릅니다.

2. 제1항의 회원의 해지 신청에도 불구하고 다음 각 호 중 하나에 해당하는 경우 해지가 제한될 수 있으며, 제한 사유가 해소된 후 해지가 완료됩니다.

1) 회원에게 미납된 사용요금, 지연배상금 및 위약금이 있는 경우
2) 회원의 귀책으로 서비스공간 내외부 시설의 파손, 훼손, 변경 및 기타 손해가 발생한 경우
3) 기타 회원의 귀책으로 회사에 손해가 발생한 경우
4) 기타 운영 정책, 개별 계약서, 개별 서비스의 조항에 저촉이 되는 경우

3. 회사는 제1항에 따른 이용 계약 해지로 인해 발생할 수 있는 불이익 등에 대하여 책임지지 않습니다. 

4. 회원의 귀책사유로 인하여 이용계약이 해지됨으로써 발생한 손해는 당해 회원이 부담하여야 하며, 이는 본 조 제8항으로 인한 일방의 해지인 경우에도 동일합니다.

5. 회사는 회원이 본 약관에 따른 의무를 위반하는 경우 이용을 제한하거나 이용계약을 해지할 수 있고, 이 경우 발생하는 모든 책임은 회원이 부담합니다. 회사는 필요한 경우 회원의 위반행위를 관련 사법기관에 통지하거나 제소, 고소 할 수 있습니다.

6. 회사가 본 조항에 따라 서비스 이용을 제한하거나 이용 계약을 해지하는 경우, 회사는 제5조에서 정한 방법으로 회원에게 통지합니다. 단 부득이한 사유로 사전 통지가 불가능한 경우에는 그 사유가 종료된 후 통지합니다.

7. 회원은 전항의 통지일로부터 7일 이내에 항변 및 소명을 할 수 있고, 회사가 해당 항변 및 소명이 정당하다고 판단할 경우 회사는 서비스 이용 제한 또는 이용계약 해지를 취소할 수 있습니다. 단, 회원이 위 기한 내 항변 또는 소명을 하지 않은 경우, 서비스 이용 제한 또는 이용계약 해지에 동의한 것으로 간주합니다.

8. 다음 각 호의 경우 일방 당사자는 즉시 본 계약을 해지할 수 있습니다.

1) 상대방 당사자가 본 계약 및 약관을 위반하고, 그에 대해 회사가 시정을 요구한 기한 내에 시정하지 않은 경우
2) 상대방 당사자가 파산, 화의 또는 회사 정리 신청이 제출된 경우
3) 상대방 당사자가 자신의 사업수행을 중단한 경우
4) 제3자가 상대방 당사자를 해산 또는 청산하기 위한 절차를 취하거나 결의를 채택하거나 신청할 경우
5) 상대방 당사자의 자산이 압류되거나 그 자산에 대한 경매신청이 있는 경우
6) 어음의 부도 또는 지급불능 절차의 신청 등 당사자의 자산, 신용 또는 사업에 대한 중대한 변화가 있는 경우
7) 본 조에 따른 본 계약의 해지는 일방 당사자가 다른 당사자를 상대로 가지는 기타 권리 및 구제 수단에 영향을 미치지 아니합니다.


제16조. 권리양도 금지

1. 회원은 회사의 사전 서면 동의 없이 본 계약상의 권리 및 의무를 제3자에게 양도 또는 담보로 제공할 수 없으며, 다른 사람으로 하여금 이용하도록 할 수 없습니다.

2. 회사는 회사의 관계 회사나 제3자를 통해 본 계약의 의무를 이행할 수 있습니다.


제17조. 유지 및 관리

1. 회사는 법정공휴일이나 일요일에는 전체 건물의 사용과 제시설의 운전을 중지할 수 있으며, 평일에도 전체 건물 혹은 서비스공간의 보수, 점검 등을 위하여 필요한 범위 내에서 서비스의 사용이나 시설의 운전을 제한할 수 있습니다.

2. 회원 혹은 방문객은 자신에 의해 서비스공간의 시설이 훼손되거나 하자가 발생하였을 경우 즉시 회사에 통지하여야 하며, 회사는 회원에게 교체 또는 복구 비용을 청구할 권리가 있습니다. 


제18조 보안 CCTV의 운영

1. 회사는 회원들의 안전과 보안을 위해 필요한 경우 CCTV를 설치 및 열람할 수 있습니다.

2. 회원은 법령상 허용되는 경우를 제외하고는 CCTV 열람 신청을 할 수 없습니다.


제19조 금지 행위

1. 다음 각호의 행위는 금지되며, 회원은 회원의 방문객 등이 다음과 같은 행위를 하지 않도록 관리·감독하여야 합니다.

1) 인화성 있는 물품, 기타 인체에 유해한 물품을 반입 또는 보관하는 등 회사 및 회원의 안전에 위험을 초래하는 행위
2) 회사의 동의 없이 광고물 등을 설치 및 게시하는 행위
3) 지정된 흡연공간이 아닌 공간(건물 계단 등을 포함하고 이에 한하지 아니함)에서 흡연(전자담배 포함)을 하거나, 악취, 소음, 진동 등을 발생시키는 행위
4) COURT 오피스 공간에 설치된 구조물, 기구, 혹은 시설물을 파손 또는 훼손하거나, 회사의 동의 없이 이동 또는 변경하는 행위
5) 기타 회사나 다른 회원, 회원 관련자, 회원의 방문객 또는 회원의 재산에 위험을 초래하는 행위
6) 회사 및 회사 직원의 전화번호, 이메일 등을 무단으로 사용하여 회사를 사칭하거나 회사의 명예, 이미지를 훼손하는 행위
7) 회사가 제공하는 유/무선 네트워크 외 회원이 별도의 네트워크를 설치하는 행위
8) 타인의 지식 재산을 무단으로 복제하는 등 사용하는 행위 (본 조항은 본 계약 종료 이후에도 적용됩니다.)
9) 회사의 상표, 서비스표, 로고 등 기타 지식 재산을 무단으로 변경, 복사, 사용하는 행위 (본 조항은 본 계약 종료 이후에도 적용됩니다.)
10) 회의실 및 부대시설을 예약 없이 사용하는 행위
11) 기타 다른 회원의 공간 이용을 방해하거나 다른 회원에게 불쾌감 또는 혐오감을 주는 행위
12) 그 외 운영 정책으로 정한 금지 행위

2. 회원이 제1항의 금지사항을 위반하였을 경우, 회사는 회원에게 기한을 정하여 시정요청을 하거나 서면경고를 줄 수 있으며, 이러한 시정 요청을 받은 회원은 요청받은 기한 내 위반사항을 시정하지 않은 경우, 회원의 서비스 이용 제한되거나 또는 계약이 해지될 수 있습니다. 


제20조 COURT 오피스 공간의 원상회복

1. 회원은 본 계약 기간이 종료됨과 동시에 회사의 허락 없이 설치한 물건 등을 철거해야 하며 가구, 비품 등 회원 소유의 물건을 즉시 반출해야 합니다.

2. 계약기간 내 회사와 협의하에 인테리어를 변경하거나 시설을 설치한 경우 회원은 본 계약 기간이 종료됨과 동시에 계약 전 상태로 원상회복을 해야 합니다.

3. 회원이 전항의 원상회복의무를 하지 않을 경우 회사가 대신 지출한 원상회복 비용을 전부 배상해야 합니다.

4. 회사는 회원과 별도의 협의가 없는 경우에는 본 계약 기간 종료 후에 남아 있는 회원의 소유물에 대한 어떠한 보관 의무가 없으며 분실 시 이를 보상할 의무가 없습니다.

5. 회원이 이용 종료 후 남기고 간 물건에 대한 수령 고지를 2회 이상 통지하였으나 이를 찾아가지 않을 경우 폐기 가능합니다. 발생한 폐기물을 계약 종료일로부터 7일 이내 수거하지 않을 시, 폐기 관련 비용 및 보관비용이 부과될 수 있습니다.

6. 계약 종료 후 회원의 우편물이 COURT 오피스 공간으로 송달되는 경우 회사는 우편물에 대한 보관 의무가 없으므로 이를 반송하거나 폐기할 수 있습니다. 


제21조. 손해배상

1. 회원, 방문객 기타 회원의 관련자가 고의 또는 과실로 COURT 오피스 공간이 소재한 건물 자체, COURT 오피스 공간 내 시설, 회사나 다른 회원 등 기타 제3자의 재산을 훼손하거나 파손하는 경우 또는 회사 임직원, 다른 회원, 다른 회원의 방문객 등 제3자의 생명, 신체에 손해를 발생시킨 경우, 회원은 즉시 회사에 그러한 사실을 통지하고 발생한 재산상, 신체상 피해를 원상복구 및/또는 그에 대한 손해를 배상하여야 합니다.

2. 회원, 회원 관련자 및 회원이 초대한 방문객들 간에 다툼, 성희롱 등을 포함한 상호 마찰이 발생하는 경우, 회사는 위 다툼에 관여·중재하지 않으며, 다툼으로 인하여 발생한 손해는 전적으로 회원 및 다툼의 당사자가 책임집니다.

3. 회원은 본 계약이 종료된 후 무단으로 COURT 오피스 공간 및 서비스를 이용할 수 없으며, 이를 위반한 경우, COURT 오피스 공간 8시간 이용 금액의 10배를 손해배상액의 예정액으로 회사에 배상하여야 합니다. 

										
기타							


제22조 (가분성)

1. 본 계약의 일부 조항이 무효 또는 불법이거나 집행이 불가능하다고 인정되는 경우에도 나머지 조항들의 효력에는 영향을 미치지 않습니다.				


제23조. 준거법 및 관할법원

1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.

2. 회사와 회원 간 발생한 분쟁에 관한 소송의 제1심은 서울중앙지방법의 관할로 합니다. 


본 약관의 시행일은 2022년 6월 30일로 합니다.

            `}
        </section>
      </article>
    </div>
  );
};

export default TermsOfUse;
