import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
};

export default function ScrollToTop({ containerRef }: Props) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (containerRef.current) {
      // router 이동시 화면 스크롤 위로 이동
      containerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [pathname]);
  return null;
}
