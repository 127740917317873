import { BuildingCommonFacilityDeskGroupModel, BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { MediaFile } from "src/api/file/file-types";

// 대표 건물 찾기
// 대표 건물 : buildingList > buildingFloorList > buildingRoomList > isPrimary : true 인 buidling
export const findPrimaryBuilding = (buildingList: BuildingModel[]) => {
  for (let forBuilding of buildingList) {
    if (forBuilding?.buildingFloorList) {
      for (let floor of forBuilding?.buildingFloorList) {
        if (floor.buildingRoomList) {
          for (let room of floor.buildingRoomList) {
            if (room.isPrimary) return forBuilding;
          }
        }
      }
    }
  }
  return null;
};

// 대표건물 소팅 (가장 앞으로)
export const sortedPrimaryFirstBuilding = (buildingList: BuildingModel[]) => {
  const primary = findPrimaryBuilding(buildingList);
  if (primary) {
    const removePrimaryPuilding = buildingList.filter((building) => building.id !== primary.id);
    const reAddFirstPrimaryBuilind = [primary, ...removePrimaryPuilding];
    return reAddFirstPrimaryBuilind;
  }
};

// 건물을 건물명 기준 오름차순으로 소팅
export const sortedByBuildingName = (buildingList: BuildingModel[]) => {
  return buildingList.sort((a, b) => a.buildingName!.localeCompare(b.buildingName!));
};

// 공용공간을 공용공간명 기준 오름차순으로 소팅
export const sortedByFacilityName = (
  facilityList: (CommonFacilityModel[] & BuildingCommonFacilityDeskGroupModel[] & CommonFacilityModel[]) | undefined,
) => {
  return facilityList?.sort((a, b) => a.facilityName!.localeCompare(b.facilityName!));
};

// 이미지 - 대표 이미지가 첫번째로 노출되도록 sorting
export const mediaSorting = (files: MediaFile[]) => {
  const sortedMedias = files.sort((a: MediaFile, b: MediaFile) => {
    // isPrimary가 true인 요소를 맨 앞으로 이동
    if (a.isPrimary && !b.isPrimary) {
      return -1;
    }
    if (!a.isPrimary && b.isPrimary) {
      return 1;
    }
    // isPrimary가 동일하면 orderNums에 따라 정렬
    return a.orderNums! - b.orderNums!;
  });
  return sortedMedias;
};

export const officeFirstMediaSorting = (files: MediaFile[]) => {
  const officeMediaFiles = files.filter((file) => file.mediaServiceType === "MEDIA_PRODUCT_OFFICE");
  const primaryOfficeMediaFiles = officeMediaFiles.filter((file) => file.isPrimary);
  const secondaryOfficeMediaFiles = officeMediaFiles.filter((file) => !file.isPrimary);

  return [...primaryOfficeMediaFiles, ...secondaryOfficeMediaFiles, ...files.filter((file) => file.mediaServiceType !== "MEDIA_PRODUCT_OFFICE")];
};
