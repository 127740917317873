import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useParams, useNavigate } from "react-router-dom";
import { getPublicProductGuideAsync, getPublicProductGuidesAsync } from "src/api/guide/guide-api";
import { ProductGuideDetail } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import { Controller, useForm } from "react-hook-form";
import { getPublicOrganizationAsync } from "src/api/organization/organization-api";
import { getExternalVisitorAsync, getExternalVisitorQrAsync, patchConsentAgreeAsync } from "src/api/visitor/visitor-api";
import { Visitor, VisitorQr } from "src/api/visitor/visitor-types";
import { BaseButton, BaseModal, BaseTextInput, ConfirmModal } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import CompanyLogo from "../components/CompanyLogo";
import MapGuide from "../components/MapGuide";
import ParkingGuide from "../components/ParkingGuide";
import WifiGuide from "../components/WifiGuide";
import { ConsentAgree, Modal, VisitType } from "../visitor-types";
import UseGuide from "../components/UseGuide";
import BaseAbstractModal from "src/components/BaseAbstractModal";
import { onlyNumber } from "src/utils/common-util";
import Header from "src/components/layout/Header";
import usePostMessage from "src/pages/hooks/post-message";

// 방문자 에러 상태
type VisitorErrorState = {
  errorCode: string;
};

type FormData = {
  lastPhoneNumber: string;
  isAgree: boolean;
};
/**
 * 방문자 초대 QR 코드 상세 웹뷰 화면
 */
const VisitorDetail = () => {
  const initializedRef = useRef(false);
  const navigate = useNavigate();
  const params = useParams();

  // (무인증) 방문자 QR 조회 api
  const { executeAsync: getVisitorQr } = useApiOperation(getExternalVisitorQrAsync, { noAuthenticationRequired: true, noHandleError: true });

  // (무인증) 방문자 초대내역 정보 조회 api
  const { executeAsync: getVisitor } = useApiOperation(getExternalVisitorAsync, { noAuthenticationRequired: true, noHandleError: true });

  // (무인증) 회사 정보 조회 api
  const { executeAsync: getOrganization } = useApiOperation(getPublicOrganizationAsync, { noAuthenticationRequired: true, noHandleError: true });

  // (무인증) 공간상품 사용안내 목록조회 api
  const { executeAsync: getPublicProductGuides } = useApiOperation(getPublicProductGuidesAsync, {
    noAuthenticationRequired: true,
    noHandleError: true,
  });

  // (무인증) 공간상품 사용안내 상세조회 (new) api
  const { executeAsync: getPublicProductGuide } = useApiOperation(getPublicProductGuideAsync, {
    noAuthenticationRequired: true,
    noHandleError: true,
  });

  // (무인증) 방문자용 개인정보 이용 약관 동의 저장
  const { executeAsync: patchConsentAgree } = useApiOperation(patchConsentAgreeAsync, { noAuthenticationRequired: true, noHandleError: true });

  const { postMessageGoBackNavigation } = usePostMessage();
  // 회사로고
  const [companyLogo, setCompanyLogo] = useState<string>("");

  // 방문자 QR
  const [visitorQr, setVisitorQr] = useState<VisitorQr>();

  // 방문자 초대 상세
  const [visitor, setVisitor] = useState<Visitor>();

  // 공간상품 사용안내 목록
  const [productGuides, setProductGuides] = useState<ProductGuideDetail[]>([]);

  // 확인 버튼만 있는 알림 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 방문자 에러 상태
  const [visitorErrorState, setVisitorErrorState] = useState<VisitorErrorState>();

  // 개인정보 동의 모달
  const [isAgreedToTermsModal, setIsAgreedToTermsModal] = useState<Boolean>(false);

  // 개인정보 이용약관 내용 모달
  const [isPrivacyPolicyModal, setIsPrivacyPolicyModal] = useState<Boolean>(false);

  // 개인정보 동의 호출실패 안내문구
  const [isFailedConsentAgree, setIsFailedConsentAgree] = useState<Boolean>(false);

  // 방문자 QR 조회
  const fetchVisitorQr = useCallback(
    async (visitorKey: string) => {
      try {
        const { data } = await getVisitorQr({ visitorKey });
        setVisitorQr(data?.data?.qr);
      } catch (error: any) {
        setAlertModal({ isOpen: true, message: "에러가 발생했습니다." });
      }
    },
    [getVisitorQr],
  );

  // 회사 정보 조회
  const fetchOrganization = useCallback(
    async (organizationId: number) => {
      try {
        const { data } = await getOrganization({ organizationId });
        if (data?.data?.mediaList && data?.data?.mediaList.length > 0) {
          const url = data.data.mediaList[0].url || "";
          if (url) {
            // 회사 로고
            setCompanyLogo(url);
          }
        }
      } catch (error: any) {
        // 회사 로고는 없으면 기본으로 적용하기 때문에 에러 핸들링 하지 않음
        console.error("fetchOrganization error", error.response);
      }
    },
    [getOrganization],
  );

  // (무인증) 공간상품 사용안내 목록조회 후 상세조회해서 목록 바인딩
  const fetchPublicProductGuides = useCallback(
    async (productId: number | string, buildingId: number | string) => {
      try {
        const { data } = await getPublicProductGuides({ productId, buildingId });

        if (data?.data?.content || [].length > 0) {
          // 상세 조회
          const promises = (data?.data?.content || []).map((productGuide) => getPublicProductGuide({ guideId: productGuide.id! }));
          const results = await Promise.all(promises);
          const _productGuides: ProductGuideDetail[] = results.map(({ data }) => data?.data?.content);
          setProductGuides(_productGuides);
        }
      } catch (error: any) {
        setAlertModal({ isOpen: true, message: "에러가 발생했습니다." });
      }
    },
    [getPublicProductGuides, getPublicProductGuide],
  );

  // 방문자 초대내역 정보 조회
  const fetchVisitor = useCallback(
    async (visitorKey: string) => {
      try {
        const { data } = await getVisitor({ visitorKey });
        const visitor: Visitor = data?.data;
        setVisitor(visitor);
        if (visitor.mbOrganizationId) {
          // 회사 정보 조회
          fetchOrganization(visitor.mbOrganizationId);
        }
        if (visitor.spaceProductId && visitor.buildingId) {
          // 공간상품 사용안내 목록조회 (무인증)
          fetchPublicProductGuides(visitor.spaceProductId, visitor.buildingId);
        }
        if (visitor.consentAgree === ConsentAgree.DENIED) {
          // 개인정보 비동의 일 경우, 모달오픈
          setIsAgreedToTermsModal(true);
        }
      } catch (error: any) {
        if (error?.response) {
          // 방문자 에러 상태
          setVisitorErrorState({ errorCode: error?.response?.data?.meta?.errorCode });
        } else {
          setAlertModal({ isOpen: true, message: "에러가 발생했습니다." });
        }
      }

      if (!initializedRef.current) {
        initializedRef.current = true;
      }
    },
    [getVisitor, fetchOrganization, fetchPublicProductGuides],
  );

  // 방문일시 ex) 3월 21일 오후 2:00 - 오후 3:00
  const visitDateTime = useMemo(() => {
    if (!visitor) return "";
    let result = "";
    const { visitStartTime, visitEndTime } = visitor;
    const isSameDay = moment(visitStartTime).isSame(visitEndTime, "day");
    // 시작일시
    result += moment(visitStartTime).format("YYYY년 M월 D일");
    result += moment(visitStartTime).format(" A");
    result += moment(visitStartTime).format(" h:mm");
    result += " -";
    if (!isSameDay) {
      // 같은날이 아니면 종료일 추가
      result += moment(visitEndTime).format(" M월 D일");
    }
    // 종료시간
    result += moment(visitEndTime).format(" A");
    result += moment(visitEndTime).format(" h:mm");
    return result;
  }, [visitor]);

  const defaultValues = useMemo(() => {
    return {
      lastPhoneNumber: "",
      isAgree: false,
    };
  }, []);

  const { handleSubmit, register, control, watch } = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (params && params.visitorKey) {
      fetchVisitorQr(params.visitorKey);
      fetchVisitor(params.visitorKey);
    }
  }, [fetchVisitorQr, fetchVisitor, params]);

  // QR 코드 노출 여부
  const isVisibleVisitorQr = useMemo(() => {
    if (!visitorQr || !visitorQr?.cardSerialNumber) return false;
    if (moment().isBefore(moment(visitor?.visitStartTime).subtract(30, "minute"))) {
      // 현재시간이 방문시작시간 30분이전이면 비노출
      return false;
    }
    return true;
  }, [visitor, visitorQr]);

  const onSubmit = async (formData: FormData) => {
    if (params && params.visitorKey) {
      try {
        const response = await patchConsentAgree({ visitorKey: params.visitorKey, inputNumber: formData.lastPhoneNumber });
        if (response.status === 200 && response.data?.data.data.userKey) {
          setIsFailedConsentAgree(false);
          setIsAgreedToTermsModal(false);
        }
      } catch (error: any) {
        if (error?.response) {
          const errorCode = error?.response?.data?.meta?.errorCode;
          if (errorCode === "eCT602") {
            setIsFailedConsentAgree(true);
          }
        }
      }
    }
  };
  console.log("visitor", visitor);

  const onError = () => {};

  if (!initializedRef.current) return null;

  // 방문자 삭제되었을때 신규 대응
  if (visitor?.visitStatus === "VISIT_DELETE") {
    // 방문 초대가 취소되었습니다. (방문자삭제여부: {0})
    return (
      <>
        <MetaTag title="방문자 이용 안내" />
        <div className="visitor">
          <CompanyLogo />
          <div className="visitor__invalid-page">
            <div className="error-img"></div>
            <p>방문 초대가 취소되었습니다</p>
          </div>
        </div>
      </>
    );
  }

  // 방문자 에러 상태
  if (visitorErrorState) {
    if (visitorErrorState.errorCode === "eCT506") {
      // 방문 초대가 취소되었습니다. (방문자삭제여부: {0})
      return (
        <>
          <MetaTag title="방문자 이용 안내" />
          <div className="visitor">
            <CompanyLogo />
            <div className="visitor__invalid-page">
              <div className="error-img"></div>
              <p>방문 초대가 취소되었습니다</p>
            </div>
          </div>
        </>
      );
    } else if (visitorErrorState.errorCode === "eCT505") {
      // 방문시간이 종료되었습니다. (방문종료일시: {0})
      return (
        <>
          <MetaTag title="방문자 이용 안내" />
          <div className="visitor">
            <CompanyLogo />
            <div className="visitor__invalid-page">
              <div className="error-img"></div>
              <p>
                방문기간이 지나
                <br />
                유효하지 않은 페이지입니다.
              </p>
            </div>
          </div>
        </>
      );
    } else if (visitorErrorState.errorCode === "eCT507") {
      // [Self 방문자초대 만료] 방문시간이 종료되었습니다. (방문종료일시: {0})
      return (
        <>
          <MetaTag title="방문자 이용 안내" />
          <div className="visitor">
            <CompanyLogo />
            <div className="visitor__invalid-page">
              <div className="error-img"></div>
              <p>
                방문 시간이 종료되어
                <br />
                더이상 유효하지 않은 페이지입니다
              </p>
              <p className="font15 pt10 font-weight-400">
                방문 시간 연장이 필요한 경우
                <br />
                셀프 방문자 등록을 추가로 진행해 주세요
              </p>
            </div>
          </div>
        </>
      );
    } else if (visitorErrorState.errorCode === "eCT501") {
      // {0} 정보를 찾을 수 없거나 삭제되었습니다. (아이디: {1})
      return (
        <>
          <MetaTag title="방문자 이용 안내" />
          <div className="visitor">
            <CompanyLogo />
            <div className="visitor__invalid-page">
              <div className="error-icon ic-question"></div>
              <p>
                방문자 초대 정보를 찾을 수 없거나
                <br />
                삭제되었습니다
              </p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <MetaTag title="방문자 이용 안내" />
          <div className="visitor">
            <CompanyLogo />
            <div className="visitor__invalid-page">
              <div className="error-img"></div>
              <p>
                이 페이지가 맞나요? <br />
                정확한 주소인지 다시 한 번 확인해주세요
              </p>
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <MetaTag title="방문자 이용 안내" />
      <div className="visitor">
        <CompanyLogo />
        <article className="visitor__intro">
          <h1>
            {visitor?.contractName ? visitor.contractName : visitor?.spaceProductName}
            <br />
            방문을 환영합니다
          </h1>
          <p>방문일시: {visitDateTime}</p>
        </article>
        <article className="visitor__qr pb40">
          <div className="qr-container pt40">
            <div className="qr-container__container">
              <div className="frame-overlay">
                <div className="frame-element top-left"></div>
                <div className="frame-element top-right"></div>
                <div className="frame-element bottom-left"></div>
                <div className="frame-element bottom-right"></div>
              </div>
              <div className="qr-container__wrap">
                {isVisibleVisitorQr ? (
                  <QRCode value={visitorQr?.cardSerialNumber!} size={200} />
                ) : (
                  <>
                    {!visitorQr || !visitorQr?.cardSerialNumber ? (
                      // 오류일때
                      <div className="waiting-qr">
                        <button onClick={() => window.location.reload()}>다시 시도하기</button>
                      </div>
                    ) : (
                      // 방문 30분전
                      <div className="waiting-qr">
                        <div>
                          <p>QR코드 생성 대기 중...</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {isVisibleVisitorQr ? (
              <>
                <p className="main-text">
                  {visitor?.visitType === VisitType.SELF_REGIST ? "출입 단말기에 QR코드를 스캔 하세요." : "출입 단말기에 QR코드를 Taap 하세요."}
                </p>
                <p className="sub-text">
                  {visitor?.visitType === VisitType.SELF_REGIST ? "방문 일시 동안 출입이 가능합니다." : "방문일시 30분전 부터 입장이 가능합니다."}
                </p>
              </>
            ) : (
              <>
                {!visitorQr || !visitorQr?.cardSerialNumber ? (
                  // 오류일때
                  <>
                    <p className="main-text">출입 단말기에 QR코드를 Taap 하세요.</p>
                    <p className="sub-text">방문일시 30분전 부터 입장이 가능합니다.</p>
                  </>
                ) : (
                  // 방문30분전
                  <>
                    <p className="main-text">방문일시 30분 전부터 QR 코드 확인이 가능합니다.</p>
                  </>
                )}
              </>
            )}
          </div>
        </article>
        <article className="visitor__info">
          {visitor?.visitType === VisitType.SELF_REGIST ? (
            <>
              <UseGuide visitor={visitor} />
            </>
          ) : (
            <>
              {/* 오시는 길 */}
              <MapGuide visitor={visitor} isChangeLanguage={true} />

              {/* 주차 안내 */}
              <ParkingGuide visitor={visitor} fetchVisitor={fetchVisitor} productGuides={productGuides} />
              {/* 와이파이 이용안내 */}
              <WifiGuide productGuides={productGuides} />
            </>
          )}
        </article>
      </div>
      {isAgreedToTermsModal && (
        <BaseAbstractModal isOpen={true} className="visitor-agree-terms-modal">
          <h1>방문을 환영합니다!</h1>
          <p>안전한 비회원 방문 초대 서비스 이용을 위해 초대자의 정보 확인 및 개인정보 이용 동의가 필요합니다.</p>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Controller
              control={control}
              name="lastPhoneNumber"
              render={({ field: { name, value, onChange }, fieldState: { error, isDirty } }) => (
                <div className="input-wrap">
                  <input
                    name={name}
                    value={onlyNumber(value)}
                    type="text"
                    onBlur={(e) => {
                      onChange && onChange(e.target.value.trim(), e);
                    }}
                    onChange={onChange}
                    placeholder="초대받은 휴대폰번호의 뒷 4자리"
                    maxLength={4}
                  />
                  {isFailedConsentAgree && <p>초대받은 휴대폰 번호 뒷 4자리 확인해주세요.</p>}
                </div>
              )}
            ></Controller>
            <Controller
              control={control}
              name="isAgree"
              render={({ field: { name, value, onChange }, fieldState: { error, isDirty } }) => (
                <>
                  <input className="checkbox" type="checkbox" id="2" onChange={onChange} checked={value} />
                  <label className="font14 flex-center">
                    <div
                      className="base-checkbox"
                      onClick={() => {
                        onChange(!value);
                      }}
                    ></div>
                    <p className="ml7 font15">
                      <span onClick={() => {}} className="text-primary3">
                        (필수)
                      </span>{" "}
                      <span
                        onClick={() => {
                          setIsPrivacyPolicyModal(true);
                        }}
                        className="text-underline"
                      >
                        개인정보 이용약관
                      </span>
                      에 동의합니다.
                    </p>
                  </label>
                </>
              )}
            ></Controller>

            <button
              disabled={watch("lastPhoneNumber").length < 4 || !watch("isAgree")}
              className="minmax200 mt30 mx-auto base-btn size-small"
              type="submit"
            >
              동의 후 QR출입증 확인하기
            </button>
          </form>
        </BaseAbstractModal>
      )}
      {isPrivacyPolicyModal && (
        <BaseAbstractModal className="visitor-privacy-policy-modal" isOpen={true}>
          <h1>방문 및 출입 관리를 위한 개인정보 취급방침</h1>
          <p>
            핀포인트에서 제공하는 방문초대 서비스 이용과 관련하여 아래와 같이 고객님의 개인정보를 수집 • 이용하기 위하여 개인정보보호법 제 15조, 제
            22조에 따라 동의를 구합니다. <br />
            <br />
          </p>
          <h2>개인정보 수집 이용 내역</h2>
          <ul className="base-list">
            <li>필수 항목 : 이름, 휴대폰 번호 </li>
            <li>수집 목적 : Taap 방문 초대 서비스 이용자 식별과 카카오톡을 통한 방문 출입 QR 알림톡 발송</li>
            <li>
              보유 기간 : 서비스 이용 완료 후 최대 3개월(관련 문의 응대 완료 후 폐기)*통신비밀보호법에 의하여 고객님의 개인정보를 보관할 필요성이 있는
              경우에는 위와 같이 개인정보를 보관할 수 있으며 해당 개인정보는 보관의 목적으로만 이용합니다.{" "}
            </li>
          </ul>
          <br />
          <h2>개인정보의 위탁 처리 </h2>
          <ul className="base-list">
            <li>수탁자 : (주)링크허브</li>
            <li>위탁 내용 : 카카오톡 알림톡 발송 업무</li>
            <li>제공 항목 : 휴대폰 번호</li>
          </ul>
          <br />
          <p>
            귀하는 위와 같이 개인정보를 수집•이용하는데 동의를 거부할 권리가 있습니다. 단, 동의를 거부할 경우 서비스 이용이 제한되어 방문 출입이
            불가함을 알려드립니다.
          </p>
          <button onClick={() => setIsPrivacyPolicyModal(false)} className="minmax100 mt30 mx-auto base-btn size-small" type="button">
            확인
          </button>
        </BaseAbstractModal>
      )}

      <ConfirmModal isOpen={alertModal.isOpen} btnRightTitle={"확인"} onClick={() => setAlertModal({ isOpen: false })}>
        {alertModal.message || ""}
      </ConfirmModal>
    </>
  );
};
export default VisitorDetail;
