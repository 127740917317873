import { ReactChild, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface state {
  isOpen: boolean;
  title: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  type?: string;
  className?: string;
}
/*
  BaseModal
  타이틀영역에 x버튼이 존재하는 모달
*/
const BaseModal = (props: state) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef<HTMLElement | null>(null);
  useEffect(() => {
    ref.current = document.getElementById("modal");
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!props.isOpen) return null;

  return mounted
    ? createPortal(
        <>
          <div className="dim">
            <div className={`base-modal ${props.className ? props.className : ""}`}>
              <div className="base-modal__title">
                <span>{props.title}</span>
                <button className="close-btn" onClick={props.onClose}></button>
              </div>
              <div className="base-modal__contents">{props.children && <>{props.children}</>}</div>
            </div>
          </div>
        </>,
        ref.current!,
      )
    : null;
};

export default BaseModal;
