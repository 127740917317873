import { createContext } from "react";

export type CourtAuthValue = {
  // 인가 요청(인증 서버 로그인 페이지로 이동)
  authorize: (params?: AuthorizeOptions) => void;
  // 인증 갱신 처리
  refresh: (params: { token?: TokenResponse; error?: any }) => void;
  // 인증 취소 처리
  revoke: () => void;
  // 인증 옵션
  options: CourtAuthOptions;
  // 보안 객체
  security: CourtSecurity;
};

export type AuthorizeOptions = {
  // history 에서 삭제 여부
  replace?: boolean;
  // login 이면 인증 서버의 세션과 관계 없이 로그인 폼 출력
  // none 이면 idTokenHint 를 통한 인증
  prompt?: "login" | "none";
  // 교차 클라이언트 인층 처리 토큰
  id_token_hint?: string;
  // state
  state?: string;
};

export type TokenResponse = {
  // 토큰 타입(Bearer)
  tokenType: string;
  // 접근 JWT
  accessToken: string;
  // 토큰 만료 시간
  expiresIn: number;
  // 아이디 JWT
  idToken: string;
  // 갱신 토큰
  refreshToken: string;
  // 권한 범위 목록
  scope: string;
};

export type CourtAuthStatus =
  // 처음 진입 상태
  | "none"
  // 인증 완료 상태
  | "authenticated"
  // 인증 실패 상태
  | "unauthenticated"
  // 인증 토큰 요청 상태
  | "pending"
  // 인증 실패 상태
  | "error";

export type CourtSecurity = {
  // 상태
  status: CourtAuthStatus;
  // 로그인 사용자 정보
  user?: CourtUser;
  // id_token 클레임 정보
  idToken?: OAuth2Jwt<CourtAuthIdTokenPayload>;
  // refresh_token
  refreshToken?: string;
  // 에러
  error?: any;
};

export type CourtUser = {
  // 회원 번호
  memberNo: string;
  // 휴대폰 번호
  phoneNumber: CourtUserVerification;
  // 이메일
  email: CourtUserVerification;
  // 회원 권한 목록
  roles: CourtUserRole[];
  // 상태 목록
  states: CourtUserState[];
};

export type CourtUserVerification = {
  // 검증 값
  value: string;
  // 검증 여부
  isVerified: boolean;
};

export type OAuth2Jwt<T extends CourtAuthAccessTokenPayload | CourtAuthIdTokenPayload = any> = {
  header: CourtAuthJwtHeader;
  payload: T;
};

export type CourtAuthJwtHeader = {
  // 키 아이디
  kid: string;
  // 키 알고리즘
  alg: string;
};

export type CourtAuthAccessTokenPayload = {
  // 회원 번호
  sub: string;
  // 클라이언트 아이디
  aud: string;
  // 발급 주체
  iss: string;
  // 권한 범위 목록
  scope: string;
  // 토큰 발급 시간
  iat: number;
  // 토큰 만료 시간
  exp: number;
};

export type CourtAuthIdTokenPayload = {
  // 회원 번호
  sub: string;
  // 클라이언트 아이디
  aud: string;
  // 발급 주체
  iss: string;
  // 이메일
  email?: string;
  // 이메일 인증 여부
  email_verified?: boolean;
  // 휴대폰 번호(E.164 포맷)
  phone_number?: string;
  // 휴대폰 번호 인증 여부
  phone_number_verified?: boolean;
  // 토큰 발급 시간
  iat: number;
  // 토큰 만료 시간
  exp: number;
  // 사용자 인증 시간
  auth_time: number;
  // 사용자 정보 마지막 수정 시간
  updated_at: number;
  // 사용자 디바이스 식별 정보
  device_unique_id?: string;
  // 사용자 권한 목록
  roles: CourtUserRole[];
  // 회원 상태 목록
  states: CourtUserState[];
};

export type CourtUserRole =
  // 휴대폰 인증 후 회원 가입 완료 전 상태의 사용자
  | "ROLE_STARTER"
  // 일반 사용자
  | "ROLE_MEMBER";

export type CourtUserState = {
  // 회원 상태 타입
  status: CourtUserStateStatus;
  // 회원 상태 적용 일시
  time: number;
};

export type CourtUserStateStatus =
  // 회원 가입 필요 상태
  | "REQUIRE_JOIN"
  // 이미 가입 된 휴대폰 번호와 다른 디바이스 식별 정보로 휴대폰 점유 인증을 시도 하는 상태
  | "ALREADY_EXISTS_PHONE_NUMBER"
  // 회원 가입 상태
  | "JOINED"
  // 이메일 인증 상태
  | "EMAIL_VERIFIED"
  // 휴대폰 점유 인증 상태
  | "PHONE_NUMBER_VERIFIED"
  // 마케팅 동의 상태
  | "MARKETING_CONSENT"
  // 마케팅 비 동의 상태
  | "MARKETING_CONSENT_DISAGREE"
  // 계정 연결 대기 상태
  | "WAITING_FOR_RECOVERY";

export type CourtAuthState = {
  // 인증 후 리다이렉트 경로
  returnTo?: CourtAuthReturnToState;
};

export type CourtAuthReturnToState = {
  // 최근 등록 리턴 정보
  latest: CourtAuthReturnTo;
  // 등록 리턴 정보 히스토리 목록
  history: CourtAuthReturnTo[];
};

export type CourtAuthReturnTo = {
  // 되돌아 가야 하는 URI
  uri: string;
  // 상태 등록 된 시점(Unix time ephoch seconds)
  time: number;
};

export type CourtAuthOptions = {
  // Court 인증 발급자
  issuer: string;
  // Court 클라이언트 아이디
  clientId: string;
  // 인가 요청에 쓰이는 URL
  endpoints: {
    // 인가 요청 URL
    authorize: string;
    // 토큰 요청 URL
    token: string;
    // 토큰 삭제 요청 URL
    revoke: string;
  };
  // 인증 후 리다이렉트 URL(인증 서버에서 code, state 파라미터가 포함 되어 콜백)
  redirectUri: string;
  // 인가 요청 권한 범위 목록(space ' ' 로 구분해서 나열)
  scope: string;
  // 리다이렉트 되었을 때 호출
  onRedirect: (state: CourtAuthState) => void;
  // 에러 발생 시 호출
  onError?: (error: any) => void;
  // 로딩 시 출력 컴포넌트
  pending?: JSX.Element;
};

export interface CourtAuthError extends Error {
  // 에러 코드
  code: CourtAuthErrorCode;
}

export interface CourtAuthErrorConstructor {
  new (code: CourtAuthErrorCode, message?: string): CourtAuthError;
  (code: CourtAuthErrorCode, message?: string): CourtAuthError;
}

export class CourtAuthError extends Error {
  code: CourtAuthErrorCode;

  constructor(code: CourtAuthErrorCode, message?: string) {
    super(message);
    this.code = code;
  }
}

export type CourtAuthErrorCode =
  // 스토리지에 토큰 응답을 찾을 수 없음
  | "storage::no_token_response"
  // 유효하지 않은 요청
  | "oauth2::invalid_request"
  // 유효하지 않은 클라이언트
  | "oauth2::invalid_client"
  // 유효하지 않은 권한 부여 타입
  | "oauth2::invalid_grant"
  // 인가 되지 않은 클라이언트
  | "oauth2::unauthorized_client"
  // 지원 하지 않는 권한 부여 타입
  | "oauth2::unsupported_grant_type"
  // 유효하지 않은 권한 범위
  | "oauth2::invalid_scope"
  // 유효하지 않은 토큰
  | "oauth2::invalid_token"
  // 접근 거부
  | "oauth2::access_denied"
  // issuer 가 다름
  | "jwt::invalid_issuer"
  // 만료된 토큰
  | "jwt::expired";

function stub<T>(): T {
  throw new Error("CourtAuthProvider 내부 컴포넌트에서 사용해 주세요");
}

// 초기 값
export const initializeCourtAuthValue = { authorize: stub, options: {}, security: { status: "none" } } as CourtAuthValue;

export default createContext<CourtAuthValue>(initializeCourtAuthValue);
