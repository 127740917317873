import { useRecoilValue } from "recoil";
import { ConfirmModal } from "src/components";
import { useMemberInfoState } from "src/recoil/member/hook";
import { refreshTokenErrorModalState, RefreshTokenErrorModalState } from "src/recoil/refreshTokenErrorModal/atom";
import { useRefreshTokenErrorModal } from "src/recoil/refreshTokenErrorModal/hook";

/**
 * refresh token 요청시 에러발생할떄 띄우는 모달
 */
const RefreshTokenErrorModal = () => {
  const { logout } = useMemberInfoState();

  const refreshTokenErrorModal = useRecoilValue<RefreshTokenErrorModalState>(refreshTokenErrorModalState);

  const { closeRefreshTokenErrorModal } = useRefreshTokenErrorModal();

  if (refreshTokenErrorModal.errorCode === "oauth2::access_denied") {
    switch (refreshTokenErrorModal.errorMessage) {
      case "LOSS_PHONE_NUMBER_VERIFIED": // 휴대폰 점유 인증 상실
        return (
          <ConfirmModal
            isOpen={refreshTokenErrorModal.isOpen}
            btnLeftTitle="로그아웃"
            btnRightTitle="번호 변경"
            onClose={() => {
              logout(); // 로그아웃
              closeRefreshTokenErrorModal();
            }}
            onClick={() => {
              closeRefreshTokenErrorModal();
            }}
          >
            <div style={{ wordBreak: "break-all" }}>
              <b className="font18">현재 휴대폰 번호가 유효하지 않아요.</b>
              <p className="font16 mt20">기존에 인증하신 휴대폰 번호를 다른 사람이 쓰고 있거나, 더 이상 인증이 유효하지 않습니다.</p>
            </div>
          </ConfirmModal>
        );
      case "LOCKED": // 이용 중지
        return (
          <ConfirmModal
            isOpen={refreshTokenErrorModal.isOpen}
            btnLeftTitle="로그아웃"
            btnRightTitle="고객센터"
            onClose={() => {
              logout(); // 로그아웃
              closeRefreshTokenErrorModal();
            }}
            onClick={() => {
              closeRefreshTokenErrorModal();
            }}
          >
            <div style={{ wordBreak: "break-all" }}>
              <b className="font18">현재 권한이 일시 정지 되었어요.</b>
              <p className="font16 mt20">관리자에게 문의해주시기 바랍니다.</p>
            </div>
          </ConfirmModal>
        );
      case "INACTIVE": // 휴면 처리
        return (
          <ConfirmModal
            isOpen={refreshTokenErrorModal.isOpen}
            btnLeftTitle="로그아웃"
            btnRightTitle="휴면 해제"
            onClose={() => {
              logout(); // 로그아웃
              closeRefreshTokenErrorModal();
            }}
            onClick={() => {
              closeRefreshTokenErrorModal();
            }}
          >
            <div style={{ wordBreak: "break-all" }}>
              <b className="font18">현재 휴면상태에요.</b>
              <p className="font16 mt20">1년 간 접속이 없어서, 휴면 상태입니다. 휴면을 해제하시려면 아래의 버튼을 눌러서 휴면 해제를 신청해주세요.</p>
            </div>
          </ConfirmModal>
        );
      default:
        return null;
    }
  }
  // TODO: 그외 핸들링하지 않는 에러는 일단 에러 모달로
  return (
    <ConfirmModal
      isOpen={refreshTokenErrorModal.isOpen}
      btnRightTitle="확인"
      onClick={() => {
        logout(); // 로그아웃
        closeRefreshTokenErrorModal();
      }}
    >
      <div style={{ wordBreak: "break-all" }}>
        <b className="font18">에러 발생</b>
        <p className="font18 mt15">error code: {refreshTokenErrorModal.errorCode}</p>
        <div className="font18 mt15">error message: {refreshTokenErrorModal?.errorMessage || "에러가 발생했습니다."}</div>
      </div>
    </ConfirmModal>
  );
};

export default RefreshTokenErrorModal;
