import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { BuildingModel } from "src/api/building/building-types";
import { ProductModel } from "src/api/product/product-types";
import { Address } from "src/api/public-types";
import MapView from "src/pages/components/MapView";
import { useToast } from "src/pages/hooks/toast";
import { findPrimaryBuilding } from "../../product-types";
import { numberToStringWithComma } from "src/utils/common-util";
type Props = {
  product: ProductModel | null;
  primaryBuilding: BuildingModel | null;
};

/**
 * 공간상품 상세 > 공간정보 영역
 */
const ProductInfoArea = ({ product, primaryBuilding }: Props) => {
  const location = useLocation();
  const { openToast } = useToast();
  const mapElement = useRef(null);
  const [mapPoint, setMapPoint] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  // 건물 주차 정보
  function getBuildingParking(building: BuildingModel | null) {
    const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
    return parkingLotList.length > 0 ? parkingLotList[0] : null;
  }

  return (
    <section className={`product-info__product-info bottom-divider ${product?.productType === "TIME_COURT" ? "top-divider" : ""}`}>
      <h2>상품정보</h2>
      <p className="description">{product?.description || ""}</p>
      <div className="horizontal-divider"></div>
      {/* 건물이 두개 이상일때 대표 건물명 노출 */}
      {product?.buildingList && product?.buildingList?.length > 1 && <p className="info-title">{primaryBuilding?.buildingName}</p>}

      <div className="info-line">
        <span>규모</span>
        <p>{(primaryBuilding && `지상 ${primaryBuilding?.floorNums}층, 지하 ${primaryBuilding?.undergroundFloorNums}층`) || "-"}</p>
      </div>
      <div className="info-line">
        <span>주차장</span>
        <p>
          {`자주식 ${numberToStringWithComma(
            getBuildingParking(primaryBuilding)?.selfPropelledParkingLotTotalNums || 0,
          )}대 / 기계식 ${numberToStringWithComma(getBuildingParking(primaryBuilding)?.mechanicalParkingLotTotalNums || 0)}대`}
        </p>
      </div>
      <div className="info-line">
        <span>엘레베이터</span>
        <p>
          승객용 {primaryBuilding?.passengerElev}대 / 화물용 {primaryBuilding?.freightElev}대
        </p>
      </div>
      <div className="info-line">
        <span>출입시간</span>
        <p>
          {primaryBuilding?.entryStartTime} ~ {primaryBuilding?.entryEndTime} {primaryBuilding?.isHoliday && "/ 공휴일 가능"}
        </p>
      </div>
      <div className="horizontal-divider"></div>
      {product?.buildingList && <MapView building={findPrimaryBuilding(product?.buildingList) || {}} />}
    </section>
  );
};
export default ProductInfoArea;
