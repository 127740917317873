import { CourtAuthState } from "../contexts/CourtAuthContext";
import persistentStorage from "./persistent-storage";

/**
 * 상태 조회
 * @returns 인증 상태 정보
 */
function load() {
  const value = persistentStorage.getItem("state");
  const text = window.atob(value ?? "bnVsbA==");
  if (text === "null") {
    return null;
  } else {
    return JSON.parse(text) as CourtAuthState;
  }
}

/**
 * 상태 저장 처리
 * @param state 인증 상태 정보
 */
function save(state: CourtAuthState) {
  const value = window.btoa(JSON.stringify(state));
  persistentStorage.setItem("state", value);
}

/**
 * 상태 삭제 처리
 */
function remove() {
  persistentStorage.removeItem("state");
}

const courtAuthStateRepository = { load, save, remove };

export default courtAuthStateRepository;
