import Header from "src/components/layout/Header";
type Props = {
  onClose: Function;
};
const TermsOfThirdParty = ({ onClose }: Props) => {
  return (
    <>
      <Header
        headerType="CLOSE"
        title="개인정보 제3자 동의"
        onClickCloseButton={() => {
          onClose()!;
        }}
      />
      <section className="base-section-layout w-100">
        <div className="base-section-layout__container">
          <div className="base-section-layout__wrap">
            <div className="base-section-layout__title-wrap">
              <div className="flex-row">
                <span className="font16 font-weight-600">개인정보를 제공받는 자</span> <br />
                - 해당 공간의 제공자 (프로바이더) <br />
                <br />
                <span className="font16 font-weight-600">제공하는 개인정보 항목</span> <br />
                - 필수 항목: 이메일, 휴대폰 번호, 결제 방식 및 결제 금액
                <br /> <br />
                <span className="font16 font-weight-600">개인정보 제공 목적</span> <br />
                - 공간 예약 및 이용 서비스 제공 <br />
                - 환불 처리 <br />
                <br />
                <span className="font16 font-weight-600">개인정보 보유 및 이용 기간</span>
                <br /> - 서비스 제공 기간 동안 보유
                <br /> - 단, 관련 법령에 따라 보관이 필요한 경우나 사전 동의가 있는 경우, 해당 기간 동안 보관됩니다.
                <br />
                <br />
                <span className="font16 font-weight-600">개인정보 제공 거부에 관한 안내</span>
                <br /> - 개인정보 주체는 개인정보 제공을 거부할 권리가 있습니다.
                <br /> - 다만, 공간 예약을 위해 필요한 필수적인 개인정보 제공을 거부할 경우, 예약이 어려울 수 있습니다.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfThirdParty;
