import Header from "src/components/layout/Header";
import RefundPolicy from "./RefundPolicy";

type Props = {
  refundInfo: any;
  onClose: Function;
};
const ServiceTypeRefundPolicy = ({ refundInfo, onClose }: Props) => {
  return (
    <>
      <Header
        headerType="CLOSE"
        title="결제 및 취소 안내"
        onClickCloseButton={() => {
          onClose()!;
        }}
      />
      <RefundPolicy refundInfo={refundInfo}/>
    </>
  );
};

export default ServiceTypeRefundPolicy;
