import moment from "moment";
import { useCallback, useMemo } from "react";
import { ContractModel, CtMediaFile, isFreeContract } from "src/api/contract/contract-types";
import usePostMessage from "src/pages/hooks/post-message";
import { getUserDevice, numberToStringWithComma } from "src/utils/common-util";

type Props = {
  contract?: ContractModel | null;
};
/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 화면 > 계약 정보 (F/O 타입 이고 / 신청확인 or 계약예정 or 계약체결 or 이용승인 or 이용중 or 이용완료 / 유료일때 출력)
 */
const ContractInfo = ({ contract }: Props) => {
  const device = getUserDevice();

  // ReactNativeWebView postMessage 를 관리하는 hook
  const { postMessageFileDownload } = usePostMessage();

  // 무료 여부
  const isFree = useMemo(() => {
    return isFreeContract(contract);
  }, [contract]);

  // 계약서 다운로드 버튼 클릭
  const onClickDownload = useCallback(async () => {
    if (!contract?.contractId || (contract?.contractMedia || []).length === 0) return;

    const mediaFile: CtMediaFile | undefined = (contract?.contractMedia || []).find((v: CtMediaFile) => v.serviceTag === "CONTRACT_DOCUMENT"); // 계약서
    if (!mediaFile || !mediaFile?.id) return;

    console.log("mediaFile", mediaFile);

    // RN 파일 다운로드 요청
    postMessageFileDownload(mediaFile?.url || "", mediaFile?.filename || "");
  }, [contract, postMessageFileDownload]);

  // F/O 타입 이고, 신청확인 or 계약예정 or 계약체결 or 이용승인 or 이용중 or 이용완료, 유료일 경우만 노출
  if (
    contract?.spaceProductType !== "TIME_COURT" &&
    ["APPLY_CONFIRM", "CONTRACT_PLAN", "CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "USE_COMPLETE"].includes(contract?.contractStep || "") &&
    !isFree
  ) {
    // 계약전 (신청확인, 계약예정): ‘계약일시, 계약금, 필요서류’ 출력
    if (["APPLY_CONFIRM", "CONTRACT_PLAN"].includes(contract?.contractStep || "")) {
      return (
        <section className="base-section-layout top-divider">
          <div className="base-section-layout__container">
            <div className="base-section-layout__wrap">
              <div className="base-section-layout__title-wrap">
                <h2>계약 정보</h2>
              </div>
              <div className="base-section-layout-line">
                <p className="base-section-layout-index text-primary3">계약 일시</p>
                <p className="base-section-layout-value">
                  {contract?.contractStep
                    ? contract?.contractStep === "APPLY_CONFIRM"
                      ? "추후 안내"
                      : moment(contract?.contractDate).format("YYYY.MM.DD HH:mm")
                    : "-"}
                </p>
              </div>
              <div className="base-section-layout-line">
                <p className="base-section-layout-index text-primary3">계약금</p>
                <p className="base-section-layout-value">
                  <b>{contract?.earnestAmount ? numberToStringWithComma(Number(contract?.earnestAmount)) : "-"}</b> 원
                </p>
              </div>
              {/* <ul className="pt10 pb20">
                <li>계약금은 계약서 날인 전까지 계좌이체가 필요합니다.</li>
                <li>계약일에 필요서류를 준비해 주세요.</li>
              </ul> */}
              <div className="base-section-layout-line left-case">
                <p className="base-section-layout-index text-primary3">필요서류</p>
                <div className="base-section-layout-value">
                  <p>
                    <b>[법인 사업자]</b>
                    <br />
                    대표자 신분증 사본, 법인 등기부등본(3개월 내 발행), 법인 사업자등록증, 법인 인감, 법인 인감증명서(3개월 내 발행), 사용인감계
                  </p>
                  <ul className="pt10 pb20">
                    <li>대리인인 경우 추가로 대리인 위임장, 대리인 신분증 사본, 대리인 도장을 준비해주세요.</li>
                  </ul>
                  <p>
                    <b>[개인 사업자]</b>
                    <br />
                    신분증 사본, 도장, 사업자 등록증
                  </p>
                  <ul className="pt10 pb20">
                    <li>대리인인 경우 위임장 및 인감증명서, 대리인 신분증 사본, 대리인 도장을 준비해주세요.</li>
                  </ul>
                  <p>
                    <b>[개인]</b>
                    <br />
                    개인 신분증 사본, 도장
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    // 계약후 (계약체결, 이용승인, 이용중, 이용완료): ‘계약번호, [계약서 다운로드], 계약일시’ 출력
    else if (["CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "USE_COMPLETE"].includes(contract?.contractStep || "")) {
      return (
        <section className="base-section-layout top-divider">
          <div className="base-section-layout__container">
            <div className="base-section-layout__wrap">
              <div className="base-section-layout__title-wrap">
                <h2>계약 정보</h2>
                {device !== "other" && (contract?.contractMedia || []).length > 0 && (
                  <button type="button" className="icon-right-btn ic-download" onClick={() => onClickDownload()}>
                    계약서 다운로드
                  </button>
                )}
              </div>
              <div className="base-section-layout-line">
                <p className="base-section-layout-index text-primary3">계약 번호</p>
                <p className="base-section-layout-value">{contract?.contractNumber || "-"}</p>
              </div>
              <div className="base-section-layout-line">
                <p className="base-section-layout-index text-primary3">계약 일시</p>
                <p className="base-section-layout-value">
                  {contract?.contractDate ? moment(contract?.contractDate).format("YYYY.MM.DD HH:mm") : "-"}
                </p>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
  return null;
};

export default ContractInfo;
