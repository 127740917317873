import qs from "qs";
import QueryString from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBuildingsDetailsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { Address } from "src/api/public-types";
import { Visitor } from "src/api/visitor/visitor-types";
import MetaTag from "src/components/layout/MetaTag";
import useNaverMap from "src/hooks/useNaverMap";

type Props = {
  visitor?: Visitor;
  isChangeLanguage?: boolean;
};

/**
 * 방문자 초대 상세 웹뷰 화면 > 오시는길 안내
 */
const MapGuide = ({ visitor, isChangeLanguage }: Props) => {
  // (무인증) 건물 상세정보 목록 api
  const { executeAsync: getBuildingsDetails } = useApiOperation(getBuildingsDetailsAsync, { noAuthenticationRequired: true });
  const mapElement = useRef(null);

  const [mapPoint, setMapPoint] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);
  const [buildingAddress, setBuildingAddress] = useState<Address | null>(null);
  const [isScriptLoading, setIsScriptLoading] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  // 건물 상세정보 조회
  const fetchBuildingsDetail = useCallback(
    async (buildingId: string | number) => {
      const { data } = await getBuildingsDetails({
        buildingIds: [buildingId],
      });
      if (data?.data?.content && (data?.data?.content || []).length > 0) {
        const building = data?.data?.content[0].building;
        setBuilding(building);

        if (building?.addressList && building?.addressList?.length > 0) {
          setBuildingAddress(building?.addressList[0]);
        }
      }
    },
    [getBuildingsDetails],
  );

  useEffect(() => {
    if (visitor && visitor?.buildingId) {
      // 건물 상세정보 조회
      fetchBuildingsDetail(visitor.buildingId);
    }
  }, [visitor, fetchBuildingsDetail]);

  useEffect(() => {
    const { naver } = window;
    if (buildingAddress) {
      const checkScriptExistence = () => {
        const naverMapScript = document.getElementById("navermap");
        if (naverMapScript) {
          setIsScriptLoading(true);
          const fetchGeoCode = () => {
            naver?.maps.Service.geocode({ query: buildingAddress?.address || "" }, function (status, response) {
              if (status === naver.maps.Service.Status.ERROR) {
                return alert("Something wrong!");
              }
              if (response.v2.addresses.length > 0) {
                setMapPoint({
                  x: Number(response.v2.addresses[0].x || 0),
                  y: Number(response.v2.addresses[0].y || 0),
                });
              } else {
                if (retryCount < 5) {
                  retryCount++;
                  setTimeout(fetchGeoCode, 200);
                } else {
                  console.error("실패");
                }
              }
            });
          };
          fetchGeoCode();
          clearInterval(intervalId);
        }
      };
      const intervalId = setInterval(checkScriptExistence, 300);
    }
    let retryCount = 0;
  }, [buildingAddress, isScriptLoading]);

  useEffect(() => {
    // 좌표 (mapPoint) 가 변경될때 map 에 표기
    const { naver } = window;
    if (!mapElement.current || !naver) return;
    if (mapPoint.x === 0 && mapPoint.y === 0) return;

    // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
    const location = new naver.maps.LatLng(mapPoint.y, mapPoint.x);
    const mapOptions: naver.maps.MapOptions = {
      center: location,
      zoom: 17,
      zoomControl: true,
      zoomControlOptions: {
        position: naver.maps.Position.TOP_RIGHT,
      },
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  }, [mapPoint]);

  return (
    <>
      <MetaTag naverLanguage={(queryParams.lang as string) || "ko"} />
      <section className={`base-accordion no-icon pb50`}>
        <div className="base-accordion__title-area">
          <span>오시는 길</span>
        </div>
        <div className="base-accordion__contents">
          <p className="font15 py20">
            {buildingAddress?.address} {buildingAddress?.addressDetail}
          </p>
          <div ref={mapElement} className="map-container bg-temp"></div>
        </div>
      </section>
    </>
  );
};
export default MapGuide;
