import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBuildingsDetailsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { getGuideListAsync, getGuidePublicListAsync } from "src/api/guide/guide-api";
import { ServiceUserGuide } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useHeaderOpacity } from "src/pages/hooks/header-opacity";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import EtcGuide from "./components/EtcGuide";
import NetworkGuide from "./components/NetworkGuide";
import { getPublicETMediaFilesAsync } from "src/api/file/file-api";
import usePostMessage from "src/pages/hooks/post-message";

/**
 * 무인증 계약 이용안내 > 상세 화면 (Taap 웹뷰)
 */
type QueryParams = {
  buildingId?: string;
};
const ContractGuideDetail = () => {
  const initializedProductGuide = useRef(false);
  const initializedBuilding = useRef(false);
  const location = useLocation();

  const { goBack } = useNavigateGoBack();

  // path variable 공간상품 id
  const { contractManageId, guideId } = useParams();
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    return _queryParams;
  }, [location.search]);

  const { executeAsync: getGuideList } = useApiOperation(getGuideListAsync, { noHandleError: true });
  const { executeAsync: getBuildingsDetails } = useApiOperation(getBuildingsDetailsAsync, { noHandleError: true });
  const { executeAsync: getPublicETMediaFiles } = useApiOperation(getPublicETMediaFilesAsync, { noHandleError: true });

  // 이용안내 상세
  const [guide, setGuide] = useState<ServiceUserGuide>({});
  const [guideMediaList, setGuideMediaList] = useState<any>([]);
  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  const pageContainer = useRef<HTMLDivElement>(null);
  const startPoint = useRef<HTMLDivElement>(null);

  // 헤더 오퍼시티 적용
  const { opacityCount } = useHeaderOpacity({ pageContainer, startPoint });

  // ReactNativeWebView postMessage 를 관리하는 hook
  const { postMessageFileDownload } = usePostMessage();
  // 이용안내 상세조회
  const fetchPublicGuide = useCallback(
    async (contractManageId: string, guideId: string) => {
      const { data } = await getGuideList({ serviceId: contractManageId, serviceType: "SERVICE_CONTRACT_MANAGE", userGuideId: guideId });
      // setProductGuide(data?.data?.content);
      setGuide(data.data.content[0] || {});
      if (data.data.content && data.data.content.length > 0 && data.data.content[0].userGuide) {
        const guide = data.data.content[0];
        const { data: media } = await getPublicETMediaFiles({
          partnerId: guide?.userGuide?.partnerId || "",
          contentsList: { contentsList: [{ serviceId: String(guide?.userGuide?.id) || "", serviceType: "SERVICE_USER_GUIDE" }] },
        });
        setGuideMediaList(media.data.content);
        console.log(media.data.content);
      }

      if (!initializedProductGuide.current) {
        initializedProductGuide.current = true;
      }
    },
    [getGuideList, getPublicETMediaFiles],
  );

  // 건물 상세정보 조회
  const fetchBuildingsDetail = useCallback(
    async (buildingId: string | number) => {
      const { data } = await getBuildingsDetails({
        buildingIds: [buildingId],
      });
      if (data?.data?.content && (data?.data?.content || []).length > 0) {
        setBuilding((data?.data?.content || [])[0]?.building);
      }
      if (!initializedBuilding.current) {
        initializedBuilding.current = true;
      }
    },
    [getBuildingsDetails],
  );

  useEffect(() => {
    if (contractManageId && guideId) {
      // 공간상품 사용안내 상세조회
      fetchPublicGuide(contractManageId, guideId);
    }
  }, [contractManageId, guideId, fetchPublicGuide]);

  useEffect(() => {
    if (queryParams.buildingId) {
      // 건물 상세정보 조회
      fetchBuildingsDetail(queryParams.buildingId);
    }
  }, [fetchBuildingsDetail, queryParams.buildingId]);
  const onClickDownload = useCallback(
    async (idx: number) => {
      const mediaFile = guideMediaList[idx];
      console.log("mediaFile", mediaFile);

      if ((window as any).ReactNativeWebView) {
        // RN 파일 다운로드 요청
        postMessageFileDownload(mediaFile?.url || "", mediaFile?.filename || "");
      } else {
      }
    },
    [guideMediaList, postMessageFileDownload],
  );
  return (
    <div>
      <MetaTag title="이용안내" />
      {initializedProductGuide.current && initializedBuilding.current && (
        <>
          <Header headerType="BACK" title={guide?.userGuide?.subject} opacityCount={opacityCount} onClickCloseButton={() => goBack()} />
          <div className="space-info detail" ref={pageContainer}>
            <div className={`title-wrap`} ref={startPoint}>
              <h2>{guide?.userGuide?.subject}</h2>
              <p>{building?.buildingName}</p>
            </div>
            <section className="contents__section">
              {guide.userGuide?.type === "USER_GUIDE_WIFI" ? (
                <NetworkGuide userGuide={guide?.userGuide} />
              ) : (
                <>
                  <EtcGuide userGuide={guide?.userGuide} />
                  {(guideMediaList || []).length > 0 &&
                    guideMediaList.map((file: any, idx: number) => (
                      <div key={idx} className="my20">
                        <button type="button" className="guide-file-download" onClick={() => onClickDownload(idx)}>
                          <p className="mr10 pr10 ellipsis text-left">{file.filename}</p>
                        </button>
                      </div>
                    ))}
                </>
              )}
            </section>
          </div>
        </>
      )}
    </div>
  );
};
export default ContractGuideDetail;
