import PKCE from "js-pkce";
import ITokenResponse from "js-pkce/dist/ITokenResponse";
import { useCallback } from "react";
import log from "src/utils/log";
import { CourtAuthError, CourtAuthErrorCode, TokenResponse } from "../../contexts/CourtAuthContext";
import SingleAsyncCall from "../../utils/SingleAsyncCall";
import tokenResponseRepository from "../../utils/token-response-repository";

type PkceTokenResponse = ITokenResponse & {
  // 아이디 JWT
  id_token: string;
};

const singleAsyncCall = new SingleAsyncCall<ITokenResponse>();

export default function useFetchTokenAsync(pkce: PKCE) {
  /**
   * 인증 서버로 토큰 요청
   */
  return useCallback<() => Promise<TokenResponse>>(async () => {
    log("useFetchTokenAsync", window.location.href);
    const tokenReponse = await singleAsyncCall.execute(() => pkce.exchangeForAccessToken(window.location.href));
    if ("error" in tokenReponse) {
      const { error: codeSuffix, error_description } = tokenReponse as any;
      const code = `oauth2::${codeSuffix}` as CourtAuthErrorCode;
      throw new CourtAuthError(code, error_description);
    }
    const { token_type, scope, access_token, id_token, expires_in, refresh_token } = tokenReponse as PkceTokenResponse;
    const token = {
      tokenType: token_type,
      scope,
      accessToken: access_token,
      idToken: id_token,
      expiresIn: expires_in,
      refreshToken: refresh_token,
    };
    tokenResponseRepository.save(token);
    return token;
  }, [pkce]);
}
