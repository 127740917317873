import Linkify from "react-linkify";

type Props = {
  text?: string;
};

// 'http://' or 'https://' or 'taap.dev://'
const regExp = new RegExp("(.*://)");

/**
 * text 에서 link 형태의 문자를 찾아서 파싱해주는 컴포넌트
 */
const LinkifyText = ({ text }: Props) => {
  if (!text) return null;

  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => {
        const isValid = regExp.test(decoratedHref);
        const convertedHref = "http://" + decoratedHref;
        return (
          <a
            key={key}
            href={isValid ? decoratedHref : convertedHref}
            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {decoratedText}
          </a>
        );
      }}
    >
      {text}
    </Linkify>
  );
};

export default LinkifyText;
