import "@toast-ui/editor/dist/toastui-editor.css";
import { Viewer } from "@toast-ui/react-editor";

type Props = {
  value?: string;
};

// 'http://' or 'https://' or 'taap.dev://'
const regExp = new RegExp("(.*://)");

/**
 * toast-ui editor 로 등록한 markdown 뷰어 컴포넌트
 */
const MarkdownViewer = ({ value }: Props) => {
  return (
    <div className="base-markdown">
      <Viewer
        initialValue={value}
        usageStatistics={false}
        linkAttributes={{ target: "_blank" }}
        customHTMLRenderer={{
          link(node, context) {
            const { entering, origin } = context;
            const _origin = typeof origin === "function" ? origin() : null;

            if (entering) {
              const originHref = ((_origin as any)?.attributes?.href || "") as string;
              const isValid = regExp.test(originHref);
              const convertedHref = "http://" + originHref;
              return {
                type: "openTag",
                tagName: "a",
                attributes: {
                  href: isValid ? originHref : convertedHref,
                  title: originHref,
                  target: "_blank",
                },
              };
            } else {
              return {
                type: "closeTag",
                tagName: "a",
              };
            }
          },
        }}
      />
    </div>
  );
};

export default MarkdownViewer;
