import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/locale";
import { forwardRef, useRef } from "react";

interface Props {
  dateRange: [Date | null, Date | null];
  onDateRangeChange: (dateRange: [Date | null, Date | null]) => void;
  disabled?: boolean;
}

function RangeDatepicker(props: Props) {
  const [startDate, endDate] = props.dateRange;

  const RangeDatePinkerInput = forwardRef<HTMLInputElement, any>(({ value, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(ref as any);

    const handleClickIcon = () => inputRef.current?.focus();

    return (
      <div className="range-datepicker-input">
        <input value={value} {...props} ref={inputRef} />
        <div className="icon" onClick={handleClickIcon} />
      </div>
    );
  });

  return (
    <ReactDatePicker
      selectsRange={true}
      onChange={props.onDateRangeChange}
      calendarClassName="base-datepicker range-datepicker"
      dateFormat={"yyyy.MM.dd"}
      locale={ko}
      startDate={startDate}
      endDate={endDate}
      customInput={<RangeDatePinkerInput />}
      placeholderText="기간 선택"
      showPopperArrow={false}
      popperPlacement="bottom"
      disabled={props.disabled}
    />
  );
}
export default RangeDatepicker;
