import { useMediaQuery } from "react-responsive";
import variables from "src/assets/styles/variables.module.scss";

const CSS = {
  bpTablet: variables.bpTablet,
};

/**
 * useMediaQuery 를 사용해서 공통으로 사용되는 값들 정의
 */
export function useMediaQueries() {
  const isMobile = useMediaQuery({ query: `(max-width: ${Number(CSS.bpTablet.substring(0, CSS.bpTablet.indexOf("px")) || 0) - 1}px)` });
  const isDesktop = useMediaQuery({ query: `(min-width: ${CSS.bpTablet})` });
  return { isMobile, isDesktop };
}
