import { orderBy } from "lodash";
import QueryString from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getBuildingsDetailsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { getContractBuildingsAsync } from "src/api/contract/contract-api";
import { getPublicMediaFilesAsync } from "src/api/file/file-api";
import { MediaFile } from "src/api/file/file-types";
import { getGuideListAsync } from "src/api/guide/guide-api";
import { ServiceUserGuide } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import noimg from "src/assets/images/icons_large_noimg_building.svg";
import { BaseBottomSheet } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useHeaderOpacity } from "src/pages/hooks/header-opacity";
import usePostMessage from "src/pages/hooks/post-message";
import { getResizedImageUrl } from "src/utils/common-util";

// 건물의 외관 media file
type BuildingMediaFile = {
  buildingId: string;
  mediaFile: MediaFile;
};

/**
 * 계약 이용안내 목록 화면 (Taap 웹뷰)
 */
const ContractGuideList = () => {
  const initializedProductRef = useRef<boolean>(false);
  const initializedProductGuidesRef = useRef<boolean>(false);

  const pageContainer = useRef<HTMLDivElement>(null);
  const startPoint = useRef<HTMLDivElement>(null);

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = noimg;
  };

  const location = useLocation();
  const navigate = useNavigate();

  const { postMessageGoBackNavigation } = usePostMessage();

  // path variable 계약관리 id
  const { contractManageId } = useParams();

  // query parameter
  const [searchParams] = useSearchParams();

  const { executeAsync: getContractBuildings } = useApiOperation(getContractBuildingsAsync, { noHandleError: true });
  const { executeAsync: getPublicMediaFiles } = useApiOperation(getPublicMediaFilesAsync, { noAuthenticationRequired: true, noHandleError: true });
  const { executeAsync: getGuideList } = useApiOperation(getGuideListAsync, { noHandleError: true });
  const { executeAsync: getBuildingDetails } = useApiOperation(getBuildingsDetailsAsync, { noAuthenticationRequired: true, noHandleError: true });

  const [contractBuildings, setContractBuildings] = useState<any | null>(null);
  const [buildingMediaFiles, setBuildingMediaFiles] = useState<BuildingMediaFile[]>([]);
  const [GuideList, setGuideList] = useState<ServiceUserGuide[]>([]);
  const [spaceContractName, setSpaceContractName] = useState("");
  const [selectedBuildingId, setSelectedBuildingId] = useState<string>("");
  const [primaryBuildingId, setPrimaryBuildingId] = useState<string>("");

  // query parameter buildingId
  const searchParamBuildingId = useMemo(() => {
    return searchParams.get("buildingId");
  }, [searchParams]);

  // navigate query parameter buildingId 변경
  const navigateWithBuildingId = useCallback(
    (buildingId: string) => {
      const queryParams = QueryString.parse(location.search, { ignoreQueryPrefix: true });
      const newQueryParams = { ...queryParams, ...{ buildingId } };
      navigate(
        {
          pathname: location.pathname,
          search: QueryString.stringify(newQueryParams),
        },
        { replace: true },
      );
    },
    [navigate, location],
  );

  // 건물 목록
  const buildingList: BuildingModel[] = useMemo(() => {
    if (!contractBuildings || contractBuildings.length === 0) return [];
    const primaryBuilding = contractBuildings?.find((building: any) => String(building?.id) === String(primaryBuildingId));
    // 대표건물 제외 건물명 오름차순으로 정렬
    const notPrimaryBuildingList = orderBy(
      contractBuildings?.filter((building: any) => String(building?.id) !== String(primaryBuildingId)),
      ["", "buildingName"],
      ["asc"],
    );
    // 최상단 대표 건물, 이후 건물은 건물명 오름차순
    if (primaryBuilding) {
      return [primaryBuilding, ...notPrimaryBuildingList];
    }
    return notPrimaryBuildingList;
  }, [contractBuildings, primaryBuildingId]);

  // 선택된 건물
  const selectedBuilding: BuildingModel | undefined = useMemo(() => {
    if (!selectedBuildingId || !contractBuildings || contractBuildings.length === 0) return undefined;
    return (contractBuildings || []).find((building: any) => String(building?.id || "") === String(selectedBuildingId));
  }, [contractBuildings, selectedBuildingId]);

  // 건물 선택 bottom sheet 오픈 여부
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState<boolean>(false);

  // 헤더 오퍼시티 적용
  const { opacityCount } = useHeaderOpacity({ pageContainer, startPoint });

  // 계약의 건물 운영정보 조회
  const fetchContractBuildings = useCallback(
    async (contractManageId: string) => {
      const { data } = await getContractBuildings({ contractManageId });
      const _buildings = data?.data?.buildingManageList || null;

      setSpaceContractName(data.data?.contractName || data.data?.spaceProductName || "");

      const buildingIds = _buildings?.map((building: any) => building.buildingId);
      if (buildingIds && buildingIds.length > 0) {
        const { data } = await getBuildingDetails({ buildingIds });
        const buildingList = data.data.content?.map((item) => item.building);
        setContractBuildings(buildingList);
      }

      let _primaryBuildingId: string = "";
      if (_buildings && _buildings.length > 0) {
        for (let i = 0; i < _buildings.length; i++) {
          const building = (_buildings || [])[i];
          if (building) {
            if (building.isPrimary) {
              _primaryBuildingId = building.buildingId;
              setPrimaryBuildingId(building.buildingId);
            }
          }
        }
        if (searchParamBuildingId) {
          // search param 에 buildingId 가 있으면 해당 건물 선택
          setSelectedBuildingId(searchParamBuildingId);
        } else {
          // 대표 건물이 선택되어있게
          setSelectedBuildingId(_primaryBuildingId);
        }
      }

      if (!initializedProductRef.current) {
        initializedProductRef.current = true;
      }
    },
    [getContractBuildings, searchParamBuildingId, getBuildingDetails],
  );

  // 공통 미디어파일 목록 조회
  const fetchPublicMediaFiles = useCallback(
    async (buildingIds: string[]) => {
      const _buildingMediaFiles: BuildingMediaFile[] = [];
      for (let i = 0; i < buildingIds.length; i++) {
        const { data } = await getPublicMediaFiles({
          serviceId: buildingIds[i],
          mediaServiceTypes: ["MEDIA_BUILDING"], // 건물 외관 ( 건물 대표 카테고리 )
        });
        if (data?.data?.content && (data?.data?.content || []).length > 0) {
          const primaryMediaFile = data.data.content.find((v: MediaFile) => v.isPrimary === true);
          if (primaryMediaFile) {
            // 건물 외관의 대표 이미지
            _buildingMediaFiles.push({ buildingId: buildingIds[i], mediaFile: primaryMediaFile });
          }
        }
      }
      setBuildingMediaFiles(_buildingMediaFiles);
    },
    [getPublicMediaFiles],
  );

  // 이용안내  목록조회
  const fetchGuides = useCallback(
    async (contractManageId: string | number, buildingId: string | number) => {
      const { data } = await getGuideList({ serviceId: contractManageId, serviceType: "SERVICE_CONTRACT_MANAGE", buildingId });
      const _Guides = data?.data?.content || [];
      setGuideList(_Guides);

      if (!initializedProductGuidesRef.current) {
        initializedProductGuidesRef.current = true;
      }
    },
    [getGuideList],
  );

  // 건물의 외관 media file 목록 중 해당 건물 파일 찾기
  const findBuildingMediaFile = useCallback(
    (buildingId: string) => {
      return buildingMediaFiles.find((v: BuildingMediaFile) => v.buildingId === buildingId);
    },
    [buildingMediaFiles],
  );

  // 바텀시트에서 건물 선택 변경
  const onChangeSelectedBuilding = useCallback((buildingId: string) => {
    setIsOpenBottomSheet(false);
    setSelectedBuildingId(buildingId);
    navigateWithBuildingId(buildingId);
  }, []);

  useEffect(() => {
    if (contractManageId) {
      // 건물 운영정보 조회
      fetchContractBuildings(contractManageId);
    }
  }, [contractManageId, fetchContractBuildings]);

  useEffect(() => {
    if (buildingList.length > 0) {
      // 공통 미디어파일 목록 조회
      const buildingIds = buildingList?.map((building: any) => String(building.id));
      fetchPublicMediaFiles(buildingIds);
    }
  }, [buildingList, fetchPublicMediaFiles]);

  useEffect(() => {
    if (contractManageId && selectedBuildingId) {
      // 이용안내 목록조회
      fetchGuides(contractManageId, selectedBuildingId);
    }
  }, [selectedBuildingId, contractManageId, fetchGuides]);

  return (
    <div>
      <MetaTag title="이용안내" />
      <Header headerType="BACK" title="이용안내" opacityCount={opacityCount} onClickBackButton={() => postMessageGoBackNavigation()} />
      <div className="space-info" ref={pageContainer}>
        <div className={`title-wrap`} ref={startPoint}>
          <span>{spaceContractName}</span>
          <br />
          <h1>이용안내</h1>
          {initializedProductRef.current && (
            <div className="w-100 overflow-hidden">
              <button
                className={`chevron-down-btn text-left mb30 ${isOpenBottomSheet ? "--active" : ""}`}
                onClick={() => {
                  setIsOpenBottomSheet(true);
                }}
                disabled={buildingList.length <= 1}
              >
                <p className="ellipsis">{selectedBuilding?.buildingName}</p>
              </button>
              {/* <div className="support-banner" onClick={()=>{}}>
              <p>공간 이용에 불편함이 있나요?</p>
              <p>1:1문의</p>
            </div> */}
            </div>
          )}
        </div>

        <BaseBottomSheet
          isOpen={isOpenBottomSheet}
          onClose={() => setIsOpenBottomSheet(false)}
          title={"건물을 선택해주세요"}
          indexTitle={spaceContractName}
          className="scrollable"
        >
          <div className="space-select-sheet">
            <div className="space-list">
              {buildingList.map((building: BuildingModel, index: number) => (
                <div
                  key={index}
                  className="space-list__item"
                  onClick={() => {
                    onChangeSelectedBuilding(String(building.id));
                  }}
                >
                  <div className="space-list__item-wrap">
                    <img
                      src={getResizedImageUrl(findBuildingMediaFile(String(building.id))?.mediaFile?.url)}
                      onError={handleImgError}
                      alt="building-img"
                    />
                    <div className="space-list__item-info">
                      <p>{building?.buildingName}</p>
                      <span>
                        {(building?.addressList || []).length > 0
                          ? `${(building.addressList || [])[0].address} ${(building.addressList || [])[0].addressDetail}`
                          : ""}
                      </span>
                    </div>
                    <div className="space-list__item-selected">
                      {String(selectedBuildingId) === String(building.id) && <div className="space-list__item-checked"></div>}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </BaseBottomSheet>

        {initializedProductGuidesRef.current && (GuideList.length === 0 || GuideList.filter((guide) => guide.isDisplayed).length === 0) ? (
          <section className="contents-empty">
            <div>
              <div className="icon ic-none mb30"></div>
              <p>공간 이용안내 정보가 없습니다</p>
            </div>
          </section>
        ) : (
          <section className="list-section">
            {GuideList.filter((guide) => guide.isDisplayed).map((guide: ServiceUserGuide, index: number) => (
              <div
                key={index}
                className="list-section__item"
                onClick={() => navigate(`/court/contractManage/${contractManageId}/guides/${guide.userGuide?.id}?buildingId=${selectedBuildingId}`)}
              >
                <p>{guide?.userGuide?.subject}</p>
              </div>
            ))}
          </section>
        )}
      </div>
    </div>
  );
};
export default ContractGuideList;
