import Cookies, { CookiesStatic } from "js-cookie";

function getKey(namespace: string, key: string) {
  return `${namespace}::${key}`;
}

function getCookiesLength(cookies: CookiesStatic<string>) {
  return Object.keys(cookies.get()).length;
}

class CookieStorage implements Storage {
  private cookies: CookiesStatic<string>;
  private namespace: string;

  constructor(cookies: CookiesStatic<string>, namespace: string) {
    this.cookies = cookies;
    this.namespace = namespace;
    this.length = getCookiesLength(cookies);
  }

  name: string = `CourtAuth Cookie Storage`;

  length: number;

  clear(): void {
    Object.keys(this.cookies.get()).forEach((key) => this.cookies.remove(key));
    this.length = getCookiesLength(this.cookies);
  }
  getItem(key: string): string | null {
    return this.cookies.get(getKey(this.namespace, key)) ?? null;
  }
  key(index: number): string | null {
    return Object.keys(this.cookies.get())[index] ?? null;
  }
  removeItem(key: string): void {
    this.cookies.remove(getKey(this.namespace, key));
    this.length = getCookiesLength(this.cookies);
  }
  setItem(key: string, value: string): void {
    this.cookies.set(getKey(this.namespace, key), value);
    this.length = getCookiesLength(this.cookies);
  }
}

class DelegatingStorage implements Storage {
  private delegate: Storage;
  private namespace: string;

  constructor(delegate: Storage, namespace: string) {
    this.delegate = delegate;
    this.namespace = namespace;
    this.length = delegate.length;
  }

  name: string = "CourtAuth Local Storage";

  length: number;

  clear(): void {
    this.delegate.clear();
    this.length = this.delegate.length;
  }
  getItem(key: string): string | null {
    return this.delegate.getItem(getKey(this.namespace, key));
  }
  key(index: number): string | null {
    return this.delegate.key(index);
  }
  removeItem(key: string): void {
    this.delegate.removeItem(getKey(this.namespace, key));
    this.length = this.delegate.length;
  }
  setItem(key: string, value: string): void {
    this.delegate.setItem(getKey(this.namespace, key), value);
    this.length = this.delegate.length;
  }
}
const url = new URL(window.location.href);
const cookieStorage = new CookieStorage(Cookies.withAttributes({ expires: 30, secure: url.protocol === "https:" }), "court.auth");
const localStorage = new DelegatingStorage(window.localStorage, "court.auth");
const sessionStorage = new DelegatingStorage(window.sessionStorage, "court.auth");
const persistentStorage = localStorage;

export default persistentStorage;

export { cookieStorage, localStorage, sessionStorage };
