import moment from "moment";
import { ContractAccessLog } from "src/api/member/member-types";

export const getAccessStatus = (eventGroup?: string): string => {
  let text = "출입 인증 성공";
  switch (eventGroup) {
    case "ACCESS_FAILURE":
      text = "출입 인증 실패";
      break;
    case "ACCESS_REJECTED":
      text = "출입 거부";
      break;

    default:
      break;
  }
  return text;
};

export const transformContractAccessLogData = (data: ContractAccessLog[]) =>
  data.map((item) => {
    return {
      accessDate: item.datetime ? moment(item.datetime).format("YYYY-MM-DD HH:mm:ss") : "-",
      phoneNumber: item.phoneNumber ?? "-",
      deviceName: item.deviceName ?? "-",
      eventName: item.eventName ? getAccessStatus(item.eventGroup) : "-",
    };
  });
