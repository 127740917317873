import { TokenResponse } from "../contexts/CourtAuthContext";
import persistentStorage from "./persistent-storage";

function save(token: TokenResponse) {
  const text = JSON.stringify(token);
  const base64 = window.btoa(text);
  persistentStorage.setItem("tokenResponse", base64);
}

function load() {
  const base64 = persistentStorage.getItem("tokenResponse");
  const text = window.atob(base64 ?? "bnVsbA==");
  if (text === "null") {
    return null;
  } else {
    return JSON.parse(text) as TokenResponse;
  }
}

function remove() {
  persistentStorage.removeItem("tokenResponse");
}

const tokenResponseRepository = { save, load, remove };

export default tokenResponseRepository;
