import { UserGuide } from "src/api/guide/guide-types";
import { MarkdownViewer } from "src/components";

type Props = {
  userGuide?: UserGuide;
};

/**
 * 이용안내 목록 > 상세 화면 > 일반항목 또는 주차 관련 안내 템플릿
 */
const EtcGuide = ({ userGuide }: Props) => {
  return (
    <div className="contents__item-content">
      <div>{userGuide?.description && <MarkdownViewer value={userGuide.description} />}</div>
    </div>
  );
};
export default EtcGuide;
