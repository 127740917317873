import { useRecoilValue } from "recoil";
import { ConfirmModal } from "src/components";
import { errorModalState } from "src/recoil/errorModal/atom";
import { useErrorModal } from "src/recoil/errorModal/hook";

const HTTP_STATUS_MAP = {
  400: "잘못된 요청입니다",
  401: "인증되지 않았습니다",
  403: "권한이 없습니다",
  404: "존재하지 않는 리소스입니다",
  "4xx": "요청 오류입니다",
  "5xx": "오류가 발생하였습니다",
};

export const getStatusMessage = (errorCode: number) => {
  switch (errorCode) {
    case 400:
      return HTTP_STATUS_MAP[400];
    case 401:
      return HTTP_STATUS_MAP[401];
    case 403:
      return HTTP_STATUS_MAP[403];
    case 404:
      return HTTP_STATUS_MAP[404];
    default:
      if (errorCode >= 400 && errorCode < 500) {
        return HTTP_STATUS_MAP["4xx"];
      }
      if (errorCode >= 500 && errorCode < 600) {
        return HTTP_STATUS_MAP["5xx"];
      }
      return "알 수 없는 에러가 발생했습니다";
  }
};

/**
 * recoil errorModalState 를 사용하는
 * api 에러발생시 띄우는 에러 모달 컴포넌트
 */
const ErrorModal = () => {
  const errorModals = useRecoilValue(errorModalState);
  const { closeAllErrorModal } = useErrorModal();

  const handleConfirm = () => {
    closeAllErrorModal();
  };
  if (errorModals.length > 0) {
    // 에러모달은 첫번째 하나만 보이게
    const firstErrorModal = errorModals[0];
    return (
      <ConfirmModal isOpen={firstErrorModal.isOpen} btnRightTitle={"확인"} onClick={handleConfirm}>
        <div style={{ wordBreak: "break-word" }}>
          {/* <b className="font18 font-weight-600">{getStatusMessage(firstErrorModal.statusCode || 0)}</b> */}
          {/* {firstErrorModal?.errorCode && <p className="font18 mt15">error code: {firstErrorModal.errorCode}</p>} */}
          {!firstErrorModal.errorCode ||
            (firstErrorModal.errorCode !== "eCT801" && ( //
              <b className="font18 font-weight-600">{getStatusMessage(firstErrorModal.statusCode || 0)}</b>
            ))}

          {/* eCT801 (배치 에러) 일 때는 에러 문구 보여주기  / 아닐때는 문구없이 에러코드만*/}
          {firstErrorModal.errorCode && firstErrorModal.errorCode !== "eCT801" ? (
            <div className="font14 mt20 text-primary3">{firstErrorModal?.errorCode || "에러가 발생했습니다."}</div>
          ) : (
            <div className=" font18 font-weight-600 pre-formatted">{firstErrorModal?.errorMessage || "에러가 발생했습니다."}</div>
          )}

          {/* {firstErrorModal?.errorData && <div className="font18 mt15">error data: {JSON.stringify(firstErrorModal?.errorData, null, 2)}</div>} */}
        </div>
      </ConfirmModal>
    );
  }
  return null;
};

export default ErrorModal;
